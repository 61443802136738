import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-select-datasource',
  templateUrl: './select-datasource.component.html',
  styleUrls: ['./select-datasource.component.css']
})
export class SelectDatasourceComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
