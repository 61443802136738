<div id="message-container" [@dialog]="temps.length" style="background: #00000073;">
        <div *ngFor="let t of temps;let i = index" class="outer position-fixed row m-0 p-0 h-100 w-100 top-0 left-0"
                style="background: #00000073;z-index: 10;">
                <div class="position-relative temp transition" [ngStyle]="t.style">
                        <div *ngIf="!t?.disableClose" class="text-right position-absolute w-100 p-1 top-0 left-0" style="z-index:1;">
                                <button mat-icon-button (click)="colse(i)" class="l-h-n h-w pointer"
                                        style="background: #00000012">
                                        <img class="h-100" src="../../../assets/images/icons8-multiply-filled-50.png">
                                </button>
                        </div>
                        <ng-container *ngTemplateOutlet="t.temp"> </ng-container>
                </div>
        </div>
</div>