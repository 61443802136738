export class NotificationModel {
  id?: number;
  title?: string;
  description?: string;
  entityName?: string;
  status?: string;
  entityId?: number;
  imgUrl?: string | null;
  createdAt?: string;
  updatedAt?: string;
}
