import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PagesComponent } from './pages/pages.component';
import { SideNavigatorComponent } from './elements/side-navigator/side-navigator.component';
import { HeaderComponent } from './elements/header/header.component';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandleComponent } from './elements/error-handle/error-handle.component';
import {
  NbDialogModule,
  NbToastrModule,
  NbDatepickerModule,
  NbMenuModule,
  NbThemeModule,
  NbThemeService,
  NbSidebarService,
  NbLayoutModule,
} from '@nebular/theme';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ShareModule } from './modules/share-module/share.module';
import { MessageComponent } from './helperComponents/message/message.component';
import { DialogComponent } from './helperComponents/dialog/dialog.component';
import { MainSideNavComponent } from './elements/main-side-nav/main-side-nav.component';
import { SpinnerComponent } from './helperComponents/spinner/spinner.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ReactiveFormsModule, NgModel } from '@angular/forms';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { FirebaseMessageService } from './services/notification/firebase-message.service';

@NgModule({
  declarations: [
    AppComponent,
    PagesComponent,
    MainSideNavComponent,
    SideNavigatorComponent,
    HeaderComponent,
    ErrorHandleComponent,
    DialogComponent,
    MessageComponent,
    SpinnerComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NbDialogModule.forRoot(),
    NbToastrModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbMenuModule.forRoot(),
    NbThemeModule.forRoot(),
    NgxSpinnerModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: false,
    }),
    ShareModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    NbThemeModule.forRoot({ name: 'default' }),
    NbLayoutModule,
    NbEvaIconsModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireStorageModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
  ],
  providers: [
    NbThemeService,
    NbSidebarService,
    NgModel,
    FirebaseMessageService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
