import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IApi } from '../http-handler/common/contracts/api';
import { GenericApi } from '../http-handler/common/generic-api';
import { ShootCategory } from '../models/shoot-category.model';

@Injectable({
    providedIn: 'root'
})
export class ShootCatService {

    shootCat: IApi<ShootCategory>;
    constructor(private http: HttpClient) {
        this.shootCat = new GenericApi<ShootCategory>('shootCategories', http)
    }
}
