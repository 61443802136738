import { Injectable } from '@angular/core';
import { IApi } from '../http-handler/common/contracts/api';
import { HttpClient } from '@angular/common/http';
import { GenericApi } from '../http-handler/common/generic-api';
import { DealPayment, Payment } from '../models/payment.model';

@Injectable({ providedIn: 'root' })
export class PaymentService {

    dealPayments:IApi<DealPayment>;
    shootPayments:IApi<Payment>;

    constructor(http:HttpClient) {

        this.dealPayments = new GenericApi<DealPayment>('dealPayments',http);
        this.shootPayments = new GenericApi<Payment>('shootPayments',http);

     }

}