import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-priority',
  templateUrl: './priority.component.html',
  styleUrls: ['./priority.component.css']
})
export class PriorityComponent implements OnInit, OnChanges {
  @Input() disable: boolean = false;
  @Input() Priority: any;
  @Input() control: FormControl = new FormControl('');

  @Output() PriorityChange: EventEmitter<number> = new EventEmitter();

  priorityRange: number[] = [1, 2, 3, 4, 5];

  constructor() {

  }

  ngOnInit() {

  }

  ngOnChanges() {
    this.Priority = parseInt(this.Priority);
    if (this.Priority && !this.control.value) {

      this.control.setValue(this.Priority);
    }
  }

  setPriority(v) {
    if (this.disable) return;
    this.control.setValue(v);
    this.PriorityChange.emit(v);
  }

  label(r: number): Level {
    switch (r) {
      case 1:
        return new Level({ label: 'BackBurner', color: '#0f63fd', imgName: 'BLUE' });
      case 2:
        return new Level({ label: 'Low Priority', color: '#4e980d', imgName: 'GREEN' });
      case 3:
        return new Level({ label: 'Medium', color: '#fca407', imgName: 'YELLOW' });
      case 4:
        return new Level({ label: 'High', color: '#f88a1f', imgName: 'ORANGE' });
      case 5:
        return new Level({ label: 'Immediate', color: '#ee1315', imgName: 'RED' });
    }
  }

}

class Level {
  label?: string;
  color?: string;
  imgName?: string;
  constructor(level: Level) {
    this.label = level.label;
    this.color = level.color;
    this.imgName = level.imgName;
  }
}
