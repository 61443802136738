import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'loading',
  template:  `
  <div class="loading-outer" >
    <mat-progress-spinner [strokeWidth]="1.5" [diameter]="d" [mode]="'indeterminate'" ></mat-progress-spinner>
  </div>
  `,
  styles: [`
    .loading-outer{
      display:flex;
      justify-content:center;
      align-items:center;
    }
    mat-progress-spinner{
      margin:auto
    }
  `]
})
export class IsLoadingComponent implements OnInit {

  @Input('diameter') d:number = 50;

  constructor() { }

  ngOnInit() {
  }

}
