export class MailingAddress {
    id?: number;
    street?: string;
    type?: string;
    city?: string;
    country?: string;
    zipcode?: number;
    stateId?: string;
    accountId?: string;
    contactId?:any;
    suiteNumber?: string;
    landmark?:string;
    address?:string;
}
