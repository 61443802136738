<div>
  <input [value]="control?.value?.name || ''" [matAutocomplete]="auto" placeholder="Search Account..." InputUi
    (input)="search($event.target.value)">
  <div class="append flex">
    <i class="fa fa-spinner fa-spin" *ngIf="accounts?.isLoading"></i>
    <img *ngIf="!accounts?.isLoading" src="../../../../../assets/images/icon_search gray.png">
  </div>

  <mat-autocomplete #auto="matAutocomplete" [displayWith]="display"
    (optionSelected)="selectAccount($event.option.value)">
    <mat-option *ngFor="let a of accounts.items" [value]="a">{{a.name}}</mat-option>
    <mat-option class="add-btn-option p-0" [value]="'create'" *ngIf="createOption">
      <div class="add-account">
        Create new
        <mat-icon>add</mat-icon>
      </div>
    </mat-option>
  </mat-autocomplete>
</div>