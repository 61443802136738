<div class="row m-0 border arrow-outer">
  <div class="col row m-0 p-0" *ngFor='let r of priorityRange' (mouseover)="Priority = r" (click)="setPriority(r)"
    (mouseout)="Priority = control.value">
    <img class="wid" [style.opacity]="Priority >= r ? '1':'.3'"
      src="assets/images/priority-arrows/{{label(r).imgName}}.png" alt="">
    <img class="wid" [style.opacity]="Priority >= r ? '1':'.3'"
      src="assets/images/priority-arrows/{{label(r).imgName}}.png" alt="">
  </div>
</div>
<div class="row m-0 labels">
  <div class="p-0" [class.col]="Priority !== r" *ngFor='let r of priorityRange'>
    <span [style.color]="label(r).color" *ngIf="Priority == r">{{label(r).label}}</span>
  </div>
</div>