import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Admin } from 'src/app/models/lead.model';
import { AppService } from 'src/app/services/app.service';
import { UserService } from 'src/app/services/user.service';
import { ValidatationService } from 'src/app/shared/validator.service';

@Component({
  selector: 'app-edit-personal-information',
  templateUrl: './edit-personal-information.component.html',
  styleUrls: ['./edit-personal-information.component.scss']
})
export class EditPersonalInformationComponent implements OnInit {

  form: FormGroup = new FormGroup({
    id: new FormControl(''),
    email: new FormControl('', [Validators.email, Validators.required]),
    firstName: new FormControl('', [Validators.required, ValidatationService.spaceValidator, Validators.maxLength(250), Validators.minLength(3)]),
    lastName: new FormControl('', [Validators.required, ValidatationService.spaceValidator, Validators.maxLength(250), Validators.minLength(3)]),
    username: new FormControl('', [Validators.required, ValidatationService.userName, Validators.maxLength(250), Validators.minLength(3)]),
    mobile: new FormControl('', [Validators.required, ValidatationService.phoneNumberWithoutDialCodeValidator]),
  });
  isLoading: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Admin,
    public dialogRef: MatDialogRef<EditPersonalInformationComponent>,
    private _userService: UserService,
    private _appService: AppService,
  ) {
    _appService.formGroupSetValue(this.form, data);
  }

  ngOnInit() {
  }

  update() {
    this.isLoading = true;
    this._userService.users.update(this.data.id, this.form.value).then(d => {
      this._appService.toastr.success('Profile successfully updated.');
      this.isLoading = false;
      this.dialogRef.close(true);
    }).catch(err => {
      this.isLoading = false;
      this._appService.toastr.error(err)
    })
  }

}
