<div class="h-100 outer" [style.width]=" commanService?.sideMenuToggle ? '78px' :'230px'">
        <div class="logo-1" *ngIf="!commanService?.sideMenuToggle">
                <img class="w-100" src="../../../assets/images/Side_bar_icon/Logo_dashboard.png" alt="">
        </div>

        <div class="logo-2" *ngIf="commanService?.sideMenuToggle">
                <img src="../../../assets/images/Splash.png" alt="">
        </div>

    <ul class="w-100">
        <li class="w-100" *ngFor="let l of items" (click)="navigate(l?.title)" [routerLink]="[l.link]" [routerLinkActive]="'active'" >
            <img [style.height]=" commanService?.sideMenuToggle ? '25px' :'20px'" [src]="l.icon" alt="">
            <a [class.d-none]="commanService?.sideMenuToggle" >{{l?.title}}</a>
        </li>
    </ul>
</div>