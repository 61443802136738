import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IApi } from '../http-handler/common/contracts/api';
import { GenericApi } from '../http-handler/common/generic-api';
import { MediaCategory } from '../models/mediaCategory.model';

@Injectable({
  providedIn: 'root'
})
export class MediaFinalCategoryService {

  mediaFinalCategories: IApi<MediaCategory>;


constructor(private http: HttpClient) { 
  this.mediaFinalCategories = new GenericApi<MediaCategory>('mediaFinalCategories', http);

}

}
