import { BusinessCategory } from "./businessCategory.model";
import { GearModel } from "./gear.model";
import { InventoryModel } from "./inventory.model";

export class LeadServices {
    id?: number;
    name?: string;
    selected?: boolean;
    businessCategoryId?:any;
    position?:number;
    businessCategoryServiceGears?: { id?: number; gear: GearModel }[];
    businessCategoryServiceInventories?: { id?: number; inventory: InventoryModel }[];
    businessCategoryServiceStudios?: any;
    businessCategory?: BusinessCategory;
    gearIds?: number[];
    inventoryIds?: number[];
    studioIds?: any;
}