import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IApi } from '../http-handler/common/contracts/api';
import { GenericApi } from '../http-handler/common/generic-api';
import { Shoot } from '../models/shoots.model';
import { ShootPackageService } from "./shootPackage.service";
import { MediaCategoryService } from "./mediaCategory.service";
import { MediaSubCategoryService } from "./mediaSubCategory.service";
import { MediaFinalCategoryService } from "./mediaFinalCategory.service";

@Injectable({
  providedIn: 'root'
})
export class ShootService {

  shoots: IApi<Shoot>;



  constructor(
    private http: HttpClient,
    private _shootPackagesService: ShootPackageService,
    private _mediaCategoriesService: MediaCategoryService,
    private _mediaSubCategoriesService: MediaSubCategoryService,
    private _mediaFinalCategoriesService: MediaFinalCategoryService,

  ) {
    this.shoots = new GenericApi<Shoot>('shoots', http);
  }

  get shootPackages() {
    return this._shootPackagesService.shootPackages;
  }

  get mediaCategories() {
    return this._mediaCategoriesService.mediaCategories;
  }

  get mediaSubCategories() {
    return this._mediaSubCategoriesService.mediaSubCategories;
  }

  get mediaFinalCategories() {
    return this._mediaFinalCategoriesService.mediaFinalCategories;
  }
}
