import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { Page } from 'src/app/http-handler/common/contracts/page';
import { Lead } from 'src/app/models/lead.model';
import { LeadService } from 'src/app/services/lead.service';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'search-lead',
  templateUrl: './select-lead.component.html',
  styleUrls: ['./select-lead.component.css']
})
export class SelectLeadComponent implements OnInit {
  // disabled: boolean = false;
  leads: Page<Lead>;
  @Input() createOption: boolean = true;
  @Input() disabled: boolean = false;
  @Input() id: FormControl = new FormControl('');
  // @Input() leadId: FormControl = new FormControl('')
  @Input() control: FormControl = new FormControl();
  @Output('onSelect') select: EventEmitter<Lead> = new EventEmitter();
  @Output() create: EventEmitter<any> = new EventEmitter();
  selected: Lead = null;

  @Input() displayLead: (l: Lead) => string = (l) => {
    if (l && l.name) {
      return l.name;
    } else if (l && l.leadNumber) {
      return `${l.leadNumber}`;
    } else {
      return "";
    }
  }

  constructor(
    private _LeadService: LeadService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
  ) {

    this.leads = new Page({
      api: _LeadService.leads,
      properties: new Lead(),
      filters: [{
        field: 'leadNumber',
        value: null
      }, {
        field: 'name',
        value: null
      }, {
        field: 'status',
        value: ''
      },
      {
        field: 'sortBy',
        value: 'sortLeadNumInDesc',
      }]
    });

    this.fetch();

  }

  ngOnInit() {
  }

  search(v: any) {
    this.leads.filters.properties['name'].value = v;
    this.id.setValue('');
    if(this.selected){
      this.control?.setValue('');
      this.select.emit(null);
    }
    this.selected = null;
    this.fetch();
  }
  onSelect(l: Lead) {
    if (!l) {
      this.id?.setValue('');
      this.selected = null;
      this.control?.setValue('');
      this.select.emit(null);
      return;
    };
    if (l === 'create' as any) {
      this.create.emit();
      return this._router.navigate(['/pages/leads/create'], { queryParams: { redirectTo: encodeURI(this._router.url + '?prefill=lead') } })
    }
    this.id?.setValue(l.id);
    this.selected = l;
    this.control?.setValue(l);
    this.select.emit(l);
  }

  fetch() {
    this.leads.fetch().then(d => {

    }).catch(err => {
      //console.log(err);
    })
  }


  display(l: Lead) {
    if (!l || !l.name) return '';
    return l.name;
  }

}
