import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import { ChatService } from '../chat.service';
import { NotificationService } from '../notification.service';

@Injectable({
  providedIn: 'root',
})
export class FirebaseMessageService {
  currentMessage = new BehaviorSubject(null);

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private chatService: ChatService,
    private notificationService: NotificationService
  ) {
    this.angularFireMessaging.messages.subscribe((_messaging: any) => {
      _messaging.onMessage = _messaging.onMessage.bind(_messaging);
      _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    });
  }

  // updateToken(userId, token) {
  //   // we can change this function to request our backend service
  //   this.angularFireAuth.authState.pipe(take(1)).subscribe(
  //     () => {
  //       const data = {};
  //       data[userId] = token;
  //       this.angularFireDB.object('fcmTokens/').update(data)
  //     })
  // }

  requestPermission(callback) {
    return this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        // this.updateToken(userId, token);
        callback(token);
        return token;
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
        return err;
      }
    );
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload: any) => {
      this.notificationService.incrementBadgeCount();
      this.chatService.getUnreadCount();
      const no = new Notification(payload.notification.title, {
        body: payload.notification.body,
      });
      this.currentMessage.next(payload);
      no.onclick = function () {
        window.focus();
      };
    });
  }
}
