import { Injectable } from '@angular/core';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { User } from '../models/user.model';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { IApi } from '../http-handler/common/contracts/api';
import { Admin } from '../models/lead.model';
import { mapper } from '../helpers/updateEntites';
// import { ValidatationService } from '../shared/validator.service';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  pageHeading: string;
  sideMenuToggle: boolean;
  id: any;

  team: IApi<Admin>;
  constructor(
    public dialogService: NbDialogService,
    public toastrService: NbToastrService,
    public toastr: ToastrService,
    public spinner: NgxSpinnerService,
    public https: HttpClient,
    private sanitizer: DomSanitizer
  ) {
    toastr.toastrConfig.timeOut = 4000;
  }

  uploadExcel(type: string, formData: FormData): Observable<any> {
    return this.https.post(
      environment.apiUrls.api + `/api/${type}/import/excel`,
      formData,
      { headers: this.getHeaders() }
    );
  }

  async exportExcel(model: any, path?: string): Promise<any> {
    let url: string = `${environment.apiUrls.api}/api/${path}`;
    return this.https
      .post(url, mapper(model), {
        headers: this.getHeaders(),
        responseType: 'blob',
      })
      .toPromise()
      .then((response) => {
        if (response instanceof Blob) {
          return response;
        } else {
          throw new Error('Expected blob response');
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  }

  downloadFile(url): Observable<Blob> {
    this.sanitizer.bypassSecurityTrustResourceUrl(url);
    return this.https.get(url, { responseType: 'blob' });
  }

  private getHeaders(): HttpHeaders {
    const obj: any = {
      // 'Content-Type': 'application/json'
    };
    const token = window.localStorage.getItem('token');
    if (token) {
      obj['x-access-token'] = token;
    }
    const headers = new HttpHeaders(obj);
    return headers;
  }

  get user(): User {
    return JSON.parse(localStorage.getItem('user')) || new User();
  }

  set user(u) {
    localStorage.setItem('user', JSON.stringify(u));
  }

  get role(): string {
    if (!localStorage.getItem('roleType')) {
      localStorage.setItem('roleType', this.user.roleType);
    }
    return localStorage.getItem('roleType');
  }

  get isSmallNav(): boolean {
    if (!localStorage.getItem('smallSideNav')) return false;
    return true;
  }

  get token(): string {
    return localStorage.getItem('roleType') || '';
  }

  set token(t: string) {
    localStorage.setItem('token', t);
  }

  modelToBeUpdate(newObject: any, oldObject: any) {
    let updatedObject: any = {};
    Object.keys(newObject).forEach((k) => {
      if (oldObject[k]) {
        if (typeof newObject[k] == 'object') {
          if (JSON.stringify(newObject[k]) !== JSON.stringify(oldObject[k])) {
            updatedObject[k] = newObject[k];
          }
        } else {
          if (newObject[k] !== oldObject[k]) {
            updatedObject[k] = newObject[k];
          }
        }
      } else {
        updatedObject[k] = newObject[k];
      }
    });

    return updatedObject;
  }

  isObjectEmpty(object: any) {
    for (let obj in object) {
      if (object[obj]) {
        return false;
      }
    }
    return true;
  }

  formGroupSetValue(form: FormGroup, value: { [key: string]: any }) {
    Object.keys(form.controls).forEach((key) => {
      const control = form.get(key);
      if (value[key] !== undefined) {
        if (control instanceof FormControl) {
          control.setValue(value[key]);
        } else if (control instanceof FormGroup) {
          this.formGroupSetValue(control, value[key]);
        } else if (control instanceof FormArray) {
          control.controls.forEach((arrayControl, index) => {
            const arrayItem: Array<any> = value[key][index];
            if (arrayControl instanceof FormControl) {
              arrayControl.setValue(arrayItem);
            } else if (arrayControl instanceof FormGroup) {
              this.formGroupSetValue(arrayControl, arrayItem);
            }
          });
        }
      }
    });
  }
}
