<div class="d-flex h-100">

  <nb-card class="mx-auto my-3">
    <nb-card-body class="mb-5">
      <h6>RMP TEAM INVITES CLIENT</h6>
      <div class="text-center">

        <img class="mb-5" src="assets/images/illustration.png" alt="">

        <h5 class="font-weight-light mb-3">
          We Will be sending an Email invite to the <br> Client to Join Earth Virtual
        </h5>

        <form class="mx-5" [formGroup]="form"  >
          <div class="form-group text-left">
            <label for="email">Enter the email</label>
            <input class="py-2" type="email" formControlName="email" id="email" InputUi placeholder="Email">
          </div>
          <div class="pt-3" >
            <button type="submit" ButtonUi matRipple [disabled]="isLoading || form?.invalid" (click)="invite()" >Send</button>
          </div>
        </form>

      </div>
    </nb-card-body>
  </nb-card>

</div>