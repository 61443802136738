import { Component, OnInit,EventEmitter,Output,Input } from '@angular/core';
import { Country } from 'src/app/models/country.model';
import { Page } from 'src/app/http-handler/common/contracts/page';
import { CountryService } from 'src/app/services/country.service';
import { AppService } from 'src/app/services/app.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-select-country',
  templateUrl: './select-country.component.html',
  styleUrls: ['./select-country.component.css']
})
export class SelectCountryComponent implements OnInit {
  country: Page<Country>;
  @Input() control: FormControl;
  @Input() id: any;
  @Output() idChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private countryService: CountryService,
    private appService: AppService
  ) {

    this.country = new Page({
      api: countryService.countries,
      properties: new Country(),
      serverPaging: false
    });

    this.fetch();

  }

  ngOnInit() {
  }

  fetch() {
    this.country.fetch().then(d => {

    }).catch(err => {
    })
  }


}
