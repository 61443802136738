import { Directive, Output, EventEmitter, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[OnlyFloat]'
})
export class OnlyFloatDirective {

  input: HTMLInputElement;
  @Output() ngModelChange: EventEmitter<string> = new EventEmitter<string>();
  perviousValue: string;
  isKeyFloat: boolean = false;

  constructor(
    private _el: ElementRef<HTMLInputElement>
  ) {
    this.input = _el.nativeElement;
  }

  @HostListener('input', ['$event']) onInput(e) {
    if (!this.isKeyFloat || (this.input.maxLength && this.input.value.length == this.input.maxLength)) {
      this.input.value = this.perviousValue;
      this.ngModelChange.emit(this.perviousValue);
    } else {
      this.perviousValue = e.target.value;
    }
  }

  @HostListener('keydown', ['$event']) keyDown(event: KeyboardEvent) {
    // //console.log(event.keyCode);
    if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105) || event.keyCode == 8 || event.keyCode == 190) {
      this.isKeyFloat = true;
    } else {
      this.isKeyFloat = false;
    }
  }

  @HostListener('focus', ['$event']) focus(event) {
    this.perviousValue = event.target.value;
  }
}
