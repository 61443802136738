import { Directive, ElementRef, HostListener, Input, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[NumberOnly]'
})
export class NumberOnlyDirective {

  input: HTMLInputElement;
  @Output() ngModelChange: EventEmitter<string> = new EventEmitter<string>();
  perviousValue: string;
  isKeyNumaric: boolean = false;

  constructor(
    private _el: ElementRef<HTMLInputElement>
  ) {
    this.input = _el.nativeElement;
  }

  @HostListener('input', ['$event']) onInput(e) {
    if (!this.isKeyNumaric || (this.input.maxLength && e.target.value.length > this.input.maxLength)) {
      this.input.value = this.perviousValue;
      this.ngModelChange.emit(this.perviousValue);

    } else {
      this.perviousValue = e.target.value;
    }
  }

  @HostListener('keydown', ['$event']) keyDown(event: KeyboardEvent) {
    if (event.keyCode == 190) {
      return this.isKeyNumaric = false;
    }
    if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105) || event.keyCode == 8) {
      this.isKeyNumaric = true;
    } else {
      this.isKeyNumaric = false;
    }
  }

  @HostListener('focus', ['$event']) focus(event) {
    this.perviousValue = event.target.value;
  }

}
