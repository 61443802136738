import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'Status'
})
export class SpacePipe implements PipeTransform {

  transform(status: any, args?: any): any {
    if (args == 'lead') return this.leadStatus(status);
    else if (args == 'deal') return this.dealStatus(status);
    else {
      return this.toCamelCase(status || '');
    }
  }
  leadStatus(status: string) {
    if (status === 'inquiry') {
      return "Inquiry";
    }

    if (status === 'followUp') {
      return "Follow Up";
    }

    if (status === 'proposal') {
      return "Proposal";
    }

    if (status === 'onHold') {
      return "On Hold";
    }

    if (status === 'deleted') {
      return "Deleted";
    }

    if (status === 'convertedIntoDeal') {
      return "Converted";
    }
    if(status === 'closedLost'){
      return "Closed - Lost"
    }
  }

  dealStatus(status: string) {
    if (status === 'scheduled') {
      return "Scheduled";
    }
    if (status === 'capturingMedia') {
      return "Capturing Media";
    }
    if (status === 'editingMedia') {
      return "Editing Media";
    }
    if (status === 'reviewingRevisingSelecting') {
      return "RRS";
    }
    if (status === 'editingToCompletion') {
      return "Editing To Completion";
    }
    if (status === 'mediaDeliveredAwaitingFinalWord') {
      return "MDAFW";
    }
    if (status === 'won') {
      return "(Closed) Won";
    }
    if (status === 'lost') {
      return "(Closed) Lost";
    }
    if (status === 'onHold') {
      return "On Hold";
    }
    if (status === 'deleted') {
      return "Deleted";
    }
  }

  toCamelCase = function (str) {
    const result = str.replace(/([A-Z])/g, ' $1');
    const final = result.charAt(0).toUpperCase() + result.slice(1);
    return final
  }

}
