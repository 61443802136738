import { Component } from '@angular/core';
import { NavigationStart, Router, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { PresenceService } from './services/support-service/presense.service';
import { AppService } from './services/app.service';
import { TeamService } from './services/team.service';
import { UserService } from './services/user.service';
import { Page } from './http-handler/common/contracts/page';
import { Admin } from './models/lead.model';
import { Model } from './http-handler/common/contracts/model';
import { User } from './models/user.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'new-temp';

  loading:boolean = false;
  team: Model<Admin> = new Model({
    api: this.teamServicve.team,
    properties: new Admin()
  })

  user: Model<User> = new Model({
    api: this.teamServicve.team,
    properties: new Admin()
  })

  constructor(
    private router:Router,
    private presenseService:PresenceService,
    private appService:AppService,
    private userService:UserService,
    private teamServicve:TeamService
  ){
   
    this.presenseService.setPresence('online')
  // localStorage.setItem('contactClient',`{"businessName":"School","id":76,"firstName":"arsh","middleName":null,"lastName":"dhiman","fullName":"arsh dhiman","jobTitle":null,"officePhone":null,"mobilePhone":"565-756-8768","extension":null,"email":"arshDhiman123@gmail.com","website":null,"homedown":null,"past":null,"status":"active","birthday":"2020-06-03T18:30:00.000Z","introduce":null,"highSchool":null,"universityGraduate":null,"universityUnderGraduate":null,"universityJoin":null,"facebook":null,"instagram":null,"linkedIn":null,"university":null,"mbaPe":null,"relationshipLevel":null,"notes":null,"billingAddress":null,"maillingAddress":null,"vision":null,"dataSource":null,"noteForDataSource":null,"username":"arshDhiman123@gmail.com","adminId":58}`);
  // localStorage.setItem('clientType','account');

    this.router.events.subscribe((event: any) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading = false;
          break;
        }
        default: {
          break;
        }
      }
    });



  }


 

}
