import { Component, HostListener, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'social-media-tooltip',
  template: `
    <font-14 color="dark" >{{t}}</font-14> <br>
    <div class="content mt-2" >
    <img [src]="imgUrl" class="mr-2" />
    <a [href]="link" class="mr-2" target="_blank" ><font-14>{{link?.slice(0,15) || "N/a"}}</font-14></a>
    <div class="ml-auto d-flex" *ngIf="link" >

    <button mat-icon-button (click)="openLink()" >
      <img src="assets/svg/Group 398.svg" />
      </button>

      <button mat-icon-button (click)="copy()" >
      <img src="assets/svg/Group 399.svg" />
      </button>

    </div>
    </div>
  `,
  styles: [`
    :host{
      background-color:white;
      text-align:left;
      padding:1rem;
      display:block;
      padding-right:0rem
    }
    .content{
      display:flex;
      align-items:center
    }
  `]
})
export class SocialMediaTooltipComponent implements OnInit, OnChanges {

  @Input('title') t: string = "";
  @Input('link') link: string = "";
  @Input('src') imgUrl: string = "";

  constructor() { }

  ngOnInit() {
    
  }

  ngOnChanges(changes:SimpleChanges){
    if(changes['link'] && this.link && this.link.match(/[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}/g)){
      this.link = "mailto:"+this.link;
    }else if(changes['link'] && this.link && !this.link.startsWith('https://') && !this.link.startsWith('https://')){
     this.link = "https://"+ this.link;
    }
  }

  @HostListener('click', ['event']) mouseover(e) {
    // e.stopPropagation();
  }

  openLink() {
    window.open(this.link);
  }

  copy() {
    const input = document.createElement('input');
    input.value = this.link;
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    input.blur();
    document.body.removeChild(input);
  }

}
