import { Injectable } from '@angular/core';
import { IApi } from '../http-handler/common/contracts/api';
import { HttpClient } from '@angular/common/http';
import { GenericApi } from '../http-handler/common/generic-api';
import { Audience } from '../models/audience.model';

@Injectable({
  providedIn: 'root'
})
export class AudienceService {

  audience: IApi<Audience>;
  contactAudience: IApi<Audience>
  broadcastLogsMail: IApi<Audience>
  broadcastLogs: IApi<Audience>
  contactEmail: IApi<Audience>
  constructor(private http: HttpClient) {
      this.audience = new GenericApi<Audience>('audiences', http);
      this.contactAudience = new GenericApi<Audience>('contactAudiences/add/contact', http);
      this.broadcastLogsMail = new GenericApi<Audience>('broadcastLogs/send/email', http);
      this.broadcastLogs = new GenericApi<Audience>('broadcastLogs', http);
      this.contactEmail = new GenericApi<Audience>('contactAudiences', http);
  }

}
