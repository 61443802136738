import { Injectable } from '@angular/core';
import { IApi } from '../http-handler/common/contracts/api';
import { HttpClient } from '@angular/common/http';
import { GenericApi } from '../http-handler/common/generic-api';
import { Task, TaskWatchList } from '../models/task.model';
import { Admin } from '../models/lead.model';
import { Goal } from '../models/goal.model';

@Injectable({
    providedIn: "root"
})

export class TaskService {

    tasks: IApi<Task>;
    updateMultiTaks: IApi<Task>;

    constructor(http: HttpClient) {
        this.tasks = new GenericApi<Task>('tasks', http);
        this.updateMultiTaks = new GenericApi<Task>('tasks/update/multiple', http);
    }

    convertToGoal({ id }: Task): Promise<string> {
        return this.tasks.update(id,{  } as any,null, `convert/goal/${id}`).then(d => {
            return 'Task successfully converted into goal.';
        }).catch(err => {
           return Promise.reject(err);
        });
    }

    updatePostion(taskId, toPosition, fromPosition): Promise<string> {
        return this.tasks.create({ taskId, toPosition, fromPosition } as any, 'update/position').then(d => {
            return 'Postion successfully updated.';
        }).catch(err => {
            return Promise.reject(err);
        })
    }
    updatePostions(taskId, position): Promise<string> {
        return this.tasks.create({ taskId, position } as any, 'update/position').then(d => {
            return 'Postion successfully updated.';
        }).catch(err => {
            return Promise.reject(err);
        })
    }

    convertToMilestone(task: Task): Promise<string> {
        task.tag = 'milestone';
        return this.tasks.update(task.id, { tag: 'milestone' }).then(d => {
            task.tag = 'milestone';
            return 'Task successfully converted into milestone.';
        }).catch(err => {
            task.tag = 'task';
            return Promise.reject(err);
        })
    }

    addToGoal(goal: Goal, task: Task): Promise<string> {
        return this.tasks.create({ taskId: task.id, goalId: goal.id } as any, 'add/goal').then(d => {
            if (!task?.goals?.length) task.goals = [];
            task.goals.push({ ...goal });
            return 'Task successfully added to goal.';
        }).catch(err => {
            return Promise.reject(err);
        })
    }

    addAdminToWatchList(admin: Admin, task: Task): Promise<string> {
        let body:any={ taskId: task?.id, adminId: admin.id}
        if(!task) delete body.taskId
        return this.tasks.create(body, 'add/admin').then(d => {
            if (!task?.taskWatchLists?.length) task.taskWatchLists = [];
            task.taskWatchLists.push({ adminId: admin.id, admin: { ...admin }, taskId: task.id});
            return `${admin.displayName} successfully added in task watch lisk.`;
        }).catch(err => {
            return Promise.reject(err);
        })
    }

    removeAdminToWatchList(watchListAdmin: TaskWatchList, task: Task): Promise<string> {
        return this.tasks.remove(watchListAdmin.id, `remove/admin/${watchListAdmin.id}`).then(d => {
            if (task?.taskWatchLists?.length) {
                const index = task.taskWatchLists.findIndex(i => i.id === watchListAdmin.id);
                if (index > -1) {
                    task.taskWatchLists.splice(index, 1);
                }
            }
            return `${watchListAdmin.admin.displayName} successfully removed from task watch lisk.`;
        }).catch(err => {
            return Promise.reject(err);
        })
    }

}