import { Directive, Input, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[SelectUi]'
})

export class SelectUiDirective {

  input: HTMLSelectElement;

  @Input() type?: 'none' | 'primary';
  @Input() beforeText: string;


  constructor(
    private el: ElementRef<HTMLSelectElement>,
    private _renderer2:Renderer2
  ) {
    this.input = el.nativeElement;
    this.input.style.transition = 'all .15s ease-in-out';
    // this.input.classList.add('border');
    const parent = el.nativeElement.parentNode;
    if(parent){
      this._renderer2.addClass(parent,'form-field')
    }
  }

  ngOnInit() {
    this.checkType();
    if (this.beforeText) {
      let p = document.createElement('p');
      p.innerHTML = this.beforeText;
      p.style.position= 'absolute';
      p.style.top= '34px';
      p.style.left='14px';
      p.style.margin = '0px';
      this.input.style.paddingLeft= '27px';
      this.input.before(p);
    }
  }

  // primary() {
  //   let i = this.input;
  //   i.style.color = '#012c49';
  //   i.style.width = "100%";
  //   i.style.padding = "10px 6px";
  //   i.style.border = " 1px solid";
  //   i.style.borderColor = "#dee0e0";
  //   i.style.fontSize = "14px";
  //   i.style.borderRadius = "2px";
  //   i.style.outline = 'none';
  // }

  // none() {
  //   let i = this.input;
  //   i.style.border = "none";
  //   i.style.borderColor = "#dee0e0";
  //   i.style.fontSize = "14px";
  //   i.style.borderRadius = "2px";
  //   i.style.outline = 'none';
  // } 

  @HostListener('focus', ['$event']) focus(event) {
    if (this.type == 'none') return;
    this.input.style.boxShadow = "0 1px 9px 0 rgba(153, 154, 158, 0.3)";
    this.input.style.borderColor = "white";
  }

  @HostListener('blur', ['$event']) blur(event) {
    this.input.style.boxShadow = "0px 0px 0px 0px transparent";
    this.input.style.borderColor = "#dee0e0";
  }

  checkType() {
    switch (this.type) {
      case "none":
        // return this.none();
      default:
        // return this.primary();
    }
  }

}