import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'user-profile',
  template: `
  <div *ngIf="append == 'before'" class="flex content-center items-start cols mr-2" >
    <a *ngIf="link" font-14 [size]="s" [color]="c" [weight]="w" [routerLink]="[link]" ReadMoreLess [maxLength]="40" [text]="(name | titlecase) || 'N/a'" ></a>
     <font-14 *ngIf="!link" [size]="s" [color]="c" [weight]="w" ReadMoreLess [maxLength]="40" [text]="(name | titlecase) || 'N/a'" ></font-14>
     <div class="w-100 mt-1" *ngIf="subName" ><font-14 [size]="subNameSize ? subNameSize : s/1.3" [color]="subNameColor" [weight]="subNameWeight ? subNameWeight : w" >{{subName}}</font-14></div>
    </div>
    <div>
    <logo [src]="img" [backgroundColor]="'#fde0d3'" [titleColor]="'secondary'"
     [diameter]="d ? d : s*2.5" [title]="name || 'N/a'" ></logo>
    </div>
    <div *ngIf="append == 'after'" class="flex content-center items-start cols ml-2" >
    <a *ngIf="link" font-14 [size]="s" [color]="c" [weight]="w" [routerLink]="[link]" ReadMoreLess [maxLength]="40" [text]="(name | titlecase) || 'N/a'" ></a>
     <font-14 *ngIf="!link" [size]="s" [color]="c" [weight]="w" ReadMoreLess [maxLength]="40" [text]="(name | titlecase) || 'N/a'" ></font-14>
     <div class="w-100 mt-1" *ngIf="subName" ><font-14 [size]="subNameSize ? subNameSize : s/1.3" [color]="subNameColor" [weight]="subNameWeight ? subNameWeight : w" >{{subName}}</font-14></div>
    </div>
  `,
  styles: [`
    :host{
      display:inline-flex;
      align-items:center;
    }
  `]
})
export class UserProfileComponent implements OnInit {

  @Input('image') img:string = "";
  @Input('name') name:string =  "";
  @Input('size') s:number = 13;
  @Input('color') c:string = 'primary';
  @Input('weight') w:string = '500';
  @Input('link') link:string = "";
  @Input('subName') subName:string;
  @Input('subNameSize') subNameSize:number;
  @Input('subNameColor') subNameColor:string = 'muted';
  @Input('subNameWeight') subNameWeight:string;
  @Input('diameter') d:number;
  @Input('append') append:"after" | 'before' = 'after';

  constructor() { }

  ngOnInit() {
  }

}
