<div class="position-relative w-100">
  <input name="main" [formControl]="control" [matAutocomplete]="auto" placeholder="Search lead..." InputUi
    (input)="search($event.target.value)" type="text" >
  <div class="position-absolute icon">
    <i class="fa fa-spinner fa-spin" *ngIf="leads?.isLoading"></i>
    <img *ngIf="!leads?.isLoading" src="../../../../../assets/images/icon_search gray.png">
  </div>
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="display" (optionSelected)="onSelect($event.option.value)">
    <mat-option *ngFor="let a of leads.items" [value]="a">{{(displayLead(a) || "N/a")}}</mat-option>
    <mat-option class="add-btn-option p-0" [value]="'create'" *ngIf="createOption" >
      <div class="add-account">
        Create new
        <mat-icon>add</mat-icon>
      </div>
    </mat-option>
  </mat-autocomplete>
</div>