import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as XLSX from 'xlsx';
import { AppService } from 'src/app/services/app.service';

type AOA = any[][];

@Component({
  selector: 'upload-excel',
  templateUrl: './upload-excel.component.html',
  styleUrls: ['./upload-excel.component.css']
})
export class UploadExcelComponent implements OnInit {

  isLoading: boolean = false;
  drophover: boolean = false;
  isUploading: boolean = false;

  data: AOA = [[1, 2], [3, 4]];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  fileName: string = 'SheetJS.xlsx';
  file: File;

  @Input() ExcelType: string;
  @Output() onUpload: EventEmitter<any> = new EventEmitter();

  constructor(
    private _CommenService: AppService,
  ) { }

  onFileChange(evt?: any, file?: File) {
    this.file = (evt && evt.target.files && !file) ? evt.target.files[0] : file; ``
    if (!this.file.name.endsWith('.xlsx') && !this.file.name.endsWith('.xls')) {
      this.data = [[1, 2], [3, 4]];
      this.isLoading = false;
      this.file = null;
      return this._CommenService.toastr.error('Select excel file only.');
    }
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
      this.isLoading = false;
    };
    reader.readAsBinaryString(this.file);
  }

  dragOverHandler(ev) {
    this.drophover = true;
    ev.preventDefault();
  }

  dropHandler(ev) {
    ev.preventDefault();
    this.isLoading = true;

    if (ev.dataTransfer.items) {
      if (ev.dataTransfer.items.length) {
        if (ev.dataTransfer.items[0].kind === 'file') {
          this.file = ev.dataTransfer.items[0].getAsFile();
          this.onFileChange(null, this.file);
        }
      }
    } else {
      if (ev.dataTransfer.files.length) {
        this.file = ev.dataTransfer.files[0]
        this.onFileChange(null, ev.dataTransfer.files[0]);

      }
    }
  }

  ngOnInit() {
  }

  downLoadExcel() {
    switch (this.ExcelType) {
      case 'realEstates':
        return "https://earthvirtual.s3.amazonaws.com/images/75ec6d5095e12f442021f295619d914dc456dec8.xlsx";
      case 'accounts':
        return "https://earthvirtual.s3.amazonaws.com/images/bf151eb62ec2395d4bcb70bf1d04f6f272aad997.xlsx";
      case 'contacts':
        return "https://earthvirtual.s3.us-east-2.amazonaws.com/images/93922709f43fc1b2031639105add82cb2b6f819c.xlsx"
    }
  }

  upload() {
    if (!this.ExcelType) {
      return
    }
    if (!this.file) {
      return
    }
    this.isUploading = true;

    let formData: FormData = new FormData();

    formData.append('file', this.file);

    this._CommenService.uploadExcel(this.ExcelType, formData).subscribe(d => {
      this.onUpload.emit(true);
      this.isUploading = false;
      this._CommenService.toastr.success('Excel successfully uploaded');
    }, err => {
      this.isUploading = false;
      if (err.status === 0) {
        return this._CommenService.toastr.error('There is no internet connection')
      }
    });


  }

}
