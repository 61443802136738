import { Component, OnInit, ViewChild, Input, ElementRef, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit,OnChanges {
  selectedVideoSrc = "";

  @ViewChild('videoPlayer') videoplayer: ElementRef<HTMLVideoElement>;
  @Input('videos') videos = [];
  public startedPlay: boolean = false;
  isLoading:boolean;



  constructor() {
  }

  ngOnInit() {
    if(this.videos.length){
      this.selectedVideoSrc = this.videos[0];
    }
  }

  ngOnChanges(changes:SimpleChanges){
    if(changes['videos'] && this.videos){
      this.selectedVideoSrc = this.videos[0];
    }
  }

  playToggle() {
    if (this.videoplayer.nativeElement.paused) {
      this.videoplayer.nativeElement.play();
    } else {
      this.videoplayer.nativeElement.pause();
    }
  }



  slideConfig = {
    speed: 1000,
    slidesToShow: 6,
    slidesToScroll: 1,
    cssEase: 'linear',
    autoplay: false,
    centerMode: true,
    focusOnSelect: false,
    prevArrow: '.prev-icon',
    nextArrow: '.next-icon',
    infinite: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      }, {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  selectAndPlay(src) {
    this.videoplayer.nativeElement.pause();
    this.selectedVideoSrc = src;
    this.videoplayer.nativeElement.currentTime = 0;
    this.videoplayer.nativeElement.play();
  }

  get progress() : number{
    if(!this.videoplayer) return 0;
    return ((this.videoplayer.nativeElement.currentTime/this.videoplayer.nativeElement.duration) || 0)*100;
  }

  // addSlide() {
  //   this.slides.push({vdo: "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"})
  // }


}

function formatTime(timeInSeconds) {
  const result = new Date(timeInSeconds * 1000).toISOString().substr(11, 8);

  return {
    minutes: result.substr(3, 2),
    seconds: result.substr(6, 2),
  };
};
