import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'mediaCamera'
})

export class MediaCameraPipe implements PipeTransform {

    transform(v?: string, args?: any) {

        switch (v) {
            case 'drone':
                return 'Drone';
            case 'fixedWing':
                return 'Fixed Wing';
            case 'dslr':
                return 'DSLR';
            case 'goPro':
                return 'Go Pro';
        }

    }

}