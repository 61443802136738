import { Admin, Lead } from './lead.model';
import { Deal } from './deal.model';
import { ShootCategory } from './shoot-category.model';
import { ShootSub } from './shoot-subcategory.model';
import { Contact } from './contacts.model';

export class Shoot {
    id?: number;
    name?: string;
    totalReceivedAmount?: number;
    totalPaidAmount?: number;
    admin?: Admin = new Admin();
    lead?: Lead = new Lead();
    deal?: Deal = new Deal();
    adminId?: number;
    leadId?: number;
    dealId?: number;
    addressCoordinates?:any[]
    addressCoordinatesPolygon?:any[]
    shootSelectedSubCategoryIds?: number[] = [];
    shootCategory?: ShootCategory = new ShootCategory();
    shootSelectedSubCategories?: shootSelectedSubCategory[] = [];
    shootCategoryId?: number;
    shootSubCategories?: any[] = [];
    style?: string;
    medium?: string;
    paymentStyle?: string;
    psvOrApr?: string;
    price?: number;
    startDate?: string;
    // contactSkill?: ContactSkill[] = [];
    notes?: string;
    closeDate?: string;
    status?: string;
    btnDisable?: boolean;
    purposeOfShoot?: string;
    color?: string;
    selected?: boolean;
    address?: string;
    shootNumber?: number;
    shootContacts?: ShootContact[] = [];
    priority?: number;
    shootData?: string;
}

export class ShootContact {
    contactId?: number;
    contact?: Contact = {};
    id?: number;
    shootId?: number;
}

export class shootSelectedSubCategory {
    id?: number;
    shootId?: number;
    shootSubCategoryId?: number;
    shootSubCategory?: ShootSub = {};
}

