import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[preventDefault]'
})
export class PreventdefaultDirective {

  constructor() { }

  @HostListener('click', ['$event']) click(e:Event) {
    e.preventDefault();
    e.stopPropagation();
  }

}
