import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'hyphenPipe'
})
export class HyphenPipe implements PipeTransform {

    transform(value: string, ...args: any[]): any {
        if (!value) return '';
        if(value.length !== 10) return value;
        let val = (value && value.match(/\d+/g)) ? value.match(/\d+/g).join("").replace(/_/, '') : '';
        let numbers = [];
        for (let i = 0; i < val.length; i += 3) {
            numbers.push(val.substr(i, i == 6 ? 4 : 3));
            if (i == 6) { break }
        }
        return numbers.join('-');
    }

}
