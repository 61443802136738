export class FirebaseFileUpload {
    key: string;
    name: string;
    fileType: string;
    timeStamp: number;
    url: string;
    file: File;
    fileData: any;
    constructor(file: File) {
        this.file = file;
    }
}
