import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  NgZone,
  Input,
  Inject,
  OnDestroy,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MapsAPILoader  } from '@agm/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
declare var google: any;

class DialogData {
  location?: string;
  coodinates?: number[];
  place?: any;
  coodinatesPolygon?:number[]
  type?:string
}

@Component({
  selector: 'search-address',
  templateUrl: './search-address.component.html',
  styleUrls: ['./search-address.component.css'],
})
export class SearchAddressComponent implements OnInit, OnDestroy {

  view: 'pinDrop' | 'polygonDraw' = 'pinDrop'

  @Input() coodinates: FormControl = new FormControl([0, 0]);
  @Input() coodinatesPolygon: FormControl = new FormControl([0, 0]);
  @Input() searchControl: FormControl = new FormControl();
  @Input() type: FormControl = new FormControl();
  place: any;
  geocoder: any;
  mapCoodinates?: number[] = [0, 0];


// polygon map 
  lat:any = 20.5937;
  lng:any = 78.9629;
  pointList: { lat: number; lng: number }[] = [];
  polygonCoords?: number[] = [0, 0];
  drawingManager: any;
  selectedShape: any;
  selectedArea = 0;



  @ViewChild('search') searchElementRef: ElementRef;
  

  constructor(
    public dialogRef: MatDialogRef<SearchAddressComponent>,
    private mapsAPILoader: MapsAPILoader,
    private http: HttpClient,
    private ngZone: NgZone,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    if (data.location) {
      this.searchControl.setValue(data.location);
    }

    if (data.coodinates) {
      this.coodinates.setValue(data.coodinates);
      this.coodinatesPolygon.setValue(data.coodinatesPolygon)
      this.mapCoodinates = data.coodinates;
      this.polygonCoords = data.coodinatesPolygon
    }
  }

  ngOnChanges() {
    if (this.coodinates.value && this.coodinates.value.length)
      this.mapCoodinates = this.coodinates.value;

      if(this.coodinatesPolygon.value && this.coodinatesPolygon.value.length)
      this.polygonCoords = this.coodinatesPolygon.value;
  }

  ngOnInit() {
    this.setCurrentPosition();
    this.setCurrentPositions();

    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      
      this.geocoder = new google.maps.Geocoder();
      let autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement
      );
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          //get the place result
          let place: any = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          this.place = place;
          this.coodinates.setValue([
            place.geometry.location.lng(),
            place.geometry.location.lat(),
          ]);
          this.mapCoodinates = [
            place.geometry.location.lng(),
            place.geometry.location.lat(),
          ];
          this.searchControl.setValue(place.formatted_address);
          this.save();
        });
      });
    });
  }

  save() {
    this.data.coodinates = this.coodinates.value;
    this.data.place = this.place;
    this.data.location = this.searchControl.value;
  this.data.coodinatesPolygon = this.coodinatesPolygon.value
  this.data.type = this.type.value
  }

  private setCurrentPosition() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((pos) => {
        // //console.log(pos);
        if (!this.mapCoodinates.length)
          this.mapCoodinates = [pos.coords.longitude, pos.coords.latitude];
        //  //console.log(this.mapCoodinates);
      });
    }
  }

  input(event) {
    // this.locationChange.emit(event.target.value);
  }

  searchLocation(lat, lng) {
    this.geocoder.geocode(
      {
        location: {
          lat: lat,
          lng: lng,
        },
      },
      (results, status) => {
        if (results.length == 0) return false;
        this.place = results;
        this.searchControl.setValue(results[0].formatted_address);
        this.type.setValue('pinDrop');
        this.save();
      }
    );
  }

  selectMarker(lng, lat?: any) {
    this.coodinates.setValue([lng, lat]);
    this.searchLocation(lat, lng);
    this.save();
  }

  map;
  mapClickListener;
  dragendListener;
  public mapReadyHandler(map: google.maps.Map): void {
    this.map = map;
    this.mapClickListener = this.map.addListener(
      'click',
      (e: google.maps.MouseEvent) => {
        this.ngZone.run(() => {
          // Here we can get correct event
          this.selectMarker(e.latLng.lng(), e.latLng.lat());
        });
      }
    );
  }

  markerStart(e) {
  }

  public ngOnDestroy(): void {
    if (this.mapClickListener) {
      this.mapClickListener.remove();
    }
    if (this.dragendListener) {
      this.dragendListener.remove();
    }
  }


  // draw location by polygon
 
 

 public onMapReady(map:google.maps.Map): void {
    this.initDrawingManager(map);
  }

  initDrawingManager = (map: any) => {
    const self = this;
    const options = {
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: ['polygon'],
      },
      polygonOptions: {
        draggable: true,
        editable: true,
      },
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
    };
    this.drawingManager = new google.maps.drawing.DrawingManager(options);
    this.drawingManager.setMap(map);
    google.maps.event.addListener(
      this.drawingManager,
      'overlaycomplete',
      (event) => {
        if (event.type === google.maps.drawing.OverlayType.POLYGON) {
          const paths = event.overlay.getPaths();
          for (let p = 0; p < paths.getLength(); p++) {
            google.maps.event.addListener(
              paths.getAt(p),
              'set_at',
              () => {
                if (!event.overlay.drag) {
                  self.updatePointList(event.overlay.getPath());
                }
              }
            );
            google.maps.event.addListener(
              paths.getAt(p),
              'insert_at',
              () => {
                self.updatePointList(event.overlay.getPath());
              }
            );
            google.maps.event.addListener(
              paths.getAt(p),
              'remove_at',
              () => {
                self.updatePointList(event.overlay.getPath());
              }
            );
          }
          self.updatePointList(event.overlay.getPath());
          this.selectedShape = event.overlay;
          this.selectedShape.type = event.type;
        }
        if (event.type !== google.maps.drawing.OverlayType.MARKER) {
          // Switch back to non-drawing mode after drawing a shape.
          self.drawingManager.setDrawingMode(null);
          // To hide:
          self.drawingManager.setOptions({
            drawingControl: false,
          });
        }
      }
    );
  }
  private setCurrentPositions() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        if(!this.polygonCoords?.length){
          this.polygonCoords = [position.coords.latitude, position.coords.longitude];
        }
        // this.lng = position.coords.longitude;
      });
    }
  }

  deleteSelectedShape() {
    if (this.selectedShape) {
      this.selectedShape.setMap(null);
      this.selectedArea = 0;
      this.pointList = [];
      this.coodinatesPolygon.setValue('')
      // To show:
      this.drawingManager.setOptions({
        drawingControl: true,
      });
    }
  }


arr:any =[]

  updatePointList(path) {
    this.pointList = [];
    const len = path.getLength();
    for (let i = 0; i < len; i++) {
      this.pointList.push(
        path.getAt(i).toJSON()
      );

      // get places names
      this.getPlaceName(path.getAt(i).toJSON().lat, path.getAt(i).toJSON().lng).subscribe((result) => {
        let placeName = result.results[0].formatted_address;
        this.searchControl.setValue(placeName)
       
        this.save()
      });

    }
    // this.pointList.forEach((latLng:any) => {
    //   this.arr.push(latLng)
    //   this.coodinatesPolygon.setValue([this.arr]);
    // })


    let latlong = this.pointList.map((item) => [item.lat, item.lng])
this.coodinatesPolygon.setValue(latlong)
    this.selectedArea = google.maps.geometry.spherical.computeArea(
      path
    );
  }


  // get place names
  getPlaceName(lat: number, lng: number) {
    const apiKey = 'AIzaSyCPaWZ82O2KbXCsZwnZXq0Qe_kKu-SL_WA';
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;
    return this.http.get<any>(url);
    
  }

}
