import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AppService } from 'src/app/services/app.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordDialogComponent implements OnInit {

  form:FormGroup = new FormGroup({
    oldPassword:new FormControl('',[Validators.required]),
    newPassword:new FormControl('',[Validators.required,Validators.minLength(6)]),
    confirmPassword:new FormControl('',[Validators.required,Validators.minLength(6)]),
  },{validators:[MatchPassword]});

  isLoading:boolean;

  constructor(
    public dialogRef:MatDialogRef<ChangePasswordDialogComponent>,
    private _appService:AppService,
    private _userService:UserService
  ) { 

    dialogRef.addPanelClass('change-password')

  }

  ngOnInit() {
  }

  submit(){
    this.isLoading = true;
    this._userService.users.update(this._appService.user.id,{...this.form.value},null,`updatePassword/${this._appService.user.id}`).then(d => {
      this.isLoading = false;
      this.dialogRef.close();
      this._appService.toastr.success('Password Successfully Changed');
    }).catch(err => {
      this.isLoading = false;
      if(err == 'old password not correct') this.form.get('oldPassword').setErrors({invalid:'true'});
      this._appService.toastr.error(err);
    })
  }

}

function MatchPassword(c:AbstractControl):ValidationErrors{
  if(c.get('newPassword').value !== c.get('confirmPassword').value){
    c.get('confirmPassword').setErrors({invalid:'true'});
    return { confirmPassword:"New password and Confirm password should be same." };
  }
}
