import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DealService } from 'src/app/services/deal.service';
import { Page } from 'src/app/http-handler/common/contracts/page';
import { Deal } from 'src/app/models/deal.model';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'search-deal',
  templateUrl: './search-deal.component.html',
  styleUrls: ['./search-deal.component.css'],
  exportAs: 'deals'
})
export class SearchDealComponent implements OnInit {

  deals: Page<Deal>;
  @Input() createOption: boolean = true;
  @Input() createRoute: string;
  @Input() control: FormControl = new FormControl('');
  @Input() dealId: FormControl = new FormControl('');
  @Output() select: EventEmitter<Deal> = new EventEmitter();
  @Output() create: EventEmitter<any> = new EventEmitter();
  selected: Deal = null;
  @Input() showName: boolean = false;

  renderListDisplayName: (d: Deal) => string = (d: Deal) => {
    if (d) {
      if (this.showName) return `${d.dealNumber || ''} ${d.name || ''}`
      if (d.dealNumber) return d.dealNumber.toString();
      if (d.name) return d.name;
      return "N/A";
    }
    return "N/A";
  };


  constructor(
    private _dealService: DealService,
    private _router: Router,

  ) {

    this.deals = new Page({
      api: _dealService.deals,
      properties: new Deal(),
      pageSize: 50,
      filters: [{
        field: 'dealNumber',
        value: null
      }, {
        field: 'name',
        value: null
      }, {
        field: 'leadId',
        value: null
      }, {
        field: 'sortBy',
        value: 'sortDealNumInDesc',
      },]
    });
    this.deals.isLoading = true;

    this.fetch();

  }

  ngOnInit() {
  }

  search(v: any) {
    if (!isNaN(v)) {
      this.deals.filters.properties['dealNumber'].value = v;
      this.deals.filters.properties['name'].value = null;
    } else {
      this.deals.filters.properties['dealNumber'].value = null;
      this.deals.filters.properties['name'].value = v;
    }
    this.dealId?.setValue('');
    this.selected = null;
    this.fetch();
  }

  fetch() {
    this.deals.fetch().then(d => {

    }).catch(err => {
      //console.log(err);
    })
  }

  display(d: Deal) {
    if (!d || (!d.dealNumber && !d.name)) return "";
    return `${d.name || d.dealNumber || ''}`;
  }

  onSelect(d: Deal) {
    if (!d) return;
    if (d === 'create' as any) {
      this.create.emit();
      if (this.createRoute) {
        return this._router.navigate([this.createRoute]);
      }
      return this._router.navigate(['/pages/deals/create'], { queryParams: { redirectTo: encodeURI(this._router.url + '?prefill=deal') } })
    }
    this.control?.setValue(d);
    this.dealId?.setValue(d.id);
    this.selected = d;
    this.select.emit(d);
  }
}