import { Component, HostBinding, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Lead } from 'src/app/models/lead.model';
import { LeadServices } from 'src/app/models/lead-service.model';
import { Page } from 'src/app/http-handler/common/contracts/page';
import { LeadServicesService } from 'src/app/services/lead-services.service';
import { Services } from 'src/app/models/service.model';
import { AudienceService } from 'src/app/services/audience.service';
import { AppService } from 'src/app/services/app.service';
import { ValidatationService } from 'src/app/shared/validator.service';
import { Audience } from 'src/app/models/audience.model';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-add-email',
  templateUrl: './add-email.component.html',
  styleUrls: ['./add-email.component.scss'],
})
export class AddEmailComponent implements OnInit {
  isLoading: boolean = false;
  isServiceArrowRotated:boolean = false;
  isMarketArrowRotated:boolean = false;
  isStateArrowRotated:boolean = false;

  form: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    market: new FormControl('', [Validators.required]),
    subServices: new FormControl('', []),
    address: new FormControl(),
    note: new FormControl(),
    serviceId: new FormControl(),
    state: new FormControl(),
  });

  leadService: Page<LeadServices> = new Page({
    api: this.leadServices.leadServices,
    properties: new LeadServices(),
    filters: [{ field: 'name', value: '' }],
  });

  service: Page<Services> = new Page({
    api: this.leadServices.services,
    properties: new Services(),
    serverPaging: false,
    // filters: [{field: 'serviceId', value: ''}]
  });

  shootSubCategoryItems = [
    { name: 'Drone Laws', selected: false },
    { name: 'Project Detail', selected: false },
    { name: 'Photo Event', selected: false },
    { name: 'Drone Services', selected: false },
    { name: 'Drone Video', selected: false },
    { name: 'Video Production', selected: false },
    { name: 'Commercial Real Estate', selected: false },
    { name: 'Drone Photography', selected: false },
    { name: 'Fixed Wing Photography', selected: false },
    { name: 'Residential Real Estate', selected: false },
    { name: 'Matterport & LIDAR', selected: false },
    { name: 'Insurance Inspection', selected: false },
    { name: 'Land Survey & Mapping Event', selected: false },
  ];

  constructor(
    public dialogRef: MatDialogRef<AddEmailComponent>,
    private leadServices: LeadServicesService,
    private audienceService: AudienceService,
    private appService: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      ValidatationService.updateForm(this.form, data);
      this.form.get('serviceId')?.setValue(data?.service?.id);
      this.leadService.filters.properties['name'].value = data?.service?.name;
      this.fetchLeads(data);
    }
    this.fetchservice();
  }

  ngOnInit() {
  }

  async fetchLeads(data) {
    try {
      await this.leadService.fetch().then((d) => {
    
        d.items.forEach((i) => {
          data?.subServices.forEach((d) => {
            d == i.name ? (i.selected = true) : null;
          });
        });
      });
    } catch (error) {}
  }

  async fetchservice() {
    try {
      await this.service.fetch();
    } catch (error) {}
  }

  serviceName:any;

  selectService(item: any) {
    this.serviceName = item.name
    let filteredName = item?.name.replace(/\s/g, '');
    if (filteredName) {
      this.leadService.filters.properties['name'].value = filteredName;
      this.fetchLeads('');
    }
  }

 

  // serviceName: any[] = [];

  // emailService(item: string) {
  //   if (this.serviceName.includes(item)) {
  //     const index = this.serviceName.indexOf(item);
  //     this.serviceName.splice(index, 1);
  //   } else {
  //     this.serviceName.push(item);
  //   }
  // }

  async createAudience() {
    try {
      if (this.form.invalid) {
        return this.form.markAllAsTouched();
      }

      if (!this.form.get('serviceId').value) {
        return this.appService.toastr.error('Please select service');
      }

      let body: any = { ...this.form.value };
      body.subServices = this.leadService.items
        .filter((n) => n.selected)
        .map((n) => {
          return n.name;
        });

      if (!body.subServices.length) {
        return this.appService.toastr.error('Please select assigned services');
      }

      this.isLoading = true;
      const res = await this.audienceService.audience.create(body);
      this.appService.toastr.success('Audience added successfully');
      this.dialogRef.close(res);
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      this.appService.toastr.error(error as any);
    }
  }

  async updateAudience() {
    try {
      if (this.form.invalid) {
        return this.form.markAllAsTouched();
      }
      if (!this.form.get('serviceId').value) {
        return this.appService.toastr.error('Please select service');
      }
      let body: any = { ...this.form.value };
      body.subServices = this.leadService.items
        .filter((n) => n.selected)
        .map((n) => {
          return n.name;
        });

      if (!body.subServices.length) {
        return this.appService.toastr.error('Please select lead services');
      }

      this.isLoading = true;
      const res = await this.audienceService.audience.update(
        this.data?.id,
        body
      );
      this.appService.toastr.success('Audience updated successfully');
      this.dialogRef.close(res);
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      this.appService.toastr.error(error as any);
    }
  }

  toggleMarketDropdown() {
    this.isMarketArrowRotated = !this.isMarketArrowRotated;
  }

  toggleStateDropdown() {
    this.isStateArrowRotated = !this.isStateArrowRotated;
  }

  toggleServiceDropdown() {
    this.isServiceArrowRotated = !this.isServiceArrowRotated;
  }
}
