import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'priority'
})

export class PriorityPipe implements PipeTransform {

  transform(value: any, args?: string): any {
    if(args && args.includes('color')){
    return this.label(value).color;
    }else{
      return this.label(value).label;
    }
  }

  label(r: number): Level {
    switch (r) {
      case 1:
        return new Level({ label: 'Very Low', color: '#03a20d', imgName: 'RED' });
      case 2:
        return new Level({ label: 'Low', color: '#ffcc14', imgName: 'BLUE' });
      case 3:
        return new Level({ label: 'Medium', color: '#f36523', imgName: 'YELLOW' });
      case 4:
        return new Level({ label: 'High', color: '#ef2222', imgName: 'ORANGE' });
      case 5:
        return new Level({ label: 'Immediate', color: '#940000', imgName: 'RED' });
      default:
        return new Level({ label: 'Cold', color: '#226dee', imgName: 'GREEN' });
    }
  }

}

class Level {
  label?: string;
  color?: string;
  imgName?: string;
  constructor(level: Level) {
    this.label = level.label;
    this.color = level.color;
    this.imgName = level.imgName;
  }
}
