import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'Med'
})
export class LeadMedPipe implements PipeTransform {
  transform(medium: any, args?: any): any {
    if (args == 'medd') return this.Medium(medium);
  }
  Medium(medium: string) {
    if (medium === 'printedAd') {
      return "Printed Ad";
    }
    if (medium === 'socialMediaAdsIG') {
        return "Social Media Ads IG";
      }
      if (medium === 'socialMediaAdsFB') {
        return "Social Media Ads FB";
      }
      if (medium === 'crej') {
        return "CREJ";
      }
      if (medium === 'rmaWebsite') {
        return "RMA Website";
      }
      if (medium === 'rmpWebsite') {
        return "RMP Website";
      }
      if (medium === 'crepWebsite') {
        return "CREP Website";
      }
      if (medium === 'dbhWebsite') {
        return "DBH Website";
      }
      if (medium === 'introductionOrReferral') {
        return "Introduction Or Referral";
      }
      if (medium === 'coldCall') {
        return "Cold Call";
      }
      if (medium === 'coldEmail') {
        return "Cold Email";
      }
      if (medium === 'eMailCampaign') {
        return "E-Mail Campaign";
      }
      
  }
}

// medium = [{ label: 'Printed Ad', value: 'printedAd' }, { label: 'Social Media Ads IG', value: 'socialMediaAdsIG' },
// { label: 'Social Media Ads FB', value: 'socialMediaAdsFB' }, { label: 'CREJ', value: 'crej' },
// { label: 'RMA Website', value: 'rmaWebsite' }, { label: 'RMP Website', value: 'rmpWebsite' },
// { label: 'CREP Website', value: 'crepWebsite' }, { label: 'DBH Website', value: 'dbhWebsite' },
// { label: 'Introduction Or Referral', value: 'introductionOrReferral' }, { label: 'Cold Call', value: 'coldCall' },
// { label: 'Cold Email', value: 'coldEmail' }, { label: 'E-Mail Campaign', value: 'eMailCampaign' },
// ];