import { Lead, Admin } from './lead.model';
import { Deal } from './deal.model';
import { Shoot } from './shoots.model';
import { Department } from './department.model';
import { Team } from './team.model';
import { Contact } from './contacts.model';
import { Account } from './accounts.model';
import { TaskCategory } from './task-category.model';
import { SubTask } from "./sub-task.model";
import { Goal } from './goal.model';

export class Task {
    id?: number;
    title?: string;
    description?: string;
    position?: number;
    type?: string;
    dueDate?: string;
    createdAt?: string;
    selected?: boolean;
    filter?: string;
    status?: string;
    isCompleted?: boolean;
    dealId?: number;
    shootId?: number;
    leadId?: number;
    accountId?: number;
    contactId?: number;
    contact?: Contact = new Contact();
    account?: Account = new Account();
    teamDepartmentId?: number;
    teamDepartment?: Department = new Department();
    lead?: Lead = new Lead();
    deal?: Deal = new Deal();
    shoot?: Shoot = new Shoot();
    admin?: Admin = new Admin();
    adminId?: number;
    assignedTo?: number;
    totalTaskCount?: number;
    completedTaskCount?: number;
    tag?: string;
    assigned?: Team = new Team();
    btnDisable?: boolean;
    taskCategoryId?:number;
    taskCategory?: TaskCategory = {};
    taskSubTasks?: SubTask[] = [];
    taskAttachments?: [] = [];
    taskLogs?: TaskLog[] = [];
    tasks?: Task[] = [];
    parentId?: number;
    priority?: number;
    parent?:Task;
    taskWatchLists?:TaskWatchList[] = [];
    goals?:Goal[] = [];
}

export class TaskWatchList {
    adminId?:number;
    id?: number;
    taskId?: number;
    admin?:Admin
    btnDiaable?:boolean;
}

class TaskLog {
    createdAt?: string;
    id?: number;
    taskId?: number;
    title?: string;
}

