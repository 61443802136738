<header class="flex pl-3">
  <div class="flex-1">
    <font-20 size="22">{{ (data?.title | titlecase) || 'Video'}}</font-20>
  </div>
  <button mat-icon-button (click)="dialogRef.close()">
    <mat-icon>clear</mat-icon>
  </button>
</header>
<div class="video">
  <video #video controls [src]="data.video" [poster]="data.poster"></video>
</div>