import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
} from '@angular/core';
import { ContactService } from 'src/app/services/contact.service';
import { Contact } from 'src/app/models/contacts.model';
import { Page } from 'src/app/http-handler/common/contracts/page';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'search-contact',
  templateUrl: './select-contact.component.html',
  styleUrls: ['./select-contact.component.css'],
  exportAs: 'accounts',
})
export class SelectContactComponent implements OnInit, OnChanges {
  contacts: Page<Contact>;

  @Input() createOption: boolean = true;
  @Input() selectMultipal: boolean = false;
  @Input() accoutId: number = null;
  @Input() controlIds: FormControl = new FormControl([]);
  @Input() control: FormControl = new FormControl('');
  @Input() id: FormControl = new FormControl('');
  @Input() quereParams: any = {};
  selected: Contact = null;

  @Output() onSelect: EventEmitter<Contact> = new EventEmitter();
  @Output() create: EventEmitter<any> = new EventEmitter();

  constructor(private _contactService: ContactService, public router: Router) {
    // this.contacts.isLoading = false

    this.contacts = new Page({
      api: _contactService.contacts,
      properties: new Contact(),
      pageSize: 50,
      filters: [
        {
          field: 'status',
          value: 'active',
        },
        {
          field: 'search',
          value: null,
        },
        {
          field: 'accountId',
          value: null,
        },
      ],
    });
    // this.fetch();
    this.contacts.isLoading = true;
  }

  ngOnInit() {}

  ngOnChanges() {
    this.contacts.filters.properties['accountId'].value = this.accoutId || null;
    this.fetch();
    this.contacts.isLoading = false;
  }

  fetch() {
    this.contacts
      .fetch()
      .then((d) => {})
      .catch((err) => {
        //console.log(err);
      });
  }

  searchTimeout: any;
  search(value) {
    if (this.searchTimeout) clearTimeout(this.searchTimeout);
    this.contacts.filters.properties['search'].value = value;
    this.id.setValue('');
    if (this.control.value && this.control.value.id) {
      // this.accounts.filters.properties['name'].value = null;
      this.onSelect.emit(null);
    }
    this.selected = null;

    this.searchTimeout = setTimeout(() => {
      this.fetch();
    }, 500);
  }

  async getContectbyId(id) {
    let c = this._contactService.contacts.get(id);
    c.then((d) => {
      if (!this.contacts.items.find((i) => i.id === d.id)) {
        this.contacts.items.push(d);
      }
    });
    return c;
  }

  selectContact(c: Contact) {
    const e = document.activeElement as HTMLElement;
    if (e) e.blur();
    if (c === 'create') {
      this.create.emit();
      return this.router.navigate(['/pages/contacts/create'], {
        queryParams: {
          redirectTo: encodeURI(this.router.url + '?prefill=contact'),
        },
      });
    }
    if (!c || !c.id) {
      this.control.setValue('');
      this.id.setValue('');
      this.onSelect.emit(null);
    }
    if (this.selectMultipal) {
      let v;
      this.controlIds.setValue([...this.controlIds.value, c.id]);
      this.control.setValue('');
      this.onSelect.emit(c);
    } else {
      this.selected = c;
      this.control.setValue(c);
      this.id.setValue(c.id);
      this.onSelect.emit(c);
    }
  }

  display(c: Contact) {
    if (!c || !c.fullName) return;
    return c.fullName;
  }
}
