import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Industry } from 'src/app/models/accounts.model';
import { Page } from 'src/app/http-handler/common/contracts/page';
import { FormControl } from '@angular/forms';
import { Shoot } from 'src/app/models/shoots.model';
import { Router } from '@angular/router';
import { AccountsService } from 'src/app/services/accounts.service';

@Component({
  selector: 'app-search-industry',
  templateUrl: './search-industry.component.html',
  styleUrls: ['./search-industry.component.css']
})
export class SearchIndustryComponent implements OnInit {

  industries: Page<Industry>;
  @Input() control: FormControl = new FormControl('');
  @Input() id:FormControl = new FormControl('');
  @Output() create:EventEmitter<any> = new EventEmitter();
  @Input() selected:boolean;
  @Output() onSelect: EventEmitter<Shoot> = new EventEmitter();
  @Input() placeholder:string;


  constructor(
    private _industryServices: AccountsService,
    private _router:Router,
  ) {

    this.industries = new Page({
      api: _industryServices.industries,
      properties: new Industry(),
      pageSize: 50,
      filters: [{
        field: 'status',
        value: 'active'
      }, {
        field: 'name',
        value: null
      }]
    });
    this.industries.isLoading = true;

    this.fetch();

  }

  ngOnInit() {
  }

  fetch() {
    this.industries.fetch().then(d => {
      if(this.selected && d.items.length){
        this.select(d.items[0]);
      }
    }).catch(err => {
      //console.log(err);
    })
  }

  display(s: Industry) {
    if (s) {
      return s.name;
    }
  }

  select(s: Industry) {
    this.control.setValue(s);
    this.id.setValue(s['id']);
  }

  getById(id) {
    let s =  this._industryServices.industries.get(id);
    s.then(d => {
      if (!this.industries.items.find(i => i.id === d.id)) {
        this.industries.items.push(d);
      }
    })
    return s;
  }


}
