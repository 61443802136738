import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Page } from 'src/app/http-handler/common/contracts/page';
import { AdminInterest, CourseInterest } from 'src/app/models/courseInterest.model';
import { AppService } from 'src/app/services/app.service';
import { CourseInterestService } from 'src/app/services/courseInterest.service';
import { UserService } from 'src/app/services/user.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-edit-Interest',
  templateUrl: './edit-Interest.component.html',
  styleUrls: ['./edit-Interest.component.scss']
})
export class EditInterestComponent implements OnInit {

  id: any = '';

  courseInterests: Page<CourseInterest> = new Page({
    api: this._courseInterestService.courseInterests,
    serverPaging: false,
  });

  adminInterests: Page<AdminInterest> = new Page({
    api: this._courseInterestService.adminInterests,
    filters: [{
      field: 'adminId',
      value: this.id
    }]
  })
  loading: boolean;

  constructor(
    public appService: AppService,
    private router: Router,
    private _activatedRoute: ActivatedRoute,
    private _courseInterestService: CourseInterestService,
    private _userService: UserService
  ) {

    this.id = _activatedRoute.snapshot.params['id'];
    if (!isNaN(parseInt(this.id))) {
      this.adminInterests.filters.properties['adminId'].value = this.id;
      this.fetchAdminInterests();
    }
    this.fetchCourseInterests();

  }

  ngOnInit() {
  }

  fetchAdminInterests() {
    this.adminInterests.fetch().then(d => {
      this.preSelected();
    }).catch(err => {
      this.appService.toastr.error(err);
    })
  }

  fetchCourseInterests() {
    this.courseInterests.fetch().then(d => {
      d.items.forEach((item, index, ary) => {
        item.children = ary.filter(i => i.sourceId === item.id);
      })
      this.preSelected();
    }).catch(err => {
      this.appService.toastr.error(err);
    })
  }

  preSelected() {
    if (this.courseInterests?.items?.length && this.adminInterests?.items.length) {
      const capturingInterest = this.courseInterests.items.find(i => i.name.toLowerCase() === 'capturing');
      const editingInterest = this.courseInterests.items.find(i => i.name.toLowerCase() === 'editing');

      if (capturingInterest) this.selectedChidren(capturingInterest, this.capturingInterest);
      if (editingInterest) this.selectedChidren(editingInterest, this.editingInterest);

    }
  }

  selectedChidren(item: CourseInterest, interest: string[]) {
    if (interest.includes(item.name.toLowerCase())) {
      item.select = true;
    }
    if (item?.children?.length) {
      item.children.forEach(i => this.selectedChidren(i, interest));
    }
  }


  onCheck(item: CourseInterest) {
    if (!item.select) {
      if (item?.children?.length) {
        item.children.forEach(i => { i.select = false; this.onCheck(i) });
      }
    }
  }

  update() {
    this.loading = true;
    this._courseInterestService.adminInterests.create({ courseInterests: this.selectInterests, adminId: this.id } as any).then(d => {
      this.loading = false;
      this.router.navigate([this.appService?.user?.adminType === 'external' ? '/external-resource/profile' : '/pages/admins/resource/external/detail', this.id]);
    }).catch(err => {
      this.loading = false;
      this.appService.toastr.error(err);
    })
  }

  get capturingInterest(): string[] {
    var c: any = this.adminInterests.items.filter(i => i.capturingInterest).map(i => i.capturingInterest.toLowerCase().split(',')) || [];
    c = _.flattenDeep(c);
    c = _.uniqWith(c, _.isEqual);
    return c;
  }

  get editingInterest(): string[] {
    var c: any = this.adminInterests.items.filter(i => i.editingInterest).map(i => i.editingInterest.toLowerCase().split(',')) || [];
    c = _.flattenDeep(c);
    c = _.uniqWith(c, _.isEqual);
    return c;
  }

  get selectInterests(): number[] {
    return this.courseInterests.items.filter(i => (i.select && !i?.children?.length)).map(i => i.id);
  }

}
