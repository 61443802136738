export class FireBaseMessage {
    chatId?: string;
    sentBy?: string;
    sentById?: number;
    name?: string;
    message?: string;
    msgId?: string;
    adminId?: number;
    jobApplicantId?: number;
    timeStamp?: number;
    isMyMessage?: boolean;
    file?: File;
}
class File {
    name?: string
    url?: string
    fileType?: string
}