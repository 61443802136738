import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Page } from 'src/app/http-handler/common/contracts/page';
import { Shoot } from 'src/app/models/shoots.model';
import { ShootService } from 'src/app/services/shoot.service';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'search-shoot',
  templateUrl: './search-shoot.component.html',
  styleUrls: ['./search-shoot.component.css']
})
export class SearchShootComponent implements OnInit {

  shoots: Page<Shoot>;
  @Input() createOption:boolean =true;
  @Input() control: FormControl = new FormControl('');
  @Output() create: EventEmitter<any> = new EventEmitter();
  @Input() id:FormControl = new FormControl('');
  @Output() onSelect: EventEmitter<Shoot> = new EventEmitter();
  selected:Shoot = null;

  constructor(
    private _shootService: ShootService,
    private _router: Router,
  ) {

    this.shoots = new Page({
      api: _shootService.shoots,
      properties: new Shoot(),
      pageSize: 50,
      filters: [{
        field: 'status',
        value: 'active'
      }, {
        field: 'name',
        value: null
      }]
    });
    this.shoots.isLoading = true;



    this.fetch();
    this.shoots.isLoading = false

  }

  ngOnInit() {
    // this.control.valueChanges.subscribe(d => //console.log(d));
  }

  fetch() {
    this.shoots.fetch().then(d => {

    }).catch(err => {
      //console.log(err);
    })
  }

  display(s: Shoot) {
    if (s) {
      return s.name;
    }
  }

  search(n: string) {
    this.shoots.filters.properties['name'].value = n;
    this.id.setValue('');
    this.selected = null;
    this.fetch();
  }

  selectAccount(s: Shoot) {
    //console.log(s);
    if (!s) return;
    if (s === 'create' as any) {
      this.create.emit();
      return this._router.navigate(['/pages/shoots/create'], { queryParams: { redirectTo: encodeURI(this._router.url + '?prefill=shoot') } })
    }
    this.onSelect.emit(s);
    this.control.setValue(s);
    this.id.setValue(s.id);
    this.selected = s;
  }

  getAccountById(id) {
    let s = this._shootService.shoots.get(id);
    s.then(d => {
      if (!this.shoots.items.find(i => i.id === d.id)) {
        this.shoots.items.push(d);
      }
    })
    return s;
  }

  dbleClick(v){
    // //console.log(this.control.value);
    // if(v.id) this.control.setValue(v);
  }


}