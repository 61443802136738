import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Page } from 'src/app/http-handler/common/contracts/page';
import { ShootSubService } from 'src/app/services/shoot-subcategories.service';
import { ShootSub } from "../../../../models/shoot-subcategory.model";
@Component({
  selector: 'shoot-subcategories',
  templateUrl: './shoot-subcategories.component.html',
  styleUrls: ['./shoot-subcategories.component.css']
})
export class ShootSubcategoriesComponent implements OnInit, OnChanges {
  shootSubCat: Page<ShootSub>;
  @Input() id: FormControl = new FormControl('');
  @Input() Model: FormControl = new FormControl('');
  @Input() shootCategoryId: any;
  constructor(
    private _CommenService: AppService,
    private _Router: Router,
    private _ActivatedRoute: ActivatedRoute,
    private shootSubService: ShootSubService
  ) {
    this.shootSubCat = new Page({
      api: shootSubService.shootSubcat,
      properties: new ShootSub(),
      filters: [{
        field: 'shootCategoryId',
        value: null,
      },
      ],
    });
    // this.fetch();
  }

  ngOnInit() {
    // //console.log(this.shootCategoryId)
  }

  ngOnChanges() {
    // //console.log(this.shootCategoryId)
    if (this.shootCategoryId) {
      this.shootSubCat.filters.properties['shootCategoryId'].value = this.shootCategoryId;
    } else {
      this.shootSubCat.filters.properties['shootCategoryId'].value = null
    }

    this.fetch();
  }

  fetch() {
    this.shootSubCat.fetch().then(d => {
      if (d.items.length && !this.id.value) {
        this.id.setValue(d.items[0].id);
        this.Model.setValue(d.items[0]);

      } else {
        this.id.setValue(null);
        this.Model.setValue('');
      }
    }).catch(err => {
      //console.log(err);
    })

  }

  selectSubCat(id: any) {
    this.Model.setValue(this.shootSubCat.items.find(i => i.id === parseInt(id)));
  }
}