const mapper: any = (model: Object) => {
    let obj = {};
    Object.keys(model).forEach(k => {
        const type = typeof model[k];
        if (model[k] === undefined || model[k] === NaN || model[k] === "") {
            obj[k] = null;
        } else if (model[k] instanceof Array) {
            if (model[k].length) {
                const arrayType = typeof model[k][0]
                if (arrayType === 'object') {
                    obj[k] = [];
                    model[k].forEach((item) => obj[k].push(mapper(item)))
                }else{
                    obj[k] = model[k];
                }
            }else{
                obj[k] = [];
            }

        }else if(model[k] instanceof Date){
            obj[k] = model[k]
        } else if (type === 'object') {
            if (!isEmpty(model[k])) {
                obj[k] = mapper(model[k]);
            } else {
                obj[k] = null;
            }
        } else {
            obj[k] = model[k];
        }
    });
    return obj;
}

function isEmpty(arg) {
    for (var item in arg) {
        return false;
    }
    return true;
}

export { mapper };