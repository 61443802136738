<!-- <div class="position-relative w-100 d-table-cell"> -->
  
  <!-- <div class="position-absolute icon">
    <i class="fa fa-spinner fa-spin" *ngIf="industries?.isLoading"></i>
    <img *ngIf="!industries?.isLoading" src="../../../../../assets/images/icon_search gray.png">
  </div> -->

  
<!-- </div> -->

<div class="form-field">
  <input [formControl]="control" [matAutocomplete]="auto" [placeholder]="placeholder || 'Search Industry...'" (input)="fetch()"
  [(ngModel)]="industries.filters.properties['name'].value">
  <div class="append flex">
    <i class="fa fa-spinner fa-spin" *ngIf="industries?.isLoading"></i>
    <img *ngIf="!industries?.isLoading" src="../../../../../assets/images/icon_search gray.png">
  </div>
</div>

<mat-autocomplete #auto="matAutocomplete" [displayWith]="display"
  (optionSelected)="select($event.option.value)">
  <mat-option *ngFor="let a of industries.items" [value]="a">{{a?.name}}</mat-option>
</mat-autocomplete>