<div class="popup-vdo">
  <video class="vdoply" (timeupdate)="seek.value = videoPlayer.currentTime" [src]="selectedVideoSrc || videos[0]" #videoPlayer (progress)="isLoading = true;"
    (load)="isLoading = false" (loadedmetadata)="isLoading = false" >
    Sorry, Web RTC is not available in your browser
  </video>


  <!-- <div class="loading" *ngIf="isLoading">
    <loading></loading>
  </div> -->

  <div class="vedios-list" [hidden]="!selectedVideoSrc && !videos?.length" >
    <div class="play-controls">
      <div class="pr-2">
        <button class="play-icon" (click)="playToggle()">
          <mat-icon [inline]="true" *ngIf="videoPlayer?.paused">play_arrow</mat-icon>
          <mat-icon [inline]="true" *ngIf="!videoPlayer?.paused">pause</mat-icon>
        </button>
      </div>
      <div class="play-line px-2">
        <div class="progress-play">
          <output for="seek" onforminput="value = foo.valueAsNumber;"></output>
          <input #seek class="seek" name="seek" id="seek" (change)="videoPlayer.currentTime = $event?.target?.value" [value]="videoPlayer?.currentTime" [max]="videoPlayer?.duration" min="0" type="range" step="1" >
        </div>
        <div class="pl-2">
          <span><img src="assets/svg/vdo-expand.svg" /></span>
        </div>
      </div>
    </div>

    <div class="vdo-slider mt-2" *ngIf="videos?.length > 1">
      <ngx-slick-carousel class="carousel row" #slickModal="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem *ngFor="let slide of videos;let i = index;" class="slide col-lg-2 mt-2">
          <!-- <video class="busn_vdo" *ngIf="account?.properties?.aboutUsVideo" (error)="account.properties.aboutUsVideo = ''"
                [src]="account?.properties?.aboutUsVideo"></video> -->
          <div class="position-relative">
            <video class="slidervdoply" src="{{slide}}" (error)="videos?.splice(i,1)"
              (click)="selectAndPlay(slide)"></video>
            <button class="splay-icon">
              <mat-icon [inline]="true">play_arrow</mat-icon>
            </button>
          </div>
        </div>
      </ngx-slick-carousel>

      <div class="vdo-slider-icon justify-content-between d-flex" [hidden]="videos?.length < 6">
        <span class="prev-icon"><img src="assets/svg/left-arrow-icon.svg" /></span>
        <span class="next-icon"><img src="assets/svg/right-arrow-icon.svg" /></span>
      </div>
    </div>
  </div>


</div>