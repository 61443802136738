import { Injectable } from '@angular/core';
import { MediaCategory } from '../models/mediaCategory.model';
import { HttpClient } from '@angular/common/http';
import { IApi } from '../http-handler/common/contracts/api';
import { GenericApi } from '../http-handler/common/generic-api';

@Injectable({
  providedIn: 'root'
})
export class MediaCategoryService {

  mediaCategories: IApi<MediaCategory>;



  constructor(private http: HttpClient) {
    this.mediaCategories = new GenericApi<MediaCategory>('mediaCategories', http);
  }

}
