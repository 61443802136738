import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'Imag'
})
export class ImageStylePipe implements PipeTransform {
  transform(style: any, args?: any): any {
    if (args == 'img') return this.Style(style);
  }
  Style(style: string) {
    if (style === 'singleImageGround') {
      return "Single Image Ground";
    }
    if (style === 'multipleImageHEGround') {
        return "Multiple Image HE Ground";
      }
      if (style === 'multipleImageHDRGround') {
        return "Multiple Image HDR Ground";
      }
      if (style === 'singleImageDrone') {
        return "Single Image Drone";
      }
      if (style === 'multipleImageHDRDrone') {
        return "Multiple Image HDR Drone";
      }
      if (style === 'singleImageFixedWingAerial') {
        return "Single Image Fixed Wing Aerial";
      }    
  }
}