<header class="flex content-between">
  <font-25>Personal Information</font-25>
  <button class="icon-btn md" (click)="dialogRef.close(false)">
    <img src="assets/svg/close (2).svg" alt="">
  </button>
</header>
<mat-progress-bar [mode]="'indeterminate'" *ngIf="isLoading"></mat-progress-bar>
<section>
  <form autocomplete="off" [formGroup]="form" (submit)="update()">

    <div class="form-field">
      <input type="text" placeholder="First Name" formControlName="firstName" maxlength="70" >
      <control-messages [control]="form.get('firstName')"></control-messages>
    </div>

    <div class="form-field">
      <input type="text" placeholder="Last Name" formControlName="lastName" maxlength="70" >
      <control-messages [control]="form.get('lastName')"></control-messages>
    </div>

    <div class="form-field">
      <input type="text" placeholder="Username" formControlName="username" maxlength="20" >
      <control-messages [control]="form.get('username')"></control-messages>
    </div>

    <div class="form-field">
      <input type="email" disabled placeholder="Email" formControlName="email">
      <control-messages [control]="form.get('email')"></control-messages>
    </div>

    <div class="form-field">
      <input type="text" NumberInput maxlength="15" placeholder="Phone" formControlName="mobile">
      <control-messages [control]="form.get('mobile')"></control-messages>
    </div>

    <div class="text-right">
      <button class="theme-btn orange" type="submit" [disabled]="isLoading || form.invalid">
        Update </button>
    </div>

  </form>
</section>