<div>
  <input [formControl]="control" [matAutocomplete]="auto" placeholder="Search contact..." InputUi
    (input)="search($event.target.value)">
  <div class="append">
    <i class="fa fa-spinner fa-spin" *ngIf="contacts?.isLoading"></i>
    <img *ngIf="!contacts?.isLoading" src="../../../../../assets/images/icon_search gray.png">
  </div>

  <mat-autocomplete #auto="matAutocomplete" [displayWith]="display"
    (optionSelected)="selectContact($event.option.value)">
    <mat-option *ngFor="let a of contacts.items;" [value]="a">{{a.fullName}}</mat-option>
    <mat-option class="add-btn-option p-0" [value]="'create'"
      *ngIf="router.url !== 'pages/contacts/create' && createOption">
      <div class="add-account">
        Create new
        <mat-icon>add</mat-icon>
      </div>
    </mat-option>
  </mat-autocomplete>
</div>