import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'DataSrc'
})
export class dataSourcePipe implements PipeTransform {
  transform(dataSource: any, args?: any): any {
    if (args == 'dataS') return this.DataSource(dataSource);
  }
  DataSource(dataSource: string) {
    if (dataSource === 'emailSign') {
      return "Email Sign";
    }
    if (dataSource === 'website') {
        return "Website";
      }
      if (dataSource === 'businessCard') {
        return "Business Card";
      }
      if (dataSource === 'publicData') {
        return "Public Data";
      }
      if (dataSource === 'others') {
        return "Others";
      }
  }
}