import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IApi } from '../http-handler/common/contracts/api';
import { GenericApi } from '../http-handler/common/generic-api';
import { ClientDashboard } from '../models/client-dashboard.model';
import { Chat } from '../models/chat.model';
import { AppService } from './app.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ChatService {

    chats: IApi<Chat>;
    unreadMsgCount: number;
    countSubscription: Observable<number>;
    constructor(private http: HttpClient,
        private appService: AppService,) {
        this.chats = new GenericApi<Chat>('chats', http)
        this.getUnreadCount();
    }

    getUnreadCount() {
        if (this.appService?.user?.id) {
            this.chats.simpleGet({ path: 'unread/chats' }).then(d => {
                this.unreadMsgCount = d;
                this.countSubscription = d;
            }).catch(err => {
                this.appService.toastr.error(err)
            })
        }
    }
}
