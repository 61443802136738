import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostBinding, Input, NgModule, OnChanges, OnInit } from '@angular/core';

var count = 0;

@Component({
  selector: 'logo',
  template: `
    <img [src]="url" *ngIf="url" (error)="url = null;" alt="">
    <p [style.fontSize]="s ? s+'px' : d/3+'px'" *ngIf="!url" >
    {{n?.toLowerCase() == 'n/a' ? 'N/a' : text}}
    </p>
  `,
  styles: [`
  :host{
    height: 60px;
    width: 60px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    background-color: rgb(250, 250, 250);
  }
  p{
    font-weight:300;
    margin:0px;
    color:inherit;
  }
  img{
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
  `]
})
export class LogoComponent implements OnInit, OnChanges {

  @Input('diameter') d: number = 100;
  @Input('title') n: string = "";
  @Input('src') url: string = "";
  @Input('size') s: number;
  text: string = "";
  @Input('backgroundColor') bgColor: string = "";
  @Input('titleColor') titleColor: string = "";
  @Input() rounded: boolean = false;

  // @HostBinding('style.backgroundColor') get getBgColor() {
  //   if (this.bgColor) return this.bgColor;
  //   return "rgb(250, 250, 250)";
  // }

  // @HostBinding('style.borderColor') get getBorderColor() {
  //   if (this.bgColor) return this.bgColor;
  //   return "rgb(224, 224, 224)";
  // }

  constructor(el: ElementRef<HTMLElement>) {
    el.nativeElement.classList.add('bg-text-color');
    if (count >= 6) count = 0;
    ++count;
    switch (count) {
      case 1:
        el.nativeElement.classList.add("purple");
        break;
      case 2:
        el.nativeElement.classList.add("sky-blue");
        break;
      case 3:
        el.nativeElement.classList.add("pink");
        break;
      case 4:
        el.nativeElement.classList.add("dark-blue");
        break;
      case 5:
        el.nativeElement.classList.add("brown");
        break;
      case 6:
        el.nativeElement.classList.add("orange");
        break;
    }
  }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.n) {
      const n = this.n.split(' ').slice(0, 2);
      this.text = "";
      n.forEach((item) => {
        this.text += item.toUpperCase().slice(0, 1);
      })
    }
  }

  @HostBinding('style.height') get height() {
    return `${this.d}px`
  }

  @HostBinding('style.width') get width() {
    return `${this.d}px`
  }

  @HostBinding('style.borderRadius') get borderRadius() {
    if (this.rounded) {
      return "10%";
    } else {
      return "100%";
    }
  }

}

@NgModule({
  declarations: [LogoComponent],
  exports: [LogoComponent],
  imports: [CommonModule]
})
export class LogoModule {

}