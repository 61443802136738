import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IApi } from '../http-handler/common/contracts/api';
import { GenericApi } from '../http-handler/common/generic-api';
import { Department } from '../models/department.model';

@Injectable({
    providedIn: 'root'
})

export class DepartmentService {

    deptt: IApi<Department>;
    constructor(private http: HttpClient) {
        this.deptt = new GenericApi<Department>('teamDepartments', http)
    }
}
