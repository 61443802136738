import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { tap, map, switchMap, first } from 'rxjs/operators';
import { TouchSequence } from 'selenium-webdriver';
import { of } from 'rxjs';
import firebase from 'firebase';
import { AppService } from '../app.service';

@Injectable({
    providedIn: 'root'
})
export class PresenceService {
    userId: number;
    constructor(private afAuth: AngularFireAuth, private db: AngularFireDatabase,private appService:AppService) {
        this.updateOnUser().subscribe();
        this.updateOnDisconnect().subscribe();
        this.updateOnAway();
    }

    getPresence(uid: number) {
        return this.db.object(`user/${uid}`).valueChanges();
    }

    getUser() {
        return this.afAuth.authState.pipe(first()).toPromise();
    }

    async setPresence(status: string) {
        return this.db.object(`user/${this.appService?.user?.id}`).update({ status, timestamp: this.timestamp });
    }

    get timestamp() {
        return firebase.database.ServerValue.TIMESTAMP;
    }

    // Implement presence logic here
    // Updates status when logged-in connection to Firebase starts
    updateOnUser() {
        return this.db.object('.info/connected').valueChanges().pipe(
            map(connected => connected ? 'online' : 'offline')
        );

        // return this.afAuth.authState.pipe(
        //     switchMap(user => user ? connection : of('offline')),
        //     tap(status => this.setPresence(status))
        // );
    }

    updateOnDisconnect() {
        return this.afAuth.authState.pipe(
            tap(user => {
                if (this.appService?.user?.id) {
                    this.db.object(`user/${this.appService?.user?.id}`).query.ref.onDisconnect()
                        .update({
                            status: 'offline',
                            timestamp: this.timestamp
                        });
                }
            })
        );
    }

    async signOut() {
        await this.setPresence('offline');
        await this.afAuth.signOut();
    }

    // User navigates to a new tab, case 3
    updateOnAway() {
        document.onvisibilitychange = (e) => {
            if (document.visibilityState === 'hidden') {
                this.setPresence('away');
            } else {
                this.setPresence('online');
            }
        };
    }
}