<div class="position-relative">
	<div class="header p-3 bg-light">
		<input type="file" class="d-none" #fileInput (change)="onFileChange($event)">
		<div class="mr-auto">
			<h5 class="text-dark">
				EXCEL UPLOAD
			</h5>
			<a class="a" [href]="downLoadExcel()" class="text-muted d-flex align-items-end" target="_blank">
				<mat-icon class="mr-1">cloud_download</mat-icon> Download Excel format..
			</a>
		</div>
		<div class="drop-box mx-auto" (click)="fileInput.click()" (dragover)="dragOverHandler($event)"
			(drop)="drophover = false;dropHandler($event)" (dragleave)="drophover = false" [class.drophover]="drophover"
			[class.center]="!isLoading && !file" [class.header-box]="file || isLoading">

			<h5 class="text-muted">Drop a file here...</h5>
			<span class="text-muted">Drop Excel File Only</span>

		</div>
	</div>
	<div class="content" [class.active]="data[0][0] !== 1">

		<table *ngIf="data[0][0] !== 1 && !isLoading">
			<tbody>
				<tr *ngFor="let row of data">
					<td *ngFor="let val of row">
						{{val}}
					</td>
				</tr>
			</tbody>
		</table>

		<div class="h-100 w-100 d-flex" *ngIf="isLoading">
			<h4 class="m-auto">
				<i class="fa fa-spinner fa-spin"></i>
			</h4>
		</div>

	</div>
	<div class="footer p-3 bg-light text-right">
		<button class="bg-white" mat-stroked-button (click)="upload()" [disabled]="isLoading || !file">
			<mat-icon>cloud_upload</mat-icon>
			UpLoad
		</button>
	</div>
	<div class="uplaoding-outer" *ngIf="isUploading">
		<h4 class="m-auto">
			<i class="fa fa-spinner fa-spin"></i>
		</h4>
	</div>
</div>