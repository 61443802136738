import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AccountsService } from 'src/app/services/accounts.service';
import { Page } from 'src/app/http-handler/common/contracts/page';
import { Account } from 'src/app/models/accounts.model';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'search-account',
  templateUrl: './select-account.component.html',
  styleUrls: ['./select-account.component.css'],
  exportAs: 'accounts'
})
export class SelectAccountComponent implements OnInit {

  accounts: Page<Account>;
  @Input() createOption: boolean = true;
  @Input() control: FormControl = new FormControl('');
  @Input() id: FormControl = new FormControl('');
  selected: Account = null;
  @Output() onSelect: EventEmitter<Account> = new EventEmitter();
  @Output() create: EventEmitter<any> = new EventEmitter();

  constructor(
    private _AccountService: AccountsService,
    private _router: Router,

  ) {

    this.accounts = new Page({
      api: _AccountService.accounts,
      properties: new Account(),
      pageSize: 50,
      filters: [{
        field: 'status',
        value: 'active'
      }, {
        field: 'name',
        value: null
      },{
        field: 'sortBy',
        value: 'nameInAsc'
      }]
    });
    this.accounts.isLoading = true; 

    this.fetch();
    this.accounts.isLoading = false;

  }

  ngOnInit() {
  }

  fetch() {
    this.accounts.fetch().then(d => {

    }).catch(err => {
      //console.log(err);
    })
  }

  display(a: Account) {
    if (a) {
      return a.name;
    }
  }

  search(value) {
    this.accounts.filters.properties['name'].value = value;
    this.selected = null;
    if (this.control.value && this.control.value.id) {
      this.control.setValue(null);
      this.onSelect.emit(null);
      this.accounts.filters.properties['name'].value = '';
    }
    this.fetch();
  }

  selectAccount(a: Account) {

    if (a === 'create' as any) {
      this.create.emit();
      return this._router.navigate(['/pages/accounts/create'], { queryParams: { redirectTo: encodeURI(this._router.url + '?prefill=account') } })
    }

    if (!a && !a.accountAddresses) return;
    this.id.setValue(a.id);
    a.location = this.addressString(a.accountAddresses.find(i => i.type === 'billingAddress'));
    this.control.setValue(a);
    this.selected = a;
    this.onSelect.emit(a);
  }

  getAccountById(id) {
    let a = this._AccountService.accounts.get(id);
    a.then(d => {
      if (!this.accounts.items.find(i => i.id === d.id)) {
        this.accounts.items.push(d);
      }
    })
    return a;
  }

  addressString(a) {
    if (!a) {
      return;
    }
    let address;
    let { street, country, state, zipcode, city } = a;
    if (street) {
      address = street;
    }
    if (city) {
      address += ', ' + city;
    }

    if (state) {
      address += ', ' + state;
    }

    if (country) {
      address += ', ' + country;
    }

    if (zipcode) {
      address += ', ' + zipcode;
    }

    return address;

  }

}
