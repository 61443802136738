import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { DepartmentService } from 'src/app/services/department.sevice';
import { Page } from 'src/app/http-handler/common/contracts/page';
import { Department } from 'src/app/models/department.model';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-select-department',
  templateUrl: './select-department.component.html',
  styleUrls: ['./select-department.component.css']
})
export class SelectDepartmentComponent implements OnInit {
  dept: Page<Department>;
  @Input() control: FormControl = new FormControl('');
  @Output() select: EventEmitter<Department> = new EventEmitter();

  constructor(
    private DepartmentService: DepartmentService,
  ) {

    this.dept = new Page({
      api: DepartmentService.deptt,
      properties: new Department(),
      pageSize: 50,
      filters: [{
        field: 'status',
        value: 'active'
      }, {
        field: 'name',
        value: null
      }]
    });
    this.dept.isLoading = true;

    this.fetch();
  }

  ngOnInit() {
  }

  fetch() {
    this.dept.fetch().then(d => {
    }).catch(err => {
      //console.log(err);
    })
  }

  selectDeptt(d) {
    this.select.emit(this.dept.items.find(i => i.id === parseInt(d)));
  }

  display(d: Department) {
    if (!d || !d.id) return;
    if (d) {
      return d.name;
    }
  }
  // selectDeptt(d: Department) {
  //   if (!d) return;
  //   this.select.emit(d);
  // }

}
