import { User } from './user.model';
import { JobApplication } from './jobApplication.model';

export class Chat {
    id?: number;
    user2MessageCount?: number;
    user1MessageCount?: number;
    lastMessage?: string;
    text?: string;
    status?: string;
    type?: string;
    userType?: string;
    user2Id?: number;
    user1Id?: number;
    jobApplicantId?: number;
    jobId?: number;
    jobApplicationId?: number;
    blockedById?: number;
    blockedBy?: User;
    user1?: User;
    user2?: User;
    jobApplication?: JobApplication;
    sentById?:number;
}