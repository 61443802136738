import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';

import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Chat } from 'src/app/models/chat.model';
import { environment } from 'src/environments/environment';
import { FirebaseFileUpload } from '../../models/firebase-upload.model';
import { AppService } from '../app.service';

@Injectable({
    providedIn: 'root'
})

export class FileUploadService {
    private collectionUrl = environment.firebaseCollectionUrl;
    private basePath = '/uploads';

    constructor(private db: AngularFireDatabase, private storage: AngularFireStorage, private _appService: AppService) { }

    pushFileToStorage(fileUpload: FirebaseFileUpload, chat: Chat,fileType:string): Observable<number> {
        const filePath = `${this.basePath}/${fileUpload.file.name}`;
        const storageRef = this.storage.ref(filePath);
        const uploadTask = this.storage.upload(filePath, fileUpload.file);

        uploadTask.snapshotChanges().pipe(
            finalize(() => {
                storageRef.getDownloadURL().subscribe(downloadURL => {
                    fileUpload.url = downloadURL;
                    fileUpload.name = fileUpload.file.name;
                    fileUpload.fileType = fileType;
                    this.saveFileData(fileUpload, chat);
                });
            })
        ).subscribe();

        return uploadTask.percentageChanges();
    }
    // newMsg.chatId = `${chat.id}`;
    // newMsg.message = msg;
    // newMsg.sentBy = this._appService?.user?.adminType;
    // newMsg.adminId = chat.adminId;
    // newMsg.jobApplicantId = chat?.jobApplicantId;
    // newMsg.timeStamp = new Date().getTime();
    private saveFileData(fileUpload: FirebaseFileUpload, chat: Chat): void {
        let msg: any = {};
        msg.timeStamp = new Date().getTime();
        msg.file = fileUpload;
        msg.chatId = `${chat.id}`;
        msg.sentById = this._appService?.user?.id;
        // msg.adminId = chat.adminId;
        // msg.jobApplicantId = chat?.jobApplicantId;
        this.db.list(`${this.collectionUrl}/${chat?.id}`).push(msg);
    }

    getFiles(numberItems): AngularFireList<FirebaseFileUpload> {
        return this.db.list(this.basePath, ref =>
            ref.limitToLast(numberItems));
    }

    deleteFile(fileUpload: FirebaseFileUpload): void {
        this.deleteFileDatabase(fileUpload.key)
            .then(() => {
                this.deleteFileStorage(fileUpload.name);
            })
            .catch(error => console.log(error));
    }

    private deleteFileDatabase(key: string): Promise<void> {
        return this.db.list(this.basePath).remove(key);
    }

    private deleteFileStorage(name: string): void {
        const storageRef = this.storage.ref(this.basePath);
        storageRef.child(name).delete();
    }
}