import { Component, ElementRef, Inject, OnInit, ViewChild , AfterViewInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-play-video',
  templateUrl: './play-video.component.html',
  styleUrls: ['./play-video.component.scss']
})
export class PlayVideoDialogComponent implements OnInit,AfterViewInit  {

  @ViewChild('video') video:ElementRef<HTMLVideoElement>;

  constructor(
    public dialogRef: MatDialogRef<PlayVideoDialogComponent>,
    @Inject(MAT_DIALOG_DATA)  public data:{ video?:string, poster?:string, fullscreen?:boolean, title?:string }
  ) { }

  ngOnInit() {
  }

    ngAfterViewInit(){
      if(this.data.fullscreen && this.video){
        openFullscreen(this.video.nativeElement);
      }
    }

}

function openFullscreen(elem) {
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.webkitRequestFullscreen) { /* Safari */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) { /* IE11 */
    elem.msRequestFullscreen();
  }
}
