import {
  Directive,
  ElementRef,
  HostBinding,
  Input,
  NgModule,
} from '@angular/core';

@Directive({
  selector: 'font-25, [font-25]',
})
export class Font25Directive {
  @Input('color') c:
    | 'primary'
    | 'secondary'
    | 'muted'
    | 'white'
    | 'dark'
    | 'black' = 'dark';
  @Input('weight') w: 'R' | 'M' | 'B' = 'R';
  @Input('size') s: number = 25;

  constructor(private _el: ElementRef<HTMLElement>) {}

  @HostBinding('class') get class() {
    return `c-${this.c}`;
  }

  @HostBinding('style.fontWeight') get weight() {
    if (this.w == 'R') return '400';
    if (this.w == 'M') return '500';
    if (this.w == 'B') return '600';
    return this.w;
  }

  @HostBinding('style.fontSize') get size() {
    return `${this.s}px`;
  }
}

@Directive({
  selector: 'font-20, [font-20]',
})
export class Font20Directive {
  @Input('color') c:
    | 'primary'
    | 'secondary'
    | 'muted'
    | 'white'
    | 'dark'
    | 'black' = 'black';
  @Input('weight') w: 'R' | 'M' | 'B' = 'R';
  @Input('size') s: number = 20;
  @Input('shadow') t:'textShadow' | null = null

  constructor(private _el: ElementRef<HTMLElement>) {}

  @HostBinding('class') get class() {
    return `c-${this.c}`;
  }

  @HostBinding('style.fontWeight') get weight() {
    if (this.w == 'R') return '400';
    if (this.w == 'M') return '500';
    if (this.w == 'B') return '600';
    return this.w;
  }

  @HostBinding('style.fontSize') get size() {
    return `${this.s}px`;
  }

  @HostBinding('style.textShadow') get shadow(){
    if(this.t == 'textShadow')
    return `1px 4px 5px #1313135c`
  }
}

@Directive({
  selector: 'font-18, [font-18]',
})
export class Font18Directive {
  @Input('color') c:
    | 'primary'
    | 'secondary'
    | 'muted'
    | 'white'
    | 'dark'
    | 'black' = 'black';
  @Input('weight') w: 'R' | 'M' | 'B' = 'R';
  @Input('size') s: number = 18;

  constructor(private _el: ElementRef<HTMLElement>) {}

  @HostBinding('class') get class() {
    return `c-${this.c}`;
  }

  @HostBinding('style.fontWeight') get weight() {
    if (this.w == 'R') return '400';
    if (this.w == 'M') return '500';
    if (this.w == 'B') return '600';
    return this.w;
  }

  @HostBinding('style.fontSize') get size() {
    return `${this.s}px`;
  }
}
@Directive({
  selector: 'font-16, [font-16]',
})
export class Font16Directive {
  @Input('color') c:
    | 'primary'
    | 'secondary'
    | 'muted'
    | 'white'
    | 'dark'
    | 'black' = 'muted';
  @Input('weight') w: 'R' | 'M' | 'B' = 'R';
  @Input('size') s: number = 16;

  constructor(private _el: ElementRef<HTMLElement>) {}

  @HostBinding('class') get class() {
    return `c-${this.c}`;
  }

  @HostBinding('style.fontWeight') get weight() {
    if (this.w == 'R') return '400';
    if (this.w == 'M') return '500';
    if (this.w == 'B') return '600';
    return this.w;
  }

  @HostBinding('style.fontSize') get size() {
    return `${this.s}px`;
  }
}

@Directive({
  selector: 'font-14, [font-14]',
})
export class Font14Directive {
  @Input('color') c:
    | 'primary'
    | 'secondary'
    | 'muted'
    | 'white'
    | 'dark'
    | 'black' = 'muted';
  @Input('weight') w: 'R' | 'M' | 'B' = 'R';
  @Input('size') s: number = 14;

  constructor(private _el: ElementRef<HTMLElement>) {}

  @HostBinding('class') get class() {
    return `c-${this.c}`;
  }

  @HostBinding('style.fontWeight') get weight() {
    if (this.w == 'R') return '400';
    if (this.w == 'M') return '500';
    if (this.w == 'B') return '600';
    return this.w;
  }

  @HostBinding('style.fontSize') get size() {
    return `${this.s}px`;
  }
}

@Directive({
  selector: 'font-13, [font-13]',
})
export class Font13Directive {
  @Input('color') c:
    | 'primary'
    | 'secondary'
    | 'muted'
    | 'white'
    | 'dark'
    | 'black' = 'muted';
  @Input('weight') w: 'R' | 'M' | 'B' = 'R';
  @Input('size') s: number = 13;

  constructor(private _el: ElementRef<HTMLElement>) {}

  @HostBinding('class') get class() {
    return `c-${this.c}`;
  }

  @HostBinding('style.fontWeight') get weight() {
    if (this.w == 'R') return '400';
    if (this.w == 'M') return '500';
    if (this.w == 'B') return '600';
    return this.w;
  }

  @HostBinding('style.fontSize') get size() {
    return `${this.s}px`;
  }
}
@Directive({
  selector: 'font-12, [font-12]',
})
export class Font12Directive {
  @Input('color') c:
    | 'primary'
    | 'secondary'
    | 'muted'
    | 'white'
    | 'dark'
    | 'black' = 'muted';
  @Input('weight') w: 'R' | 'M' | 'B' = 'R';
  @Input('size') s: number = 12;

  constructor(private _el: ElementRef<HTMLElement>) {}

  @HostBinding('class') get class() {
    return `c-${this.c}`;
  }

  @HostBinding('style.fontWeight') get weight() {
    if (this.w == 'R') return '400';
    if (this.w == 'M') return '500';
    if (this.w == 'B') return '600';
    return this.w;
  }

  @HostBinding('style.fontSize') get size() {
    return `${this.s}px`;
  }
}

@Directive({
  selector: 'font-11, [font-11]',
})
export class Font11Directive {
  @Input('color') c:
    | 'primary'
    | 'secondary'
    | 'muted'
    | 'white'
    | 'dark'
    | 'black' = 'muted';
  @Input('weight') w: 'R' | 'M' | 'B' = 'R';
  @Input('size') s: number = 11;

  constructor(private _el: ElementRef<HTMLElement>) {}

  @HostBinding('class') get class() {
    return `c-${this.c}`;
  }

  @HostBinding('style.fontWeight') get weight() {
    if (this.w == 'R') return '400';
    if (this.w == 'M') return '500';
    if (this.w == 'B') return '600';
    return this.w;
  }

  @HostBinding('style.fontSize') get size() {
    return `${this.s}px`;
  }
}

@Directive({
  selector: 'font-10, [font-10]',
})
export class Font10Directive {
  @Input('color') c:
    | 'primary'
    | 'secondary'
    | 'muted'
    | 'white'
    | 'dark'
    | 'black' = 'muted';
  @Input('weight') w: 'R' | 'M' | 'B' = 'R';
  @Input('size') s: number = 10;

  constructor(private _el: ElementRef<HTMLElement>) {}

  @HostBinding('class') get class() {
    return `c-${this.c}`;
  }

  @HostBinding('style.fontWeight') get weight() {
    if (this.w == 'R') return '400';
    if (this.w == 'M') return '500';
    if (this.w == 'B') return '600';
    return this.w;
  }

  @HostBinding('style.fontSize') get size() {
    return `${this.s}px`;
  }
}

@NgModule({
  imports: [],
  exports: [
    Font25Directive,
    Font20Directive,
    Font16Directive,
    Font14Directive,
    Font13Directive,
    Font18Directive,
    Font12Directive,
    Font11Directive,
    Font10Directive,
  ],
  declarations: [
    Font25Directive,
    Font20Directive,
    Font16Directive,
    Font14Directive,
    Font13Directive,
    Font18Directive,
    Font12Directive,
    Font11Directive,
    Font10Directive,
  ],
})
export class FontsModule {}
