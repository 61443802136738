import { CommonModule } from "@angular/common";
import { Component, Input, NgModule } from "@angular/core";
import { FontsModule } from "../../directives/fonts/fonts";

@Component({
    selector: 'data-not-found',
    template: `
        <div class="flex p-4 center cols">
            <img style="width:{{s}}{{s?'px':'auto'}}" class="mb-2" src="assets/images/nothing-found.png" />
            <font-13>{{t}}</font-13>
        </div>
    `,
    styles: [`
        :host{
            display:block;
        }
    `]
})

export class DataNotFoundComponent {
    @Input('text') t: string = 'data not found';
    @Input('size') s: number = null;
    constructor() {

    }
}

@NgModule({
    imports: [FontsModule,CommonModule],
    declarations: [DataNotFoundComponent],
    exports: [DataNotFoundComponent]
})
export class DoNotFoundModule { }