<input type="file" id="file" class="d-none" (click)="file.value = null" (change)="selectImg($event,cropper)" #file>
<label (click)="file.click()">
    <span *ngIf="!template"> Select Image </span>
    <ng-container *ngTemplateOutlet="template"></ng-container>
</label>

<ng-template #cropper let-data let-ref="dialogRef">
    <nb-card class="dialog m-0">
        <nb-card-header>
            <font-25>
                {{selectfileType.startsWith('image') ? 'Image': 'Video'}}
            </font-25>
        </nb-card-header>
        <nb-card-body class="text-center">
            <image-cropper *ngIf="selectfileType.startsWith('image')" [imageChangedEvent]="event"
                [maintainAspectRatio]="true" [aspectRatio]="cropRatio ? cropRatio : 10/10" format="png"
                (imageCropped)="chooseImg($event)"
                (loadImageFailed)="ref.close();commenService.toastr.error('Some thing wrong with this images. please check image format or type.')">
            </image-cropper>
            <video *ngIf="selectfileType.startsWith('video')" [src]="base64" controls></video>
        </nb-card-body>
        <nb-card-footer>
            <div class="row">
                <div class="col">
                    <div class="progress mb-1" *ngIf="!dontUpload">
                        <div class="progress-bar progress-bar-striped bg-color" role="progressbar"
                            [style.width]="failed ? '0%' : uploader.progress + '%'"></div>
                    </div>
                    <div class="row m-0">
                        <div class="col p-0">
                            <strong>Uploading &nbsp; {{uploader.queue[0]?.progress}}%</strong>
                        </div>
                        <strong>Size &nbsp; {{uploader.queue[0]?.file?.size/1024/1024 | number:'.2'}} MB</strong>
                    </div>
                </div>
                <div class="px-3">
                    <button mat-button class="bg-color mr-4" (click)="done()" [disabled]="uploader.isUploading">
                        Upload
                    </button>
                    <button mat-button class="border text-muted" (click)="uploader.clearQueue();ref.close();">
                        Cancel
                    </button>
                </div>
            </div>

        </nb-card-footer>
    </nb-card>
</ng-template>