import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IApi } from '../http-handler/common/contracts/api';
import { GenericApi } from '../http-handler/common/generic-api';
import { ShootPackage } from '../models/shootPackage.model';

@Injectable({
  providedIn: 'root',
})
export class ShootPackageService {
  shootPackages: IApi<ShootPackage>;

  constructor(private http: HttpClient) {
    this.shootPackages = new GenericApi<ShootPackage>('shootPackages', http);
  }
}
