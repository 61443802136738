import { Directive, ElementRef, OnChanges, HostListener, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AppService } from 'src/app/services/app.service';

@Directive({
  selector: '[appIframe]'
})
export class IframeDirective implements OnChanges ,OnInit{

  iframe: HTMLIFrameElement;
  @Input() src?:string;

  constructor(
    private el: ElementRef<HTMLIFrameElement>,
    private sanitizer: DomSanitizer,
    private appService:AppService
    ) {

    this.iframe = el.nativeElement;

  }

  ngOnInit(){
    let safe:any = this.sanitizer.bypassSecurityTrustResourceUrl(this.src);
    this.src = safe.changingThisBreaksApplicationSecurity;

    


    if (this.src.endsWith('.xlsx')) {
      this.iframe.src = '../../../../../assets/images/excel.png';
    }else if(this.src.endsWith('.pdf')) {
      this.iframe.src = '../../../../../assets/images/pdf.png';
    }else{
      this.iframe.src = this.src;
    }
  }


  ngOnChanges() {

  }

  @HostListener('load') onload(){
    let style = window.document.createElement('style');
    style.innerHTML = `
    img{
      height:50px;
    }
    embed{
      height:50px;
      overflow:hidden
    }
    `;
    if(this.iframe.contentDocument && this.iframe.contentDocument.head){
      // //console.log(this.iframe.contentDocument.body)
      this.iframe.contentDocument.head.appendChild(style);
    }

    if(this.iframe.contentDocument && this.iframe.contentDocument.body){
      this.iframe.contentDocument.head.appendChild(style);
    }
  }

}
