<div >
  <input [formControl]="control"  [matAutocomplete]="auto" placeholder="Shoot..." InputUi (input)="search($event.target.value)" >
  <div class="append">
    <i class="fa fa-spinner fa-spin" *ngIf="shoots?.isLoading"></i>
    <img *ngIf="!shoots?.isLoading" src="../../../../../assets/images/icon_search gray.png">
  </div>

  <mat-autocomplete #auto="matAutocomplete" [displayWith]="display"
    (optionSelected)="selectAccount($event.option.value)">
    <mat-option *ngFor="let a of shoots.items" [value]="a">{{a.name}}</mat-option>
    <mat-option class="add-btn-option p-0" [value]="'create'" *ngIf="createOption" >
      <div class="add-account">
        Create new
        <mat-icon>add</mat-icon>
      </div>
    </mat-option>
  </mat-autocomplete>
</div>