import { Component, forwardRef, OnInit, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Page } from 'src/app/http-handler/common/contracts/page';
import { Audience } from 'src/app/models/audience.model';
import { AppService } from 'src/app/services/app.service';
import { AudienceService } from 'src/app/services/audience.service';

@Component({
  selector: 'select-audiences',
  templateUrl: './audiences.component.html',
  styleUrls: ['./audiences.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SelectAudiencesComponent)
    }
  ],
  host: {
    '(blur)': 'onTouch()'
  }
})
export class SelectAudiencesComponent implements OnInit {

  audiences: Page<Audience> = new Page({
    serverPaging:false,
    api: this._audienceService.audience,
    properties: new Audience(),
  });

  @Input() selected: Audience[] = [];
  @Input('multiple') multiple: boolean = false;

  constructor(
    private _audienceService: AudienceService,
    private _appService: AppService
  ) {
    this.fetch();
  }

  ngOnInit() {
  }

  fetch() {
    this.audiences.fetch().then(d => {

    }).catch(err => {
      this._appService.toastr.error(err);
    })
  }


  onChange: any = () => { }
  onTouch: any = () => { };
  private _val: number | number[] = NaN;
  set value(val) {
    this._val = val
    this.onChange(val)
    this.onTouch(val)
  }

  preSelectedUser() {
    const d = this.audiences;
    if (typeof this.value === 'number' && d.items.map(i => i.id).includes(this.value)) {
      const audience = d.items.find(i => i.id === this.value);
      if (audience) {
        this.selected = [{ ...audience }];
      }
    } else if (this.value) {
      if (this.multiple && this.value instanceof Array) {
        this.value.forEach(d => {
          this.selectById(d);
        })
      }
    }
  }

  onSelectionChange(value: number[] | number) {
    var audience;
    this.selected = [];
    if (this.multiple && value instanceof Array) {
      value.forEach(d => {
        audience = this.audiences.items.find(i => i.id === d);
        if (audience) {
          this.selected.push(audience);
        }
      })
    } else if(value && typeof value === 'number') {
      audience = this.audiences.items.find(i => i.id === value);
      if (audience) {
        this.selected = [audience];
      }
    } else{
      this.selected = [];
    }
  }

  async selectById(id) {
    const audience = await this._audienceService.audience.get(id);
    if (audience) {
      this.selected = this.multiple ? [...this.selected, { ...audience }] : [{ ...audience }];
    }
  }

  get value() {
    return this._val || NaN;
  }

  writeValue(value: any) {
    this.value = value;
  }
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

}
