import { Directive, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output, Renderer2 } from '@angular/core';

@Directive({
    selector: '[selection-list-item]'
})
export class SelectedItemDirective {    
    

    @Input() itemData:Object;
    constructor() { }
    ngOnInit() { }
    @HostListener('click', ['$event']) click(event) {
        if (event.ctrlKey) {
            event.preventDefault();
            event.stopPropagation();
            // unselect accidentally selected text (browser default behaviour)
            document.getSelection().removeAllRanges();
           if(this.itemData['id']){
            this.isSelected = !this.isSelected;  
           }         
        }

        
    }

    @HostBinding('class.selected-item') isSelected: boolean;

}
