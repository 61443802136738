import { Contact } from './contacts.model';
import { State } from './state.model';
import { Country } from './country.model';
import { Account } from './accounts.model';
import { Shoot } from './shoots.model';
export class RealEstate {
    id?: 1;
    name?: string;
    type?: string;
    square?: string;
    street?: string;
    city?: string;
    zipcode?: string;
    address?:string;
    landmark?:string;
    lotSize?: string;
    suiteNumber?: string;
    lastSoldPrice?: any;
    activeListing?: boolean;
    listingPrice?: number;
    lastSoldYear?: number;
    features?: string;
    beds?: number;
    baths?: number;
    rooms?: number;
    floors?: number;
    basement?: boolean;
    dropBoxLink?: string;
    state?: string;
    country?: string;
    status?: string;
    parcelId?: string;
    editAdditional?: boolean;
    btnDisable?: boolean;
    editTagged?: boolean;
    // accounts?: Account[] = [];
    // shoots?: Shoot[] = [];
    realEstateAccounts?: Account[] = [];
    realEstateShoots?: Shoot[] = [];
}

