<div class="main-page p-3">
  <header *ngIf="appService?.user?.adminType !== 'external'" class="main-header mb-3 flex ">
    <a routerLink="../../../">
      <font-25 color="light">External Resources</font-25>
    </a>
    <img class="m-2" src="assets/svg/chevron-left 1.svg" alt="">
    <a routerLink="../">
      <font-25 color="light">Detail</font-25>
    </a>
    <img class="m-2" src="assets/svg/chevron-left 1.svg" alt="">
    <font-25 color="muted">
      Edit Interest </font-25>
  </header>

  <header *ngIf="appService?.user?.adminType === 'external'" class="main-header mb-2 flex ">
    <a routerLink="../../">
      <font-25 color="light">Profile</font-25>
    </a>
    <img class="m-2" src="assets/svg/chevron-left 1.svg" alt="">
    <font-25 color="muted">
      Edit Interest </font-25>
  </header>

  <div class="row content">
    <div class="col-lg-4">
      <div class="capturing bgOrange">
        <div class="skillHead pb-2 font bebas">Capturing</div>
        <hr class="hr_ln">
        <div class="scroll-section">
          <div *ngFor="let item of courseInterests?.items">
            <div *ngIf="!item.sourceId && item.name.toLowerCase() === 'capturing'">
              <ng-container [ngTemplateOutlet]="children1"
                [ngTemplateOutletContext]="{children:item.children,parentId:item.id,checkbox:'white'}"></ng-container>
            </div>
          </div>
          <div class="py-5" *ngIf="courseInterests?.isLoading">
            <loading></loading>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-8">
      <div class="rtcol">
        <div class="col-lg-6 h-100">
          <div class="capturing bgwhite">
            <div class="skillHead text-black pb-2 font bebas">Editing</div>
            <hr class="hr_ln black">
            <div class="scroll-section">
              <div *ngFor="let item of courseInterests?.items">
                <div *ngIf="!item.sourceId && item.name.toLowerCase() === 'editing'">
                  <ng-container [ngTemplateOutlet]="children1"
                    [ngTemplateOutletContext]="{children:item.children,parentId:item.id,colorClass:'black'}">
                  </ng-container>
                </div>
              </div>
              <div class="py-5" *ngIf="courseInterests?.isLoading">
                <loading></loading>
              </div>
            </div>
          </div>
        </div>

        <div class="bgImgSkill">
          <div class="bg-header">
            <font-25 size="92" color="white" class="font bebas">
              EARTH <br> VIRTUAL
            </font-25>
          </div>
          <div class="bg-footer">
            <!-- <div class="bg-blur">
              <div class="upload-text-outer" (click)="course.properties?.testAttachment ? null : file.click()">
                <input type="file" hidden #file (click)="file.value = null" (change)="uploadFile($event.target.files)">
                <div class="flex">
                  <font-13 color="white">Upload an <br> Evaluation Test</font-13>
                </div>
                <div class="flex pr-3" *ngIf="!course.properties?.testAttachment" >
                  <img  src="assets/svg/Icon feather-upload-cloud.svg" alt="">
                </div>
                <div class="flex" *ngIf="course.properties?.testAttachment" >
                  <div class="p-relative" >
                    <div class="p-absolute top-right">
                      <button class="icon-btn circle cancel sm" (click)="$event.stopPropagation(); $event.preventDefault();course.properties.testAttachment = null">
                        <img src="assets/svg/Cancel.svg" alt="">
                      </button>
                    </div>
                    <img class="mt-1" height="50px" src="assets/svg/folder-icon.svg">
                  </div>
                </div>
              </div>
            </div> -->
            <div class="mt-3">
              <button class="theme-btn outline white mr-3" [disabled]="loading" [routerLink]="[appService?.user?.adminType !== 'external' ? '../' : '../../']">Cancel</button>
              <button class="theme-btn orange" (click)="update()"
                [disabled]="loading || !selectInterests?.length">Done</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<ng-template let-children="children" #children1 let-parentId="parentId" let-checkbox="checkbox"
  let-colorClass="colorClass">
  <div>
    <div *ngFor="let item of children;let i = index;">
      <div class="custom-checkbox">
        <input type="checkbox" (change)="onCheck(item)" [(ngModel)]="item.select" id="check1{{parentId}}{{item.id}}">
        <label class="checkbox {{checkbox}}" for="check1{{parentId}}{{item.id}}">
          <mat-icon inline="true">check</mat-icon>
        </label>
        <label for="check1{{parentId}}{{item.id}}" class="text pl-2 mb-0 radio-label {{colorClass}}">{{item.name |
          titlecase}}</label>
      </div>
      <div class="pl-4 expand" [class.show]="item.select" *ngIf="item.children">
        <ng-container [ngTemplateOutlet]="children2"
          [ngTemplateOutletContext]="{children:item.children,parentId:item.id,colorClass:colorClass,checkbox:checkbox}">
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template let-children="children" let-parentId="parentId" let-checkbox="checkbox" let-colorClass="colorClass"
  #children2>
  <div>
    <div *ngFor="let item of children;let i = index;">
      <div class="custom-checkbox">
        <input type="checkbox" (change)="onCheck(item)" [(ngModel)]="item.select" id="check1{{parentId}}{{item.id}}">
        <label class="checkbox {{checkbox}}" for="check1{{parentId}}{{item.id}}">
          <mat-icon inline="true">check</mat-icon>
        </label>
        <label for="check1{{parentId}}{{item.id}}" class="text pl-2 mb-0 radio-label {{colorClass}}">{{item.name |
          titlecase}}</label>
      </div>
      <div class="pl-4 expand" [class.show]="item.select" *ngIf="item.children">
        <ng-container [ngTemplateOutlet]="children1"
          [ngTemplateOutletContext]="{children:item.children,parentId:item.id,colorClass:colorClass,checkbox:checkbox}">
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>