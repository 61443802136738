<nb-card class="mt-4">
  <nb-card-body class="bg-white">
    <div class="row mb-3">
      <div class="col-12">
        <label class="d-inline">SHOOT (s)</label>
        <button mat-button class="float-right" [queryParams]="addQuereParams" [routerLink]="'/pages/shoots/create'"
          [status]="'secondary'" ButtonUi>Add Shoot</button>
      </div>
    </div>
    <div class="row mx-0">
      <div class="card mr-3 mb-3" [routerLink]="'/pages/shoots/detail/'+s.id" [style.backgroundColor]="s.color"
        *ngFor="let s of shoots.items">
        <div class="card-body py-3 pl-3 pr-2">
          <p>{{s?.name || 'N/a'}}</p>
          <p class="mb-3"> {{s.shootCategory?.name || 'N/a'}} </p>
          <p class="text-right"> {{s?.startDate | date}} </p>
          <img src="../../../../../assets/images/camera.png" alt="">
        </div>
      </div>
    </div>
  </nb-card-body>
</nb-card>