import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'check-box',
  templateUrl: './check-box.component.html',
  styleUrls: ['./check-box.component.css']
})
export class CheckBoxComponent implements OnInit, OnChanges {

  @Input() value: boolean;
  @Input() control:FormControl = new FormControl(false) ;
  @Input() class: any;
  @Input() placeholder: string;
  @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(){
    if(this.value || this.value === false){
      this.control.setValue(this.value);
    }
  }

  check(value) {
  }

}
