<div class="my-4 outer" [class.shadow-0]="!shadow">
  <div class="p-3 bg-white">
    <div class="row">
      <div class="col-12">
        <label class="d-inline">TASK (s)</label>
        <button mat-button class="float-right" [status]="'secondary'" ButtonUi (click)="addNewTask()">Add Task</button>
      </div>
      <div class="list" [ngClass]="taskClass ? taskClass : 'col-8' " cdkDropList [cdkDropListData]="tasks.items"
        (cdkDropListDropped)="drop($event)">
        <div class="input-group task" *ngFor="let t of tasks.items;let i = index;" cdkDrag>
          <div class="example-custom-placeholder bg-light" *cdkDragPlaceholder></div>
          <div class="input-group-prepend py-2 pl-2">
            <button class="drag-btn" mat-icon-button cdkDragHandle>
              <mat-icon>drag_indicator</mat-icon>
            </button>
          </div>
          <div class="input-group-prepend">
            <button class="check-box" mat-icon-button>
              <check-box [(value)]="t.isCompleted" (change)="statusChg(t)">
              </check-box>
            </button>
          </div>
          <input class="mr-2 col" (change)="change(t,i)" [(ngModel)]="t.title" [type]="'none'" InputUi
            placeholder="Add Task...">
          <!-- <div class="spinner" *ngIf="t.btnDisable">
            <i class="fa fa-spinner fa-spin"></i>
          </div> -->
          <div class="input-group-append">
            <mat-datepicker #myDatepicker (closed)="change(t,i)"></mat-datepicker>
            <button class="px-2" mat-button (click)="myDatepicker.open()" [disabled]="t.btnDisable">
              <input class="date-input" readonly [(ngModel)]="t.dueDate" [matDatepicker]="myDatepicker">
              <span *ngIf="t?.dueDate">{{t?.dueDate | date:'dd MMM'}}</span>
              <img *ngIf="!t?.dueDate" height="19px" src="../../../../../assets/images/CALENDER.png" alt="">
            </button>
          </div>
          <div class="input-group-append">
            <button mat-icon-button *ngIf="!t.assigned?.id" class="profile-btn" [disabled]="t.btnDisable"
              data-toggle="dropdown">
              <img height="100%" class="rounded-circle p-2"
                [src]="t?.assigned?.imgUrl || '../../../../../assets/images/PROFILE.png'">
            </button>
            
            <button mat-icon-button *ngIf="t.assigned?.id" class="profile-btn pr-2" [style.width]="'auto'" [disabled]="t.btnDisable"
              data-toggle="dropdown">
              <img height="100%" class="rounded-circle p-2"
                [src]="t?.assigned?.imgUrl || '../../../../../assets/images/user (2).png'">
                <!-- <span>{{(t?.assigned?.firstName?.length > 30) ? (t?.assigned?.firstName | slice:0:25)+'..' : (t?.assigned?.firstName) ||'N/a'}} -->
                <span> {{t?.assigned?.firstName}}&nbsp;&nbsp;{{t?.assigned?.lastName}}
                </span>
            </button>

            <div class="dropdown-menu">
              <mat-card class="card">
                <mat-card-content>
                  <div class="col-12 p-0">
                    <label>ASSIGNEE</label>
                  </div>
                  <div class="d-flex">
                    <search-team-member [value]="t.assigned || ''" (onSelect)="assign($event,t,i)"></search-team-member>
                    <div class="px-2 d-flex"><span class="m-auto">or</span></div>
                    <div class="col p-0">
                      <button mat-button ButtonUi status="muted" (click)="assignToMe(t,i)">Assign to Me</button>
                    </div>
                  </div>
                </mat-card-content>

              </mat-card>
            </div>
            <button mat-icon-button class="detail-btn" [disabled]="!t.id" (click)="detail(Tdetail)">
              <img height="19px" src="../../../../../assets/images/detail icon copy.png">
            </button>
            <ng-template #Tdetail>
              <nb-card>
                <nb-card-body class="bg-white p-5">
                  <button class="mb-3 py-2 px-4" ButtonUi [status]=" !t.isCompleted ? 'secondary' : 'primary'"
                    (click)="t.isCompleted = !t.isCompleted ;change(t,i)"
                    mat-button>{{t.isCompleted ? 'Completed' : 'Mark Done'}}</button>
                  <p *ngIf="leadId">LEAD NO.&nbsp;&nbsp;:&nbsp;&nbsp;<a class="a" *ngIf="t?.lead?.id" routerLink="/pages/lead/detail/{{t?.lead?.id}}" >{{t?.lead?.leadNumber}}</a></p>
                  <p *ngIf="dealId">DEAL NO.&nbsp;&nbsp;:&nbsp;&nbsp;<a class="a" *ngIf="t?.deal?.id" routerLink="/pages/deal/detail/{{t?.deal?.id}}" >{{t?.deal?.dealNumber}}</a></p>
                  <p *ngIf="shootId">SHOOT NAME&nbsp;&nbsp;:&nbsp;&nbsp;{{t?.shoot?.name}}</p>
                  <p *ngIf="teamDepartmentId">DEPARTMENT NAME&nbsp;&nbsp;:&nbsp;&nbsp;{{t?.teamDepartment?.name}}</p>
                  <h5 class="font-weight-normal mb-3">{{t?.title}}</h5>
                  <ul>
                    <li>
                      <div>Assignee &nbsp;&nbsp;</div>: &nbsp;&nbsp;
                      <span>
                        <button *ngIf="!t.assigned?.id" mat-icon-button class="img-btn" data-toggle="dropdown">
                          <img height="100%" class="rounded-circle"
                            [src]="t?.assigned?.imgUrl || '../../../../../assets/images/PROFILE.png'">
                        </button>
                        <button *ngIf="t.assigned?.id" mat-icon-button class="img-btn" data-toggle="dropdown">
                          <img height="100%" class="rounded-circle"
                            [src]="t?.assigned?.imgUrl || '../../../../../assets/images/user (2).png'">
                        </button>
                        <button *ngIf="t?.assigned?.id" class="change" data-toggle="dropdown">
                          {{t?.assigned?.firstName}}&nbsp;&nbsp;{{t?.assigned?.lastName}}
                        </button>
                        <div class="dropdown-menu">
                          <mat-card class="card">
                            <mat-card-content>
                              <div class="col-12 p-0">
                                <label>ASSIGNEE</label>
                              </div>
                              <div class="d-flex">
                                <search-team-member [value]="t.assigned || ''" (onSelect)="assign($event,t,i)">
                                </search-team-member>
                                <div class="px-2 d-flex"><span class="m-auto">or</span></div>
                                <div class="col p-0">
                                  <button mat-button ButtonUi status="muted" (click)="assignToMe(t,i)">Assign to
                                    Me</button>
                                </div>
                              </div>
                            </mat-card-content>

                          </mat-card>
                        </div>
                      </span>
                    </li>
                    <li>
                      <div>Date &nbsp;&nbsp;</div>: &nbsp;&nbsp;
                      <span>
                        <button mat-icon-button class="img-btn" (click)="datePiker.open()">
                          <img height="19px" src="../../../../../assets/images/CALENDER.png" alt="">
                        </button>
                        <button *ngIf="t?.dueDate" class="change" (click)="datePiker.open()">
                          {{t?.dueDate | date}}
                        </button>
                        <input readonly [(ngModel)]="t.dueDate" [matDatepicker]="datePiker">
                        <mat-datepicker #datePiker (closed)="change(t,i)"></mat-datepicker>
                      </span>
                    </li>
                    <li>
                      <div>Description &nbsp;&nbsp;</div>: &nbsp;&nbsp;
                      <textarea class="col" InputUi [(ngModel)]="t.description" (change)="change(t,i)"></textarea>
                    </li>
                  </ul>
                </nb-card-body>
              </nb-card>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>