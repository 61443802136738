import { Component, Input, NgModule } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValidatationService } from '../validator.service';
@Component({
  selector: 'control-messages',
  template: `
    <div class="errorMsg" *ngIf="errorMessage != null">{{ errorMessage }}</div>
  `,
  styles: [
    `
      .errorMsg {
        /* bottom: -22px; */
        color: #fe4564;
        font-size: 12px;
        left: 0;
        position: relative;
        text-align: left;
        bottom: -3px;
        line-height: normal;
      }
    `,
  ],
})
export class ControlMessagesComponent {
  @Input() controlName: string;
  @Input() control: FormControl;

  constructor() {
  }

  get errorMessage() {
    // Find the control in the Host (Parent) form
    for (let propertyName in this.control.errors) {
      if (
        this.control.errors.hasOwnProperty(propertyName) &&
        this.control.touched
      ) {
        return ValidatationService.getValidatorErrorMessage(
          propertyName,
          this.control.errors[propertyName]
        );
      }
    }
    return null;
  }
}

@NgModule({
  declarations: [ControlMessagesComponent],
  exports: [ControlMessagesComponent],
})
export class ControlMessagesModule {}
