import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FireBaseMessage } from '../../models/firebase-message.model';
import { environment } from 'src/environments/environment';
import { AppService } from '../app.service';
import { Chat } from 'src/app/models/chat.model';
import { ChatService } from '../chat.service';

@Injectable({
  providedIn: 'root',
})
export class SupportFirebaseService {
  private collectionUrl = environment.firebaseCollectionUrl;

  constructor(
    public _db: AngularFireDatabase,
    private _appService: AppService,
    private chatService: ChatService
  ) {}

  getMessages(chatId): Observable<FireBaseMessage[]> {
    return this._db
      .list<FireBaseMessage>(`${this.collectionUrl}/${chatId}`)
      .valueChanges()
      .pipe(
        map((d) => {
          return d.map((d) => {
            this.chatService.getUnreadCount();
            return d;
          });
        })
      );
  }

  getChats() {
    return this._db
      .list<FireBaseMessage>(`${this.collectionUrl}`)
      .valueChanges();
  }

  async sendMessage(chat: Chat, msg: string) {
    const newMsg = new FireBaseMessage();
    newMsg.chatId = `${chat.id}`;
    newMsg.message = msg;
    newMsg.sentById = this._appService?.user?.id;
    // newMsg.adminId = chat.adminId;
    // newMsg.jobApplicantId = chat?.jobApplicantId;
    newMsg.timeStamp = new Date().getTime();
    try {
      const data = await this._db
        .list<FireBaseMessage>(`${this.collectionUrl}/${chat.id}`)
        .push(newMsg as any);
      return data;
    } catch (err) {
      console.log(err);
    }
  }

  deleteChat(id: number) {
    return this._db
      .list<FireBaseMessage>(`${this.collectionUrl}/${id}`)
      .remove();
  }
}
