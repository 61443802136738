import { Injectable } from '@angular/core';
import { IApi } from '../http-handler/common/contracts/api';
import { HttpClient } from '@angular/common/http';
import { GenericApi } from '../http-handler/common/generic-api';
import { Account, Industry } from '../models/accounts.model';
import { BillingAddress } from '../models/billing-address.model';
import { MailingAddress } from '../models/mailing-address.model';

@Injectable({
  providedIn: 'root'
})

export class AccountsService {

  accounts: IApi<Account>;
  industries: IApi<Industry>;
  billingaddress: IApi<BillingAddress>;
  maillingAddress: IApi<MailingAddress>;
  constructor(private http: HttpClient) {

    this.accounts = new GenericApi<Account>('accounts', http);
    this.industries = new GenericApi<any>('industries', http);
    this.billingaddress = new GenericApi<any>('billingaddress', http);
    this.maillingAddress = new GenericApi<any>('mailingAddress', http);

  }


  addressString(a) {
    if (!a) {
      return;
    }
    let address;
    let { street, country, state, zipcode, city } = a;
    if (street) {
      address = street;
    }
    if (city) {
      address += ', ' + city;
    }

    if (state) {
      address += ', ' + state;
    }

    if (country) {
      address += ', ' + country;
    }

    if (zipcode) {
      address += ', ' + zipcode;
    }

    return address;

  }

}
