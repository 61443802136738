import { Injectable } from '@angular/core';
import { IApi } from '../http-handler/common/contracts/api';
import { HttpClient } from '@angular/common/http';
import { GenericApi } from '../http-handler/common/generic-api';
import { State } from '../models/state.model';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  states: IApi<State>;

  constructor(http: HttpClient) {
    this.states = new GenericApi<State>('states', http);
  }
}
