import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FileUploader, FileItem, ParsedResponseHeaders } from 'ng2-file-upload';
import { environment } from "src/environments/environment";
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-multipalFileUploader',
  templateUrl: './multipalFileUploader.component.html',
  styleUrls: ['./multipalFileUploader.component.css']
})

export class MultipalFileUploaderComponent implements OnInit {

  @Input() uploadUrl: string;
  @Input() template: any;
  @Input() single: boolean = false;
  @Input() fileType: string;
  @Input() attached:any = {};

  @Output() upload: EventEmitter<any> = new EventEmitter();
  @Output() complete: EventEmitter<any> = new EventEmitter();

  uploader: FileUploader;
  hasBaseDropZoneOver: boolean;
  hasAnotherDropZoneOver: boolean;
  response: string;

  constructor(
    public commenService: AppService
  ) {
  }
  ngOnInit() {
    this.uploader = new FileUploader({
      url: this.uploadUrl ? this.uploadUrl : environment.apiUrls.api + '/api/images/upload',
      itemAlias: 'media',
      headers: [{ name: 'x-access-token', value: localStorage.getItem('token') }],
    });

    this.hasBaseDropZoneOver = false;
    this.hasAnotherDropZoneOver = false;

    this.response = '';


    if(this.attached){
      let key;

      for(let k in this.attached){
        key = k;
      }
      if(key){

        this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
          // //console.log(form);
          form.append(key , this.attached[key]);
         };
      }

    }

    this.uploader.response.subscribe(res => this.response = res);
    this.uploader.onSuccessItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {

      let res = JSON.parse(response);
      if (res.isSuccess) {
        this.upload.emit({ ...res.data, type: item._file.type.includes('image') ? 'photo' : "video" });
      }

    }

    this.uploader.onCompleteAll = () => {
      this.uploader.clearQueue();
      this.complete.emit(null);
    }

    this.uploader.onAfterAddingFile = (item) => {
      item.withCredentials = false;
    }

  }

  chooseFile(event) {
    let files: File[] = [...event.target.files];
    if (!files.length) return;
    files.forEach(file => {
      if (this.fileType && this.fileType !== 'all' && !file.type.startsWith('image')) {
        if (this.single) {
          return this.commenService.toastr.info(`Only ${this.fileType} files is supported. please select only ${this.fileType}`);;
        } else {
          return;
        }
      }
      if (!this.fileType && !file.type.startsWith('image') && !file.type.startsWith('video')) {
        return;
        //  this.commenService.toastr.info('Only image or video files is supported. please select only image or video',null,{});
      }
      this.addToQueue(file);
    });
  }

  addToQueue(file: File) {
    if (file) {
      this.uploader.addToQueue([file]);
    }
  }



}
