import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'CrLoc'
})
export class CrLocPipe implements PipeTransform {
  transform(locc: any, args?: any): any {
    if (args == 'Crlocc') return this.CreationLocation(locc);
  }
  CreationLocation(locc: string) {
    if (locc === 'studio') {
      return "Studio";
    }
    if (locc === 'clientOffice') {
        return "Client Office";
      }
      if (locc === 'constructionSite') {
        return "Construction Site";
      }
  }
}