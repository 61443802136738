import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Page } from 'src/app/http-handler/common/contracts/page';
import { ShootCatService } from 'src/app/services/shoot-categories.service';
import { ShootCategory } from "../../../../models/shoot-category.model";
@Component({
  selector: 'shoot-categories',
  templateUrl: './shoot-categories.component.html',
  styleUrls: ['./shoot-categories.component.css']
})
export class ShootCategoriesComponent implements OnInit {
  shootCat: Page<ShootCategory>;

  @Input() id: FormControl = new FormControl('');
  @Input() Model: FormControl = new FormControl('');
  @Output() changed: EventEmitter<number> = new EventEmitter();


  constructor(
    private shootCatService: ShootCatService
  ) {
    this.shootCat = new Page({
      api: shootCatService.shootCat,
      properties: new ShootCategory(),

    });
    this.fetch();
  }

  ngOnInit() {
  }

  fetch() {
    this.shootCat.fetch().then(d => {
    }).catch(err => {
      //console.log(err);
    })
  }
  selectCat(id: any) {
    this.Model.setValue(this.shootCat.items.find(i => i.id === parseInt(id)));
    this.changed.emit(id);
  }

}
