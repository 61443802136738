<form [formGroup]="form" autocomplete="off" >
  <header >
    <h6 >
      Change password
    </h6>
    <button mat-icon-button class="close" mat-dialog-close >
      <mat-icon>clear</mat-icon>
    </button>
  </header>
  <mat-progress-bar [mode]="'indeterminate'" *ngIf="isLoading" ></mat-progress-bar>
  <section>
    <div class="form-group">
      <input type="password" autocomplete="new-password" InputUi placeholder="Old Password" formControlName="oldPassword" >
    </div>
    <div class="form-group">
      <input type="password" autocomplete="new-password" InputUi placeholder="New Password" formControlName="newPassword" >
    </div>
    <div class="form-group mb-0">
      <input type="password" autocomplete="new-password" InputUi placeholder="Confirm Password" formControlName="confirmPassword" >
    </div>
  </section>
  <footer>
    <button type="submit" ButtonUi matRipple [disabled]="isLoading || form.invalid" (click)="submit()" >
      Submit
    </button>
  </footer>
</form>