import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  @Output() toggle: EventEmitter<boolean> = new EventEmitter<boolean>();
  // toggleImg = '../../../assets/images/Close_menu.png';
  constructor(
    private router: Router,
    public commonService: AppService,
    public appService: AppService
  ) {}
  ngOnInit() {}

  checkIcon(heading) {
    if (!heading) return '';
    switch (heading) {
      case 'Home':
        return '';
      case 'Real Estate':
        return 'assets/images/Real Estate.png';
      case 'Account':
        return 'assets/images/Account.png';
      case 'Contact':
        return 'assets/images/Contact.png';
      case 'Lead':
        return 'assets/images/Lead.png';
      case 'Deal':
        return 'assets/images/Deal.png';
      case 'Shoots':
        return 'assets/images/Shoot.png';
      case 'Task':
        return 'assets/images/Task.png';
      case 'Team':
        return 'assets/images/Team.png';
      case 'Media':
        return 'assets/images/Media.png';
    }
  }
}
