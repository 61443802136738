import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgCircleProgressModule } from 'ng-circle-progress';
import {
  NbLayoutModule,
  NbButtonModule,
  NbIconModule,
  NbInputModule,
  NbCardModule,
  NbSelectModule,
  NbRadioModule,
  NbCheckboxModule,
  NbToggleModule,
  NbContextMenuModule,
  NbUserModule,
  NbAlertModule,
  NbProgressBarModule,
  NbCalendarModule,
  NbChatModule,
  NbCalendarRangeModule,
  NbListModule,
  NbSidebarModule,
  NbDatepickerModule,
} from '@nebular/theme';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatChipsModule } from '@angular/material/chips';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatRippleModule } from '@angular/material/core';
import { MatStepperModule } from '@angular/material/stepper';
import { PagingModule } from './components/paginator/paginator.component';
import { CropperComponent } from './components/cropper/cropper.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ChartsModule } from 'ng2-charts';
import { AddsidelistComponent } from './components/addsidelist/addsidelist.component';
import { SelectStateComponent } from './components/select-state/select-state.component';
import { FileUploadModule } from 'ng2-file-upload';
import { ToggleComponent } from './components/toggle/toggle.component';
import { SelectCountryComponent } from './components/select-country/select-country.component';
import { InputUiModule } from './directives/input-ui.directive';
import { ButtonUiDirective } from './directives/button-ui.directive';
import { CheckBoxComponent } from './components/check-box/check-box.component';
import { MultipalFileUploaderComponent } from './components/multipalFileUploader/multipalFileUploader.component';
import { HyphenDirective } from './directives/hyphen-phone/hyphen.directive';
import { NumberOnlyDirective } from './directives/only-number/number-only.directive';
import { OnlyFloatDirective } from './directives/float-number/only-float.directive';
import { SelectAccountComponent } from './components/select-account/select-account.component';
import { SelectContactComponent } from './components/select-contact/select-contact.component';
import { SelectTeamMemberComponent } from './components/select-team-member/select-team-member.component';
import { SearchAddressComponent } from './components/search-address/search-address.component';
import { AgmCoreModule } from '@agm/core';
import { SelectLeadComponent } from './components/select-lead/select-lead.component';
import { TasksComponent } from './components/tasks/tasks.component';
import { SearchDealComponent } from './components/search-deal/search-deal.component';
import { ShootListComponent } from './components/shoot-list/shoot-list.component';
import { RouterModule } from '@angular/router';
import { UploadExcelComponent } from './components/upload-excel/upload-excel.component';
import { SelectDepartmentComponent } from './components/select-department/select-department.component';
import { DealPaymentsComponent } from './components/deal-payments/deal-payments.component';
import { SearchShootComponent } from './components/search-shoot/search-shoot.component';
import { LocationDirective } from './directives/location/location.directive';
import { SafePipe } from './pipes/safe/safe.pipe';
import { HyphenPipe } from './pipes/hyphen/hyphen.pipe';
import { IframeDirective } from './directives/iframe/iframe.directive';
import { DatePipe } from './pipes/date-pipe/date.pipe';
import { ShootSubcategoriesComponent } from './components/shoot-subcategories/shoot-subcategories.component';
// import { CreateShootComponent } from '../shoots/components/create-shoot/create-shoot.component';
import { ShootCategoriesComponent } from './components/shoot-categories/shoot-categories.component';
import { SpacePipe } from './pipes/spacing/spacing.pipe';
import { BckDataPipe } from './pipes/spacing/bckData.pipe';
import { SelectDatasourceComponent } from './components/select-datasource/select-datasource.component';
import { dataSourcePipe } from './pipes/spacing/datasource.pipe';
import { LeadMedPipe } from './pipes/spacing/leadMedium.pipe';
import { PurShootPipe } from './pipes/spacing/purposeOfShoot.pipe';
import { ImageStylePipe } from './pipes/spacing/mediaImage.pipe';
import { SubLocPipe } from './pipes/spacing/subLocMedia.pipe';
import { CrLocPipe } from './pipes/spacing/CrLocation.pipe';
import { MediaCameraPipe } from './pipes/spacing/media-camera.pipe';
import { InviteComponent } from './components/invite/invite.component';
import { SearchIndustryComponent } from './components/search-industry/search-industry.component';
import { AutoFileUploaderComponent } from './components/auto-file-uploader/auto-file-uploader.component';
import { PriorityComponent } from './components/priority/priority.component';
import { NumberInputModule } from './directives/number/number.directive';
import { IsLoadingComponent } from './components/is-loading/is-loading.component';
import { TooltipModule, TooltipOptions } from 'ng2-tooltip-directive';
import { ChangePasswordDialogComponent } from './dialogs/change-password/change-password.component';
import { ReadMoreModule } from './directives/read-more-less/read-more-less.directive';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { PlaceholderLoadingModule } from './directives/placeholder-loading/placeholder-loading.directive';
import { LogoModule } from './components/logo/logo.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { PriorityPipe } from './pipes/priority/priority.pipe';
import { SocialMediaTooltipComponent } from './components/social-media-tooltip/social-media-tooltip.component';
import { DoNotFoundModule } from './components/data-not-found/data-not-found';
import { CountryCodePickerInputComponent } from './components/country-code-picker-input/country-code-picker-input.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { TaskSelectionActionsComponent } from './components/task-selection-actions/task-selection-actions.component';
import { SelectedItemDirective } from './directives/selection/selected-item.directive';
import { SelectedItemList } from './directives/selection/selected-list.directive';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { TaskInchargeComponent } from './components/task-incharge/task-incharge.component';
import { PriorityBarComponent } from './components/priority-bar/priority-bar.component';
import { SelectAudiencesComponent } from './components/audiences/audiences.component';
import { RouterDrawerContainerComponent } from './components/router-drawer-container/router-drawer-container';
import { ControlMessagesModule } from 'src/app/shared/controls-msg/control-messages.omponent';
import { EditPersonalInformationComponent } from './components/edit-personal-information/edit-personal-information.component';
import { AddEditEquipmentComponent } from './components/add-edit-equipment/add-edit-equipment.component';
import { EditInterestComponent } from './components/edit-Interest/edit-Interest.component';
import { PlayVideoDialogComponent } from './components/play-video/play-video.component';
import { ChatBotComponent } from './components/chat-bot/chat-bot.component';
import { ChatMessagesComponent } from './components/chat-bot/chat-messages/chat-messages.component';
import { StartNewChatComponent } from './components/chat-bot/start-new-chat/start-new-chat.component';
import { PreventdefaultDirective } from './directives/preventdefault/preventdefault.directive';
import { IvyGalleryModule } from 'angular-gallery';
import { TimeagoModule } from 'ngx-timeago';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { LinkifyPipe } from './pipes/linkify/linkify.pipe';
import { FontsModule } from './directives/fonts/fonts';
import { MatBadgeModule } from '@angular/material/badge';

const MyDefaultTooltipOptions: TooltipOptions = {
  theme: 'light',
  shadow: true,
  'show-delay': 200,
  autoPlacement: true,
  'tooltip-class': 'my-tooltip',
};

const nbModules = [
  NbLayoutModule,
  NbButtonModule,
  NbIconModule,
  NbEvaIconsModule,
  NbInputModule,
  NbCardModule,
  NbSelectModule,
  NbRadioModule,
  NbCheckboxModule,
  NbToggleModule,
  NbContextMenuModule,
  NbUserModule,
  NbAlertModule,
  NbProgressBarModule,
  NbCalendarModule,
  NbChatModule,
  NbCalendarRangeModule,
  NbListModule,
  NbSidebarModule,
  MatButtonToggleModule,
];

const MaterialModule = [
  MatSidenavModule,
  MatCardModule,
  MatTableModule,
  MatCheckboxModule,
  MatExpansionModule,
  MatListModule,
  MatButtonModule,
  MatIconModule,
  MatInputModule,
  MatSelectModule,
  MatFormFieldModule,
  MatToolbarModule,
  MatMenuModule,
  MatTooltipModule,
  MatDialogModule,
  MatNativeDateModule,
  MatDatepickerModule,
  MatChipsModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatSliderModule,
  MatSnackBarModule,
  MatAutocompleteModule,
  MatTabsModule,
  MatRadioModule,
  DragDropModule,
  MatRippleModule,
  MatBottomSheetModule,
  MatStepperModule,
  IvyGalleryModule,
  TimeagoModule.forRoot(),
  PickerModule,
  LogoModule,
  PagingModule,
  MatBadgeModule,
];

@NgModule({
  imports: [
    AgmCoreModule.forRoot({
      // AIzaSyAVqxJGOu6uyt145Z11llIeMqCxuz48sAA
      apiKey: 'AIzaSyCPaWZ82O2KbXCsZwnZXq0Qe_kKu-SL_WA',
      libraries: ['places', 'drawing', 'geometry'],
    }),
    CommonModule,
    ChartsModule,
    nbModules,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    FormsModule,
    NbDatepickerModule,
    FontAwesomeModule,
    NgCircleProgressModule.forRoot({
      radius: 90,
      space: -5,
      outerStrokeWidth: 5,
      outerStrokeColor: '#3db45d',
      innerStrokeColor: '#ededed',
      innerStrokeWidth: 5,
      title: ['Title'],
      animateTitle: false,
      animationDuration: 400,
      showUnits: false,
      clockwise: false,
      outerStrokeLinecap: 'square',
      titleFontSize: '22',
      titleFontWeight: '700',
      titleColor: '#2d2d2d',
      subtitleFontSize: '20',
      subtitleFontWeight: '500',
      subtitleColor: '#2d2d2d',
    }),
    TooltipModule.forRoot(MyDefaultTooltipOptions),
    MaterialModule,
    ImageCropperModule,
    FileUploadModule,
    RouterModule,
    SlickCarouselModule,
    FontsModule,
    DoNotFoundModule,
    PlaceholderLoadingModule,
    InputUiModule,
    ControlMessagesModule,
    NumberInputModule,
    ReadMoreModule,
  ],
  exports: [
    CommonModule,
    ChartsModule,
    DoNotFoundModule,
    InputUiModule,
    nbModules,
    FormsModule,
    ReactiveFormsModule,
    NgCircleProgressModule,
    NbDatepickerModule,
    FontAwesomeModule,
    MaterialModule,
    CropperComponent,
    AddsidelistComponent,
    SelectStateComponent,
    SelectCountryComponent,
    ToggleComponent,
    FileUploadModule,
    ButtonUiDirective,
    CheckBoxComponent,
    MultipalFileUploaderComponent,
    AgmCoreModule,
    NumberOnlyDirective,
    HyphenDirective,
    OnlyFloatDirective,
    SelectAccountComponent,
    SelectContactComponent,
    SelectTeamMemberComponent,
    SearchAddressComponent,
    SelectLeadComponent,
    TasksComponent,
    SearchDealComponent,
    ShootListComponent,
    UploadExcelComponent,
    ShootCategoriesComponent,
    ShootSubcategoriesComponent,
    DealPaymentsComponent,
    SelectDepartmentComponent,
    SearchShootComponent,
    HyphenPipe,
    LocationDirective,
    SafePipe,
    DatePipe,
    SpacePipe,
    ImageStylePipe,
    dataSourcePipe,
    PurShootPipe,
    CrLocPipe,
    SubLocPipe,
    LeadMedPipe,
    BckDataPipe,
    IframeDirective,
    MediaCameraPipe,
    InviteComponent,
    SearchIndustryComponent,
    AutoFileUploaderComponent,
    PriorityComponent,
    NumberInputModule,
    IsLoadingComponent,
    TooltipModule,
    ChangePasswordDialogComponent,
    ReadMoreModule,
    PlaceholderLoadingModule,
    UserProfileComponent,
    PriorityPipe,
    SocialMediaTooltipComponent,
    CountryCodePickerInputComponent,
    VideoPlayerComponent,
    SlickCarouselModule,
    SelectedItemDirective,
    SelectedItemList,
    TaskSelectionActionsComponent,
    TaskInchargeComponent,
    PriorityBarComponent,
    SelectAudiencesComponent,
    RouterDrawerContainerComponent,
    EditPersonalInformationComponent,
    AddEditEquipmentComponent,
    EditInterestComponent,
    PreventdefaultDirective,
    PlayVideoDialogComponent,
    ChatBotComponent,
    LinkifyPipe,
    StartNewChatComponent,
    FontsModule,
    ControlMessagesModule,
  ],
  entryComponents: [
    SearchAddressComponent,
    ChangePasswordDialogComponent,
    EditPersonalInformationComponent,
    AddEditEquipmentComponent,
    PlayVideoDialogComponent,
  ],
  declarations: [
    SocialMediaTooltipComponent,
    IsLoadingComponent,
    AddsidelistComponent,
    CropperComponent,
    SelectStateComponent,
    ToggleComponent,
    SelectCountryComponent,
    ButtonUiDirective,
    ShootSubcategoriesComponent,
    CheckBoxComponent,
    MultipalFileUploaderComponent,
    NumberOnlyDirective,
    HyphenPipe,
    HyphenDirective,
    OnlyFloatDirective,
    SelectAccountComponent,
    SelectContactComponent,
    SelectTeamMemberComponent,
    SearchAddressComponent,
    SelectLeadComponent,
    TasksComponent,
    SearchDealComponent,
    ShootListComponent,
    ShootCategoriesComponent,
    UploadExcelComponent,
    SelectDepartmentComponent,
    DealPaymentsComponent,
    SearchShootComponent,
    LocationDirective,
    SafePipe,
    DatePipe,
    SpacePipe,
    PurShootPipe,
    LeadMedPipe,
    dataSourcePipe,
    BckDataPipe,
    ImageStylePipe,
    SubLocPipe,
    CrLocPipe,
    IframeDirective,
    ShootSubcategoriesComponent,
    ShootCategoriesComponent,
    SelectDatasourceComponent,
    MediaCameraPipe,
    InviteComponent,
    SearchIndustryComponent,
    AutoFileUploaderComponent,
    PriorityComponent,
    ChangePasswordDialogComponent,
    UserProfileComponent,
    PriorityPipe,
    CountryCodePickerInputComponent,
    VideoPlayerComponent,
    SelectedItemDirective,
    SelectedItemList,
    TaskSelectionActionsComponent,
    TaskInchargeComponent,
    PriorityBarComponent,
    SelectAudiencesComponent,
    RouterDrawerContainerComponent,
    EditPersonalInformationComponent,
    AddEditEquipmentComponent,
    EditInterestComponent,
    PreventdefaultDirective,
    PlayVideoDialogComponent,
    ChatBotComponent,
    ChatMessagesComponent,
    LinkifyPipe,
    StartNewChatComponent,
  ],
})
export class ShareModule {}
