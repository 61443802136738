import { Component, OnInit, Input } from '@angular/core';
import { trigger,query,transition,animate,keyframes,stagger,style } from '@angular/animations';
import { Observable} from 'rxjs/Rx';
import { Message } from './message.model';


@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css'],
  animations:[
    trigger('msgAnimation',[
      transition('* => *', [

        query(':enter', style({ opacity: 0 }), { optional:true }),

        query(':enter', stagger('300ms' , [
          animate('1s ease-in',keyframes([
            style({opacity: .4, transform:'scale(1,1)',offset: .4}),
          ]))
        ]), { optional:true }),

        query(':leave', stagger('300ms' , [
          animate('1s ease-in',keyframes([
            style({opacity: .0, transform:'scale(0.7,0.7)',offset: .3}),
          ]))
        ]), { optional:true })

      ])
    ])

  ]
})
export class MessageComponent implements OnInit {

  @Input() messages:Array<Message>;
  private timer;

  constructor() { }

  ngOnInit() {
    this.timer = Observable.interval(10000);
    this.timer.subscribe((t) => {
      if(this.messages.length != 0){
        this.messages.pop();
      }
    });
  }

  removeAll(){
    for(let i = 0; 1 <= this.messages.length; i++){
      this.messages.splice(i)
    }
  }


}

