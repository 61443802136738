<div>
  <input [formControl]="control" [matAutocomplete]="auto" placeholder="Search deal..." InputUi
    (input)="search($event.target.value)" type="text" >
  <div class="append">
    <i class="fa fa-spinner fa-spin" *ngIf="deals?.isLoading"></i>
    <img *ngIf="!deals?.isLoading" src="../../../../../assets/images/icon_search gray.png">
  </div>

  <mat-autocomplete #auto="matAutocomplete" [displayWith]="display" (optionSelected)="onSelect($event.option.value)">
    <mat-option *ngFor="let a of deals.items" [value]="a">{{a?.dealNumber || ''}} {{a?.name || ''}} </mat-option>
    <mat-option class="add-btn-option p-0" [value]="'create'" *ngIf="createOption" >
      <div class="add-account">
        Create new
        <mat-icon>add</mat-icon>
      </div>
    </mat-option>
  </mat-autocomplete>
</div>