import { Directive, Output, EventEmitter, ElementRef, HostListener, OnInit, OnChanges, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: 'input[hyphen]'
})
export class HyphenDirective implements OnInit, OnChanges {

    previousValue: any = '';
    @Input() value: string;

    constructor(
        private el: ElementRef<HTMLInputElement>,
        private control: NgControl
    ) {



    }

    ngOnInit() {

    }

    ngOnChanges() {
        if (this.value) {
            this.hyphen(this.el.nativeElement)
        }
    }

    @HostListener('change', ['$event']) onChange(e) {
        // //console.log(e.target.value);
    }

    @HostListener('input', ['$event']) onInput(e) {
        const input = e.target;
        this.hyphen(input)
    }

    @HostListener('keypress', ['$event']) onKeyPress(e) {
        if ((e.keyCode < 48 || e.keyCode > 57) && e.keyCode !== 45) {
            e.target.value = this.previousValue;
            this.control.control.setValue(this.previousValue);
        };
    }

    hyphen(input) {
        if (!parseInt(input.value)) {
            this.previousValue = null;
            return input.value = null;
        };
        let val = input && input.value ? input.value.match(/\d+/g).join("").replace(/_/, '') : '';
        let numbers = [];
        for (let i = 0; i < val.length; i += 3) {
            numbers.push(val.substr(i, i == 6 ? 4 : 3));
            if (i == 6) { break }
        };
        input.value = numbers.join('-');
        this.control.control.setValue(input.value);
        this.previousValue = input.value;
    }

}
