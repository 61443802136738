import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Page } from 'src/app/http-handler/common/contracts/page';
import { User } from 'src/app/models/user.model';
import { AppService } from 'src/app/services/app.service';
import { ChatService } from 'src/app/services/chat.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-start-new-chat',
  templateUrl: './start-new-chat.component.html',
  styleUrls: ['./start-new-chat.component.scss']
})
export class StartNewChatComponent implements OnInit {

  admins: Page<User>;
  constructor(
    private _appService: AppService,
    private _adminService: UserService,
    public _matDialogRef: MatDialogRef<StartNewChatComponent>,
    private chatService: ChatService
  ) {
    this.admins = new Page({
      api: _adminService.users,
      filters: [{ field: 'displayName', value: '' }],
      serverPaging: false
    });

    this.fetch();
  }

  fetch() {
    for (let i = 0; i < 5; i++) {
      this.admins?.items.push(new User())
    }
    this.admins.fetch().then().catch(err => {
      this._appService.toastr.error(err)
    })
  }

  ngOnInit() {
  }

isLoading:boolean;
  createChat(id) {
    if(this.isLoading) return;
    let body:any = {};
    body.user2Id = id;
    this.isLoading = true;
    this.admins.isLoading = true;
    this.chatService.chats.create(body).then(d => {
    this.isLoading = false;
    this._matDialogRef.close(d)
    this.admins.isLoading = false;
    }).catch(err => {
    this.isLoading = false;
    this.admins.isLoading = false;
    this._appService.toastr.error(err);
    })
  }

}
