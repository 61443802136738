export class Audience {
    id?: number;
    name?: string;
    selected?:boolean;
    subServices?: SubServices[]= []
}

export class SubServices{

}
