import { Injectable } from '@angular/core';
import { IApi } from '../http-handler/common/contracts/api';
import { HttpClient } from '@angular/common/http';
import { GenericApi } from '../http-handler/common/generic-api';
import { Country } from '../models/country.model';

@Injectable({
  providedIn: 'root'
})

export class CountryService {

  countries:IApi<Country>;

  constructor(http:HttpClient){
      
      this.countries = new GenericApi<Country>('countries',http);
  }
}
