import {
  Component,
  OnInit,
  Input,
  ElementRef,
  Renderer2,
  HostBinding,
} from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-main-side-nav',
  templateUrl: './main-side-nav.component.html',
  styleUrls: ['./main-side-nav.component.scss'],
})
export class MainSideNavComponent implements OnInit {
  @Input() drawer: MatDrawer;

  sideNav = [
    {
      title: 'CRM',
      link: '/pages/dashboard',
      navIcon: 'assets/main-sidenav-icons/CRM.svg',
      navIconActive: 'assets/main-sidenav-icons/CRM-1.svg',
      disabled: false,
    },
    {
      title: 'Job Board',
      link: '/pages/job',
      navIcon: 'assets/main-sidenav-icons/Job Board.svg',
      navIconActive: 'assets/main-sidenav-icons/Job Board-1.svg',
      disabled: false,
    },
    {
      title: 'Client Request',
      link: '/pages/clientRequest',
      navIcon: 'assets/main-sidenav-icons/Client Request.svg',
      navIconActive: 'assets/main-sidenav-icons/Client Request-1.svg',
      disabled: false,
    },
    // {
    //   title: 'Calendar',
    //   link: '',
    //   navIcon: 'assets/main-sidenav-icons/Calender.svg',
    //   navIconActive: 'assets/main-sidenav-icons/Calender-1.svg',
    //   disabled: true,
    // },
    {
      title: 'Projects',
      link: '',
      navIcon: 'assets/main-sidenav-icons/Projects.svg',
      navIconActive: 'assets/main-sidenav-icons/Projects-1.svg',
      disabled: true,
    },
    {
      title: 'Tasks',
      link: '/pages/tasks',
      navIcon: 'assets/main-sidenav-icons/Tasks.svg',
      navIconActive: 'assets/main-sidenav-icons/Tasks-1.svg',
      disabled: false,
    },
    // {
    //   title: 'Stock',
    //   link: '',
    //   navIcon: 'assets/main-sidenav-icons/Stock.svg',
    //   navIconActive: 'assets/main-sidenav-icons/Stock-1.svg',
    //   disabled: true,
    // },
    {
      title: 'Chat',
      // link: '/admin-console/chat',
      link: '/pages/chat',
      navIcon: 'assets/main-sidenav-icons/ic_chat.svg',
      navIconActive: 'assets/main-sidenav-icons/ic_chat-1.svg',
      disabled: false,
    },
    {
      title: 'Team',
      link: '/pages/admins',
      navIcon: 'assets/main-sidenav-icons/Team.svg',
      navIconActive: 'assets/main-sidenav-icons/Team-1.svg',
      disabled: false,
    },
    {
      title: 'Email List',
      link: '/pages/email-list',
      navIcon: 'assets/main-sidenav-icons/email.svg',
      navIconActive: 'assets/main-sidenav-icons/email-1.svg',
      disabled: false,
    },
    {
      title: 'Forms',
      link: '/pages/quote-forms',
      navIcon: 'assets/images/setting-sidebar/forms.svg',
      navIconActive: 'assets/images/setting-sidebar/forms-active.svg',
      disabled: false,
    },
    {
      title: 'Reports',
      link: '/pages/reports',
      navIcon: 'assets/main-sidenav-icons/Reports.svg',
      navIconActive: 'assets/main-sidenav-icons/Reports-1.svg',
      disabled: true,
    },
    {
      title: 'Real Estate',
      link: '/pages/real-estate',
      navIcon: 'assets/main-sidenav-icons/Real Estate.svg',
      navIconActive: 'assets/main-sidenav-icons/Real Estate-1.svg',
      disabled: false,
    },
    {
      title: 'Training',
      link: '/pages/training',
      navIcon: 'assets/main-sidenav-icons/Training.svg',
      navIconActive: 'assets/main-sidenav-icons/Training-1.svg',
      disabled: false,
    },
    {
      title: 'Profile',
      link: '/pages/my-account',
      navIcon: 'assets/main-sidenav-icons/Profile.svg',
      navIconActive: 'assets/main-sidenav-icons/Profile-1.svg',
      disabled: false,
    },
    {
      title: 'Settings',
      link: '/settings',
      navIcon: 'assets/main-sidenav-icons/settings.svg',
      navIconActive: 'assets/main-sidenav-icons/settings-1.svg',
      disabled: false,
    },
    {
      title: 'Software Edit',
      link: '/pages/software-edit',
      navIcon: 'assets/main-sidenav-icons/Software-edit.svg',
      navIconActive: 'assets/main-sidenav-icons/Software-edit-1.svg',
      disabled: false,
    },
  ];

  type: any;

  constructor(
    private _el: ElementRef<HTMLElement>,
    private _renderer2: Renderer2,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    _renderer2.addClass(_el.nativeElement, 'sidebar');
  }

  ngOnInit() {}

  dashBoardRoute() {
    this.router.navigate([`pages/dashboard/business`]);
  }

  close() {
    localStorage.setItem('smallSideNav', 'true');
  }

  toggleMainSideNav() {
    if (this.smallSideNav == 'true') {
      localStorage.setItem('smallSideNav', 'false');
    } else {
      localStorage.setItem('smallSideNav', 'true');
    }
  }

  get smallSideNav() {
    return localStorage.getItem('smallSideNav');
  }

  @HostBinding('class.smallnav') get isSmallNav() {
    return this.smallSideNav === 'true';
  }
}
