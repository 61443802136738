import { Component, OnInit, Output, EventEmitter, Input, OnChanges, Self } from '@angular/core';
import { Page } from 'src/app/http-handler/common/contracts/page';
import { Admin } from 'src/app/models/lead.model';
import { UserService } from 'src/app/services/user.service';
import { FormControl } from '@angular/forms';
import { Filter } from 'src/app/http-handler/common/contracts/filters';

@Component({
  selector: 'search-team-member',
  templateUrl: './select-team-member.component.html',
  styleUrls: ['./select-team-member.component.css'],
  providers: [UserService]
})
export class SelectTeamMemberComponent implements OnInit, OnChanges {

  teamMember: Page<Admin>;

  @Input() value: Admin;

  @Input() control: FormControl = new FormControl('');
  @Input() controlId: FormControl = new FormControl('');
  @Input() adminId: FormControl = new FormControl('');
  @Input('roleType') roleType:string;
  @Input() placeholder: string;

  @Output('onSelect') select: EventEmitter<Admin> = new EventEmitter();
  
  constructor(
    @Self() private _adminService: UserService
  ) {

    this.teamMember = new Page({
      api: _adminService.users,
      properties: new Admin(),
      pageSize: 20,
      filters: [{
        field: 'displayName',
        value: null
      }, {
        field: 'status',
        value: 'active'
      }, {
        field: 'accountId',
        value: null
      }, {
        field: 'filter',
        value: 'team'
      },
    {field: 'roleType', value: 'projectManager' }]
    });

    // this.teamMember.isLoading = true;

    this.teamMember.fetchPromise();
    // this.fetch();



  }

  ngOnInit() {
    if (this.value && this.value.id) {
      this.control.setValue(this.value);
      this.controlId.setValue(this.value.id);
    }
  }

  clear() {
    this.control.setValue('');
    this.controlId.setValue('');
    this.value = null;
  }

  ngOnChanges() {
    if (this.value && this.value.id) {
      this.control.setValue(this.value);
      this.controlId.setValue(this.value.id);
      this.teamMember.filters.properties['roleType'].value='projectManager'
      this.fetch();
    }
  }

  search(n: string) {
    this.teamMember.filters.properties['displayName'].value = n;
    if (this.controlId.value) {
      this.teamMember.filters.properties['displayName'].value = '';
      this.clear();
    }
    this.fetch();
  }

  onSelect(a?: Admin) {
    this.control.setValue(a || '');
    this.controlId.setValue(a.id || '');
    this.select.emit(a);
  }


  fetch() {
    this.teamMember.fetch().then(d => {

    }).catch(err => {
      //console.log(err);
    });
  }

  display(a: Admin) {
    if (a) {
      return a.displayName || "";
      // return a.firstName + ' ' + a.lastName;
    }
  }

}
