<div class="right">
  <mat-card class="card-outer">

    <div class="header flex" *ngIf="chat?.id">
      <div
        class="flex current-user-info {{chatModel?.properties?.blockedById == appservice?.user?.id || !chatModel?.properties?.blockedById ? onlineStatus :''}} {{appservice?.user?.adminType=='internal'? 'pointer':''}}">
        <img
          [src]="(appservice?.user?.id == chatModel?.properties?.user1Id ? chatModel?.properties?.user2?.imgUrl:chatModel?.properties?.user1?.imgUrl) || 'assets/images/Square icon.png'"
          alt="">
        <!-- <img [src]="chatModel?.properties?.jobApplicant?.imgUrl||'assets/images/Square icon.png'" alt=""
          *ngIf="appservice?.user?.adminType=='internal'"
          [routerLink]="'/pages/admins/resource/external/detail/'+chatModel?.properties?.jobApplicant?.id"> -->
        <div>
          <div class="w-100" font-14 weight="M" color="muted">
            {{appservice?.user?.id == chatModel?.properties?.user1Id ?
            chatModel?.properties?.user2?.displayName:chatModel?.properties?.user1?.displayName}}
          </div>
          <!-- <div class="w-100" font-14 weight="M" color="muted" *ngIf="appservice?.user?.adminType=='internal'"
            [routerLink]="'/pages/admins/resource/external/detail/'+chatModel?.properties?.jobApplicant?.id">
            {{chatModel?.properties?.jobApplicant?.displayName}}
          </div> -->
          <div class="userName" font-13 weight="R" color="dark">
            {{appservice?.user?.id == chatModel?.properties?.user1Id ? chatModel?.properties?.user2?.username :
            chatModel?.properties?.user1?.username}}
          </div>
          <!-- <div class="userName" font-13 weight="R" color="muted" *ngIf="appservice?.user?.adminType=='internal'"
            [routerLink]="'/pages/admins/resource/external/detail/'+chatModel?.properties?.jobApplicant?.id">
            {{chatModel?.properties?.jobApplicant?.username}}
          </div> -->
        </div>
        <div class="info-cont">
          <div class="" font-13 weight="R" color="muted">
            <img src="../../../../../../assets/images/phone.png" alt="">
<!-- 
            {{appservice?.user?.id == chatModel?.properties?.user1Id ?
              chatModel?.properties?.user2?.displayName:chatModel?.properties?.user1?.displayName}} -->

           {{ chatModel?.properties?.user1?.mobile }}
          </div>
          <div font-13 weight="R" color="muted">
            <img src="../../../../../../assets/images/email.png" alt="">
            {{ chatModel?.properties?.user1?.email }}
          </div>
        </div>
      </div>



      <div class="ml-auto more text-right"
        *ngIf="chatModel?.properties?.blockedById == appservice?.user?.id || !chatModel?.properties?.blockedById">
        <button class="icon-btn md ml-2" [matMenuTriggerFor]="menu">
          <!-- <img src="assets/svg/options.svg" alt=""> -->
          <img src="../../../../../../assets/svg/more (1).svg" alt="">
        </button>
        <mat-menu #menu>
          <button mat-menu-item (click)="block('blocked')" *ngIf="!chatModel?.properties?.blockedById">
            Block
          </button>
          <button mat-menu-item (click)="block('active')" *ngIf="chatModel?.properties?.blockedById">
            Unblock
          </button>
        </mat-menu>
      </div>
    </div>
  </mat-card>
  <!-- chat box -->
  <div class="chat-box">
    <div class="chats-scroll" #scrollMe (scroll)="onScroll()">





      <!-- new chat UI -->

      <div class="main-chat">

        <ng-container *ngFor="let item of (messages | async)">

          <div>
            <div>
              <div class="opponent" *ngIf="appservice?.user?.id !== item?.sentById">  
                  
                <div>
                  <img src="assets/images/user_img.png" alt="">
                </div>
                <div>
                  <div>
                    <span class="name"> {{chatModel?.properties?.user1?.displayName}} </span> <span class="time"> {{item?.timeStamp | date: 'hh mm a'}} </span>
                  </div>
                  <div class="message-oppo">
                 {{ item?.message }}
                  </div>
                </div>
              </div>
            
              <div class="message-op" *ngIf="appservice?.user?.id !== item?.sentById">
                   
                <div class="file" *ngIf="item?.file">
                  <img *ngIf="item?.file?.fileType?.startsWith('image')" class="images" [src]="item?.file?.url" alt="blank">
                  <video *ngIf="item?.file?.fileType?.startsWith('video')" [src]="item?.file?.url" controls
                    class="image"></video>
                  <img [title]="item?.file?.name" *ngIf="item?.file?.fileType?.includes('pdf')"
                    src="assets/svg/file/pdf-type.svg" class="icon" alt="">
                  <img [title]="item?.file?.name" *ngIf="item?.file?.fileType?.includes('zip')"
                    src="assets/svg/file/zip-type.svg" class="icon" alt="">
                  <img [title]="item?.file?.name" *ngIf="item?.file?.fileType?.includes('text')" src="assets/svg/file/txt.svg"
                    class="icon" alt="">
            
                  <audio controls class="video" [title]="item?.file?.name" *ngIf="item?.file?.fileType?.startsWith('audio')"
                    [src]="item?.file?.url">
                    <source [src]="item?.file?.url" [type]="item?.file?.fileType">
                  </audio>
                  <img [title]="item?.file?.name" *ngIf="item?.file?.name.includes('xlsx')" src="assets/svg/file/xls.svg"
                    class="icon" alt="">
                  <img [title]="item?.file?.name"
                    *ngIf="!item?.file?.name?.includes('xlsx') &&  !item?.file?.fileType?.includes('image') && !item?.file?.fileType?.includes('pdf')&&!item?.file?.fileType?.startsWith('image')&&!item?.file?.fileType?.includes('video') &&!item?.file?.fileType?.includes('zip')&&!item?.file?.fileType?.includes('text')&&!item?.file?.fileType?.includes('audio')&&!item?.file?.fileType?.includes('html')"
                    src="assets/svg/file/doc.svg" class="icon" alt="">
                  <div class="download">
                    <a [href]="item?.file?.url" download target="_blank">
                      <img src="assets/images/download-gray.png" alt="">
                    </a>
                  </div>
                </div>
                <div *ngIf="item?.message">
             
                </div>
              </div>
            
            </div>


            <div class="me-self" *ngIf="appservice?.user?.id == item?.sentById">
        
              <div>
                <div>
                  <span class="time">  {{item?.timeStamp | date: 'hh mm a'}} </span><span class="name"> {{chatModel?.properties?.user2?.displayName}} </span> 
                </div>
                <div class="message-oppo">
                  {{ item?.message }}
                </div>
              </div>
              <div>
                <img [src]="chatModel?.properties?.user2?.imgUrl || 'assets/images/user_img.png'  " alt="">
              </div>
              
            </div>
          
            <div class="message-my" *ngIf="appservice?.user?.id == item?.sentById">
          
              <div class="file" *ngIf="item?.file">
                <img *ngIf="item?.file?.fileType?.startsWith('image')" class="images" [src]="item?.file?.url" alt="blank">
                <video *ngIf="item?.file?.fileType?.startsWith('video')" [src]="item?.file?.url" controls
                  class="image"></video>
                <img [title]="item?.file?.name" *ngIf="item?.file?.fileType?.includes('pdf')"
                  src="assets/svg/file/pdf-type.svg" class="icon" alt="">
                <img [title]="item?.file?.name" *ngIf="item?.file?.fileType?.includes('zip')"
                  src="assets/svg/file/zip-type.svg" class="icon" alt="">
                <img [title]="item?.file?.name" *ngIf="item?.file?.fileType?.includes('text')" src="assets/svg/file/txt.svg"
                  class="icon" alt="">
             
                <audio controls class="video" [title]="item?.file?.name" *ngIf="item?.file?.fileType?.startsWith('audio')"
                  [src]="item?.file?.url">
                  <source [src]="item?.file?.url" [type]="item?.file?.fileType">
                </audio>
                <img [title]="item?.file?.name" *ngIf="item?.file?.name.includes('xlsx')" src="assets/svg/file/xls.svg"
                  class="icon" alt="">
                <img [title]="item?.file?.name"
                  *ngIf="!item?.file?.name?.includes('xlsx') &&  !item?.file?.fileType?.includes('image') && !item?.file?.fileType?.includes('pdf')&&!item?.file?.fileType?.startsWith('image')&&!item?.file?.fileType?.includes('video') &&!item?.file?.fileType?.includes('zip')&&!item?.file?.fileType?.includes('text')&&!item?.file?.fileType?.includes('audio')&&!item?.file?.fileType?.includes('html')"
                  src="assets/svg/file/doc.svg" class="icon" alt="">
                <div class="download">
                  <a [href]="item?.file?.url" download target="_blank">
                    <img src="assets/images/download-gray.png" alt="">
                  </a>
                </div>
              </div>
            
            </div>

               
          </div>
        

     
    

    </ng-container>

      <!-- <ng-container *ngFor="let item of (messages | async)">
    

    </ng-container> -->

    </div>

<!-- new chat UI -->





      <!-- <div class="chat {{appservice?.user?.id == item?.sentById ? 'received':'sent'}}"
        *ngFor="let item of (messages | async);">
        <div class="profile">
          {{item?.name}}
          <img
            [src]="(chatModel?.properties?.user1Id == item?.sentById ? chatModel?.properties?.user1?.imgUrl : chatModel?.properties?.user2?.imgUrl)  || 'assets/images/Square icon.png'"
            alt="blank-img">






          <img src="../../../../../../assets/images/toogle_icon.png" alt="">







          <div class="time" style="color: #99A6AD;" font-12>
            {{item?.timeStamp | timeago}}
          </div>
        </div>

        <div class="message {{item?.file?'file':''}} {{appservice?.user?.id == item?.sentById ? 'text-right':''}}">
          <div class="file {{item?.message? 'pb-2':'' }}" *ngIf="item?.file">
            <img *ngIf="item?.file?.fileType?.startsWith('image')" class="image" [src]="item?.file?.url" alt="blank">
            <video *ngIf="item?.file?.fileType?.startsWith('video')" [src]="item?.file?.url" controls
              class="image"></video>
            <img [title]="item?.file?.name" *ngIf="item?.file?.fileType?.includes('pdf')"
              src="assets/svg/file/pdf-type.svg" class="icon" alt="">
            <img [title]="item?.file?.name" *ngIf="item?.file?.fileType?.includes('zip')"
              src="assets/svg/file/zip-type.svg" class="icon" alt="">
            <img [title]="item?.file?.name" *ngIf="item?.file?.fileType?.includes('text')" src="assets/svg/file/txt.svg"
              class="icon" alt="">





            <img [title]="item?.file?.name" *ngIf="item?.file?.fileType?.includes('audio')"
              src="assets/svg/file/mp3.svg" class="icon" alt="">







            <audio controls class="video" [title]="item?.file?.name" *ngIf="item?.file?.fileType?.startsWith('audio')"
              [src]="item?.file?.url">
              <source [src]="item?.file?.url" [type]="item?.file?.fileType">
            </audio>
            <img [title]="item?.file?.name" *ngIf="item?.file?.name.includes('xlsx')" src="assets/svg/file/xls.svg"
              class="icon" alt="">
            <img [title]="item?.file?.name"
              *ngIf="!item?.file?.name?.includes('xlsx') &&  !item?.file?.fileType?.includes('image') && !item?.file?.fileType?.includes('pdf')&&!item?.file?.fileType?.startsWith('image')&&!item?.file?.fileType?.includes('video') &&!item?.file?.fileType?.includes('zip')&&!item?.file?.fileType?.includes('text')&&!item?.file?.fileType?.includes('audio')&&!item?.file?.fileType?.includes('html')"
              src="assets/svg/file/doc.svg" class="icon" alt="">
            <div class="download">
              <a [href]="item?.file?.url" download target="_blank">
                <img src="assets/images/download-gray.png" alt="">
              </a>
            </div>
          </div>
          <div *ngIf="item?.message">
            <span [innerHTML]="item?.message | linkify "></span>



            <span linkify  ReadMoreLess [text]="item?.message" [maxLength]="400"></span>




          </div>
        </div>

      </div> -->
    </div>

    <div class="message-box"
      *ngIf="!chatModel?.properties?.blockedBy && chat?.id && chatModel?.properties?.jobApplication?.status != 'rejected' && chatModel?.properties?.jobApplication?.status != 'completed'">
      <div #emojiRef class="emoji-box" *ngIf="isEmoji">
        <emoji-mart (emojiClick)="addEmoji($event)" emoji="point_up"></emoji-mart>
      </div>

      <div class="w-100" *ngIf="upload">
        <div class="progress mb-1">
          <div class="progress-bar progress-bar-striped bg-color" role="progressbar" [style.width]="percentage+'%'">
          </div>
        </div>
      </div>
      <div class="flex w-100 p-2" *ngIf="selectedFiles?.length">

        <div class="file-box" *ngFor="let file of selectedFiles">
          <img src="assets/svg/Cancel.svg" *ngIf="!upload" class="cancel" (click)="selectedFiles=[]">
          <img [title]="file?.name" *ngIf="this.selectfileType.startsWith('image')" [src]="base64" class="image" alt="">
          <video *ngIf="selectfileType.startsWith('video')" [src]="base64" controls class="video"></video>
          <img [title]="file?.name" *ngIf="selectfileType?.includes('pdf')" src="assets/svg/file/pdf-type.svg"
            class="icon" alt="">
          <img [title]="file?.name" *ngIf="selectfileType?.includes('zip')" src="assets/svg/file/zip-type.svg"
            class="icon" alt="">
          <img [title]="file?.name" *ngIf="selectfileType?.includes('text')" src="assets/svg/file/txt.svg" class="icon"
            alt="">
          <img [title]="file?.name" *ngIf="selectfileType?.includes('audio')" src="assets/svg/file/mp3.svg" class="icon"
            alt="">
          <img [title]="file?.name" *ngIf="file?.name?.includes('xlsx')" src="assets/svg/file/xls.svg" class="icon"
            alt="">
          <img [title]="file?.name"
            *ngIf="!file.name?.includes('xlsx') && !selectfileType?.includes('pdf')&&!selectfileType?.includes('image')&&!selectfileType?.includes('video')&&!selectfileType?.includes('zip')&&!selectfileType?.includes('text')&&!selectfileType?.includes('audio')&&!selectfileType?.includes('html')"
            src="assets/svg/file/doc.svg" class="icon" alt="">
        </div>
        <!-- <div class="file-box">
          <img src="assets/svg/Cancel.svg" class="cancel">
          <img src="assets/svg/pdf-type.svg" class="icon" alt="">
        </div> -->
      </div>

      <div class="flex w-100">
        <img src="assets/svg/attachment-gray.svg" class="mr-2 attachment pointer" alt=""
          *ngIf="!selectedFiles?.length && !upload" (click)="fileInput.value=null;fileInput.click()">
        <textarea (click)="msgBoxClick()" (input)="whenTyping()" placeholder="Write your Message Here..."
          [(ngModel)]="msg" (keyup.enter)="submitBtn.click()"></textarea>
        <input type="file" class="d-none" (change)="selectFile($event)" #fileInput>


        <!-- <img src="assets/svg/attachment-gray.svg" class="mr-2 attachment pointer" alt=""
          *ngIf="!selectedFiles?.length && !upload" (click)="fileInput.value=null;fileInput.click()"> -->


        <!-- <img src="assets/svg/smile.svg" class="mr-2"
          (click)="$event.preventDefault();$event.stopPropagation();isEmoji =! isEmoji" alt=""> -->


        <button #submitBtn class="sent-btn" (click)="sendMessage()"
          [disabled]="upload || btnDisable || !msg && !selectedFiles?.length">
          <img src="assets/svg/sent-email.svg" alt="">
        </button>
      </div>

    </div>

  </div>
</div>