import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  FileUploader,
  FileUploaderOptions,
  ParsedResponseHeaders,
  FileItem,
} from 'ng2-file-upload';
import { AppService } from 'src/app/services/app.service';
import { NbDialogRef } from '@nebular/theme';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cropper',
  templateUrl: './cropper.component.html',
  styleUrls: ['./cropper.component.css'],
})
export class CropperComponent implements OnInit {
  @Input() uploadUrl: string;
  @Input() cropRatio: any;
  @Input() headers: any;
  @Input() template: any;
  @Input() dontUpload: boolean;
  @Input() selectFile: string;

  @Output() url: EventEmitter<any> = new EventEmitter();
  @Output() cropFile: EventEmitter<File> = new EventEmitter<File>();
  @Output() uploadSuccess: EventEmitter<any> = new EventEmitter<any>();
  @Output() uploadError: EventEmitter<any> = new EventEmitter<any>();
  @Output() fileType: EventEmitter<string> = new EventEmitter<string>();

  uploader: FileUploader;
  file: File;
  selectfileType: string;
  event: any;
  failed: boolean = false;
  dialogRef: NbDialogRef<any>;
  base64: any;
  @Input() dummyImg: any;

  constructor(public commenService: AppService) {}

  ngOnInit() {
    this.setUploader();
  }

  selectImg(event: any, template) {
    this.setUploader();
    let file: File = event.target.files[0];
    this.selectfileType = file.type;
    if (
      !this.selectFile &&
      !this.selectfileType.startsWith('image') &&
      !this.selectfileType.startsWith('video')
    ) {
      return this.commenService.toastr.info(
        'Only image or video files is supported. Please select only image or video'
      );
    }
    // New
    if (this.selectFile !== this.selectfileType.split('/')[0]) {
      return this.commenService.toastr.error(
        `Select only ${this.selectFile} files.`
      );
    }
    if (this.selectFile && !this.selectfileType.startsWith(this.selectFile)) {
      return this.commenService.toastr.info(
        `Only ${this.selectFile} files is supported. Please select only ${this.selectFile}`
      );
    }
    this.fileType.emit(file.type.startsWith('image') ? 'photo' : 'video');
    this.event = event;
    this.file = file;
    this.addToQueue(file);
    this.convertBase64();
    this.dialogRef = this.commenService.dialogService.open(template, {
      closeOnBackdropClick: false,
      hasScroll: true,
    });
  }

  convertBase64() {
    let file = this.file;
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.base64 = reader.result;
    };
  }

  chooseImg(event: any) {
    this.getImageFromBase64(event.base64, (file) => {
      this.file = file;
      if (!this.dontUpload) {
        this.addToQueue(file);
      }
    });
  }

  done() {
    if (this.dontUpload) {
      this.cropFile.emit(this.file);
      this.dialogRef.close();
    } else {
      this.upload();
    }
  }

  addToQueue(file: File) {
    this.uploader.clearQueue();
    if (file) {
      this.uploader.addToQueue([file]);
    }
  }

  uploaderOptions(options: FileUploaderOptions) {
    this.uploader.options = options;
  }

  setUploader() {
    this.uploader = new FileUploader({
      itemAlias: 'media',
      headers: [this.getHeaders()],
      url: this.uploadUrl
        ? this.uploadUrl
        : environment.apiUrls.api + '/api/images/upload',
      autoUpload: false,
    });

    this.uploader.onSuccessItem = (
      item: FileItem,
      response: string,
      status: number,
      headers: ParsedResponseHeaders
    ) => {
      let res:any = JSON.parse(response);
      
      console.log(res, 'ressssssssss')
      if (res.isSuccess) {
        this.cropFile.emit(this.file);
        this.url.emit(res);
        this.uploadSuccess.emit(res);
        this.dialogRef.close();
        this.failed = false;
      }

      if (!res.isSuccess) {
        this.commenService.toastr.error(
          'image upload failed. Please try to upload again.'
        );
        this.uploadError.emit(res);
      }
    };

    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };

    this.uploader.onErrorItem = (
      item: FileItem,
      response: string,
      status: number,
      headers: ParsedResponseHeaders
    ) => {
      let res = JSON.parse(response);
      this.failed = true;
      this.commenService.toastr.error(
        'image upload failed. please try to upload again.'
      );
      this.uploadError.emit(res);
    };

    this.uploader.onCompleteAll = () => {
      if (!this.failed) {
        this.uploader.clearQueue();
      }
    };

    this.uploader.onAfterAddingFile = (item) => {
      item.withCredentials = false;
    };
  }

  getHeaders(): { name: string; value: string } {
    return { name: 'x-access-token', value: localStorage.getItem('token') };
  }

  upload() {
    this.failed = false;
    if (this.uploader.queue.length > 0) {
      this.uploader.uploadAll();
    }
  }

  private getImageFromBase64(base64, callback) {
    let dataURI = base64;
    // convert base64 to raw binary data held in a string
    let byteString = atob(dataURI.split(',')[1]);
    // separate out the mime component
    // write the bytes of the string to an ArrayBuffer
    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    // write the ArrayBuffer to a blob, and you're done
    let bb = new Blob([ab], { type: 'image/jpeg' });
    let editedFile: any;

    try {
      editedFile = new File([bb], this.event.target.files[0].name, {
        type: 'image/jpeg',
      });
    } catch (err) {
      editedFile = bb;
      editedFile.name = this.event.target.files[0].name;
      editedFile.lastModifiedDate = new Date();
    }
    return callback(editedFile);
  }
  error(value) {
    this.commenService.toastr.error(value);
  }
  // this.commenService.toastr.closeAll();

  // close(error?: string) {
  //   let res = {
  //     error: null,
  //     file: null,
  //     base64: null
  //   }
  //   if (error) {
  //     res.error = error;
  //     this.commenService.toastr.error(error);
  //     return this.url.emit(res);
  //   }
  //   this.getImageFromBase64(this.croppedImage.file as any, this.croppedImage.base64, (file) => {
  //     this.croppedImage.file = file;
  //     res.file = file;
  //     res.base64 = this.croppedImage.base64;
  //     this.addFileInUploadQueue();
  //   });
  // }
}
