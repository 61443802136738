<div  class="{{class}} outer">
  <input type="checkbox" style="display: none;" [checked]="value || control.value" #checkbox
    (change)="valueChange.emit(checkbox.checked)" [formControl]="control" >

  <div class="check-box" (click)="checkbox.click()">
    <img src="../../../../../assets/images/check.png" alt="">
  </div>

  <label class="chekk" (click)="checkbox.click()" *ngIf="placeholder">{{placeholder}}</label>
</div>
