import { Directive, ElementRef, Input, NgModule, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[ReadMoreLess]'
})
export class ReadMoreLessDirective implements OnChanges, OnInit {

  @Input() maxLength: number = 22;
  @Input() text: string = '';
  btn: HTMLButtonElement = document.createElement('button');
  showText: 'less' | 'more' = 'less';

  constructor(private _el: ElementRef<HTMLElement>) {
    _el.nativeElement.style.transition = 'all .25s ease-in-out';
    this.btn.style.border = 'none';
    this.btn.style.background = 'transparent';
    this.btn.style.color = '#f0775e';
    this.btn.style.fontSize = '12px';
    this.btn.innerText = 'Show more';
    this.btn.style.outline = 'none';
    this.btn.style.padding = '0px';
    this.btn.style.marginLeft = '.5rem';
    this.btn.addEventListener('click', (e) => {
      e.preventDefault(); e.stopPropagation();
      if (this.showText == 'less') {
        this.showText = 'more';
        _el.nativeElement.innerText = this.text;
        this.btn.innerText = 'show less';
      } else {
        this.showText = 'less';
        _el.nativeElement.innerText = this.text.substring(0, this.maxLength) + '...';
        this.btn.innerText = 'show more';
      }
    });
    this.btn.addEventListener('mouseover', (e) => {
      this.btn.style.textDecoration = 'underline';
    });
    this.btn.addEventListener('mouseout', (e) => {
      this.btn.style.textDecoration = 'none';
    });
    _el.nativeElement.after(this.btn);
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['text']) {
      if (this.text?.length <= this.maxLength) {
        this._el.nativeElement.innerText = this.text;
      } else {
        this._el.nativeElement.innerText = this.text?.substring(0, this.maxLength) + '...';
      }
    }
    if (this.text?.length <= this.maxLength) {
      this.btn.style.display = 'none';
    } else {
      this.btn.style.display = 'inline';
    }
  }

}

@NgModule({
  declarations: [ReadMoreLessDirective],
  exports: [ReadMoreLessDirective]
})

export class ReadMoreModule{}