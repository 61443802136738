import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'Purpose'
})
export class PurShootPipe implements PipeTransform {
  transform(purposeOfShoot: any, args?: any): any {
    if (args == 'purp') return this.Purpp(purposeOfShoot);
  }
  Purpp(purposeOfShoot: string) {
    if (purposeOfShoot === 'marketing') {
      return "Marketing";
    }
    if (purposeOfShoot === 'buildingDocumentation') {
        return "buildingDocumentation";
      }
      if (purposeOfShoot === 'stockAddedToTheOptions') {
        return "Stock";
      }
      if (purposeOfShoot === 'website') {
        return "website";
      }
  }
}
