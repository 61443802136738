import { FormGroup, FormControl, FormArray, AbstractControl } from "@angular/forms";

export class ValidatationService {

  static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    let config = {
      'required': 'Field is required.',
      'min': `Minimum value required ${validatorValue.min ? validatorValue.min : ''}`,
      'max': `Maximum value should be ${validatorValue.max ? validatorValue.max : ''}`,
      'maxlength': `Maximum word limit reached`,
      'minlength': `Minimum ${validatorValue.requiredLength ? validatorValue.requiredLength : ''} characters are required`,
      'invalidName': 'Enter a valid name',
      'invalidEmailAddress': 'Invalid email address',
      'invalidPassword': 'Password must be of at least 6 characters long.',
      'invalidNumber': 'Enter positive numeric values only',
      'invalidPhoneNumber': 'Enter a valid phone number',
      'invalidCardNumber': 'Please enter a valid credit/debit card number',
      'invalidCVVNumber': 'Enter a valid CVV code',
      'invalidCardExpiry': 'Invalid expiry date',
      'invalidUrl': 'Enter a valid url starting with http://',
      'invalidTaxId': 'Enter a valid TaxId',
      'invalidFaxNumber': 'Enter a valid fax number',
      'invalidZip': 'Please enter a valid zip code',
      'invalidInput': 'Please enter a valid input',
      'invalidSpaceInput': 'Blank space not allowed',
      'invalidGoal': 'Please enter valid goal. ',
      'invalidYouTube': 'Please enter a valid youtube url',
      'invalidAccountNumber': 'Please enter a valid account number',
      "filedNotSame":`New password and Confirm password are not same.`
    };
    return config[validatorName];
  }
  
  static updateForm(
    group: FormGroup | FormControl | FormArray | AbstractControl,
    values: any
  ) {
    if (group instanceof FormControl) {
      group.setValue(values);
    } else if (group instanceof FormGroup) {
      Object.keys(group.controls).forEach((k) => {
        const control = group.get(k);
        if (values && values[k] !== undefined) {
          ValidatationService.updateForm(control!, values[k]);
        }
      });
    } else if (group instanceof FormArray) {
      group.controls.forEach((c, index) => {
        if (values[index] !== undefined) {
          ValidatationService.updateForm(c, values[index]);
        }
      });
    }
  }

  static spaceValidator(control) {
    if (control.value) {
      const v: string = `${control.value}`.replace(/\s+/g, '');
      if (v.length <= 0) {
        return { invalidSpaceInput: true }
      } else {
        return null;
      }
    }
    return null;
  }

  static passwordValidator(control: any) {
    // {6,100}           - Assert password is between 6 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    if (control.value) {
      if (control.value.match(/^[a-zA-Z0-9!@#$%^&*]{6,100}$/)) {
        return null;
      } else {
        return { 'invalidPassword': true };
      }
    }
  }

  static userName(control) {
    if (control.value) {
      if (!/^\w{3,20}$/i.test(`${control.value}`)) {
        return { invalidInput: true }
      } else {
        return null;
      }
    }
    return null;
  }

  static compareValidator(control1, control2) {
    return function matchPassword(c) {
      if (c.get(control1).value && c.get(control2).value && c.get(control1).value !== c.get(control2).value) {
        c.get(control2).setErrors({ filedNotSame: true })
        return { filedNotSame: true }
      } else {
        return null;
      }
    }
  }

  static emailValidator(control: any) {
    // RFC 2822 compliant regex
    if (control.value) {
      if (control.value.match(/^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|glass|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i)) {
        return null;
      } else {
        return { 'invalidEmailAddress': true };
      }
    }
  }

  static phoneNumberValidator(control: any) {
    if (control.value) {
      if (control.value.match(/^\d{10}$/)) {
        return null;
      }
      else if (control.value.match(/^(\+\d{1,3}[- ]?)?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)) {
        return null;
      } else if (control.value.match(/^(\+\d{1,3}[- ]?)?\(?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/)) {
        return null;
      } else {
        return { 'invalidPhoneNumber': true };
      }
    }
  }


  static phoneNumberWithoutDialCodeValidator(control: any) {
    if (control.value) {
      if (control.value.match(/^\d{7,15}$/)) {
        return null;
      } else {
        return { 'invalidPhoneNumber': true };
      }
    }
  }

}
