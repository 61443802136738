<div class="my-4 outer">
  <div class="p-3 bg-white">
    <div class="row">
      <div class="col-12">
        <label class="d-inline">PAYMENT (s)</label> 
        <button mat-button class="float-right" [status]="'secondary'" (click)="openDialog(createTemp)" ButtonUi>Add
          Payment</button>
      </div>
      <div class="col-12">
        <ul>
          <li *ngFor="let p of payments?.items">
            <div class="d-flex">
              <span>{{p?.title}}</span> &nbsp;&nbsp; : &nbsp;&nbsp;
              <p>
                {{p?.amount}} {{p?.currency || '$'}} recieved on {{(p?.date | date:'mediumDate') || 'no date '}}
                by {{filter('paymentMethods',p.paymentMethod)}}
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<ng-template #createTemp>
  <nb-card>
    <nb-card-body>
      <h6>ADD PAYMENT</h6>
      <form class="row m-0" [formGroup]="form" autocomplete="off">
        <div class="col-6 pl-0 mb-4">
          <label for="title">Title</label>
          <input id="title" type="text" InputUi formControlName="title">
        </div>
        <div class="col-6 pr-0 mb-4">
          <label for="date">Date</label>
          <input id="date" type="text" [matDatepicker]="picker" (click)="picker.open()" readonly InputUi
            formControlName="date">
          <mat-datepicker #picker></mat-datepicker>
          <label class="position-absolute m-0 datee">
            <mat-datepicker-toggle class="d-none" [for]="picker">
            </mat-datepicker-toggle>
            <mat-icon>
              <img class="calender" src="../../../../../assets/images/CALENDER.png">
            </mat-icon>
          </label>
        </div>
        <div class="col-6 pl-0 mb-4">
          <label for="amount">Amount</label>
          <input id="amount" [beforeText]="'$'" type="text" NumberInput [includeKeyCodes]="[46]" InputUi formControlName="amount">
        </div>

        <div class="col-6 pr-0 mb-4">
          <label for="paymentMethod">Payment Method</label>
          <select id="paymentMethod" formControlName="paymentMethod" SelectUi>
            <option [value]="i?.value" *ngFor="let i of paymentMethods">{{i?.label}}</option>
          </select>
          <img class="position-absolute imaag" src="../../../../../assets/images/icon_drop down.png">
        </div>
        <div class="col-6 pl-0 mb-4">
          <label for="paymentStyle">Payment Style</label>
          <select id="paymentStyle" formControlName="paymentStyle" SelectUi>
            <option [value]="i?.value" *ngFor="let i of paymentStyle">{{i?.label}}</option>
          </select>
          <img class="position-absolute imaag mr-3" src="../../../../../assets/images/icon_drop down.png">
        </div>
        <div class="col-6 pr-0 mb-4">
          <label for="type">Type</label>
          <select id="type" formControlName="type" SelectUi>
            <option value="received">Received</option>
            <option value="paidOut">Paid out</option>
          </select>
          <img class="position-absolute imaag" src="../../../../../assets/images/icon_drop down.png">
        </div>
        <div class="col-12 text-right px-0">
          <button ButtonUi mat-button type="submit" (click)="create()"
            [disabled]="form.invalid || isLoading">Add</button>
        </div>
      </form>
    </nb-card-body>
  </nb-card>
</ng-template>