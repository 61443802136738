import { Component, OnInit, Input } from '@angular/core';
import { trigger, transition, query, style, stagger, keyframes, animate } from '@angular/animations';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css'],
  animations:[

    trigger('dialog',[
      transition('* => *', [

        query(':enter', style({ opacity: 0 }), { optional:true }),

        query(':enter', stagger('300ms' , [
          animate('1s ease-in',keyframes([
            style({opacity: .7, transform:'translateY(0%)',offset: .35}),
          ]))
        ]), { optional:true }),

        query(':leave', stagger('700ms' , [
          animate('1s ease-in',keyframes([
            style({opacity: .0, transform:'translateY(0%)',offset: .35}),
          ]))
        ]), { optional:true })

      ])
    ])

  ]
})
export class DialogComponent implements OnInit {

  @Input() temps:any = [];

  constructor(
  ) { }

  ngOnInit() {
  }

  colse(i){
    if(this.temps.length){
      this.temps.splice(i,1);
    }
  }

}
