<div class="outer" >
  <div class="top-header">
    <div style="font-size: 30px;
    margin-bottom: 16px;">Select Location</div>
    <mat-button-toggle-group class="ml-2 view" [(ngModel)]="view">
      <mat-button-toggle value="pinDrop" (click)="searchControl?.setValue('')">
          Drop Pin
      </mat-button-toggle>
    
      <mat-button-toggle value="polygonDraw" (click)="searchControl?.setValue('')">
          Draw Polygon
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>


  <div *ngIf="view == 'pinDrop'" class="h-84%" style="margin-top:100px">
   
    <agm-map [latitude]="mapCoodinates[1]" [longitude]="mapCoodinates[0]" [zoom]="15"
    (mapReady)="mapReadyHandler($event)" >
      <agm-marker (dragEnd)="selectMarker($event.latLng.lng(),$event.latLng.lat())" [latitude]="coodinates?.value[1]" [longitude]="coodinates?.value[0]" [markerDraggable]="true"></agm-marker>
    </agm-map>

    <!-- <div class="row m-0 inputText">
      <input class="col" type="text" [formControl]="searchControl" #search InputUi>
      <button mat-button class="save" cdkFocusInitial (click)="dialogRef.close(data)" >Save</button>
      <button mat-stroked-button class="cancel" (click)="dialogRef.close()">Cancel</button>
    </div> -->
  </div>


  <div *ngIf="view =='polygonDraw'" class="h-84%" style="margin-top:100px">
   
    <div class="agm-container">
      <agm-map
        [zoom]="5"
        [latitude]="mapCoodinates[1]"
        [longitude]="mapCoodinates[0]"
        (mapReady)="onMapReady($event)"
      >
      </agm-map>
      <br />
      <div *ngIf="pointList.length>0" class="display-flex">
        <button mat-stroked-button color="warn" (click)="deleteSelectedShape()">Clear</button>
      </div>
   
    </div>
    
    <!-- <div class="row m-0 inputText">
      <input LocationPicker class="col" type="text" [formControl]="searchControl" #search InputUi>
      <button mat-button class="save" cdkFocusInitial (click)="dialogRef.close(data)" >Save</button>
      <button mat-stroked-button class="cancel" (click)="dialogRef.close()">Cancel</button>
    </div> -->
  </div>

  <div class="row m-0 inputText">
    <input class="col" type="text" [formControl]="searchControl" #search InputUi>
    <button mat-button class="save" cdkFocusInitial (click)="dialogRef.close(data)" >Save</button>
    <button mat-stroked-button class="cancel" (click)="dialogRef.close()">Cancel</button>
  </div>

 
</div>



   <!-- <mat-list role="list">
        <mat-list-item role="listitem" *ngFor="let point of pointList">
          {{ point.lat.toPrecision(5) }},
          {{ point.lng.toPrecision(5) }}</mat-list-item
        >
      </mat-list> -->
      <!-- <mat-card *ngIf="selectedArea > 0">
        <span> Selected Area: {{ selectedArea.toPrecision(5) }}m<sup>2</sup> </span>
      </mat-card> -->