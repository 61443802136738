<button class="pre-append" type="button" mat-ripple [matMenuTriggerFor]="countryCodes" >+{{countryCodeControl?.value}}</button>
    <mat-menu #countryCodes class="country-code-container" >
      <header>
        <input class="search-input" type="text" (click)="$event.stopPropagation();" placeholder="Search" (input)="search($event.target.value)" >
      </header>
      <hr>
      <mat-list>
        <mat-list-item matRipple (click)="onSelect(item)" class="dropdown-item" *ngFor="let item of filterCountries;let i = index;">
          <img [src]="item?.flag" width="25px" class="mr-3" alt="">
          +{{item?.callingCodes[0]}} {{item?.name | titlecase}}
        </mat-list-item>
        <div class="p-3 text-center" *ngIf="!filterCountries?.length">
          <font-16>No result found</font-16>
        </div>
      </mat-list>
      
    </mat-menu>
<input type="text" [(ngModel)]="value" maxlength="12" placeholder="Phone No." hyphen [disabled]="isDisable" >