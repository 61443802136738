<div class="main-page page-container">
  <div class="chat-header">
    <div>
      <header>
        <font-25>Chat</font-25>
      </header>
    </div>
    <div>
      <!-- <button class="icon-btn orange ml-2" title="New Chat" (click)="openNewChat()"> -->
      <!-- + -->
      <img src="assets/svg/Invite.svg" style="cursor:pointer" alt="" (click)="openNewChat()">
      <!-- </button> -->
    </div>
  </div>
  <div class="row h-100 pb-5">
    <!-- left list -->
    <div class="col-4 left">


      <mat-card class="card-outer mt-3 px-0 pt-0">
        <!-- search -->
        <div class="p-3 flex bg-col mb-2">
          <div class="col px-0">
            <div class="form-field search-field">
              <input placeholder="Search.." font-13 type="text"
                [(ngModel)]="chatList.filters.properties['search'].value" (input)="search($event)" InputUi>
              <div class="append">
                <img src="assets/svg/search (1).svg" alt="">
              </div>
            </div>
          </div>
          <!-- <button class="icon-btn ml-2" title="New Chat" (click)="openNewChat()">
            <img src="assets/svg/Invite.svg" alt="">
          </button> -->
        </div>

        <!-- divider/ -->
        <!-- <hr class="divider"> -->
        <!-- list -->
        <div class="list-container">
          <!-- row -->
          <div
            class="chater-info row flex pointer {{!this.activeRoute?.snapshot?.queryParams?.applicationId && !item?.jobApplicationId &&  item?.user2Id == this.activeRoute.snapshot.params.userId ? 'active':''}}{{!this.activeRoute?.snapshot?.queryParams?.applicationId && !item?.jobApplicationId &&  item?.user1Id == this.activeRoute.snapshot.params.userId ? 'active':''}} {{this.activeRoute?.snapshot?.queryParams?.applicationId &&this.activeRoute?.snapshot?.queryParams?.applicationId == item?.jobApplicationId ? 'active':''}}"
            *ngFor="let item of filterChatList"
            [routerLink]="appservice.user?.id == item?.user2Id ? url+item?.user1Id : url+item?.user2Id"
            [queryParams]="{'applicationId':item?.jobApplication?.id}" (click)="onChange(item)" placeholderLoading
            [loading]="isLoading">

            <!-- <div class="">
              <div class="font-12">
                W2 Member
              </div> -->




            <!-- </div> -->



            <div class="d-flex col-8 pr-0 pl-0">
              <img (click)="$event.preventDefault();$event.stopPropagation()"
                [src]="(appservice?.user?.id == item?.user1Id ? item?.user2?.imgUrl: item?.user1?.imgUrl) || 'assets/images/Square icon.png'"
                alt="">
              <!-- name -->
              <div class="w-100" font-14 weight="M" color="muted">
                <div>
                  {{appservice?.user?.id == item?.user1Id ? item?.user2?.displayName: item?.user1?.displayName ||
                  'N/a'}} <span *ngIf="item?.jobApplication?.job?.jobNo">({{item?.jobApplication?.job?.jobNo}})</span>
                </div>
                <div class="msg" font-12 weight="R" color="muted">
                  {{item?.lastMessage}}
                </div>
              </div>
            </div>

            <div class="text-right col-4 pl-0 pr-0">
              <div font-12 style="color: #45ab5f;">
                W2 Member
              </div>
              <div class="count"
                *ngIf="appservice?.user?.id == item?.user1Id && item?.user1MessageCount >= 1 || appservice.user?.id == item?.user2Id && item?.user2MessageCount >= 1">
                {{appservice?.user?.id == item?.user1Id ? item?.user1MessageCount: item?.user2MessageCount || 1}}
              </div>
            </div>

          </div>

          <div class="text-center pt-3" *ngIf="!chatList?.items?.length">
            No data found
          </div>

        </div>
      </mat-card>
    </div>

    <!-- right chat -->
    <div class="col-8 h-100 mt-3">
      <app-chat-messages #chatComp [chat]="selectedChat" (afterSent)="getChatList()"></app-chat-messages>
    </div>
  </div>
  <img src="assets/images/bgLogo.png" class="bgLogo">
</div>