import { Component, Output, Input, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { Page } from "src/app/http-handler/common/contracts/page";
import { User } from '../../../../models/user.model';
import { UserService } from '../../../../services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.css']
})
export class ToggleComponent implements OnInit {


  @Input() status: string;
  @Output() statusChange = new EventEmitter<string>();
  // @Output() change = new EventEmitter<string>();

  users: Page<User>;
  // @ViewChild('toggle', { static: false }) toggle: any;

  constructor(
    public router: Router,
    private userService: UserService,
  ) {
    this.users = new Page({
      api: userService.users,
      properties: new User(),
    });
  }
  ngOnInit() {
  }

  togglee() {
    if (this.status == "inactive") {
      this.statusChange.emit("active")
    }
    else {
      this.statusChange.emit("inactive")
    }
  }
}
