import { Component, OnInit, Input, TemplateRef, OnChanges, SimpleChanges } from '@angular/core';
import { Page } from 'src/app/http-handler/common/contracts/page';
import { DealPayment, Payment } from 'src/app/models/payment.model';
import { PaymentService } from 'src/app/services/payments.service';
import { AppService } from 'src/app/services/app.service';
import { NbDialogRef } from '@nebular/theme';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IApi } from 'src/app/http-handler/common/contracts/api';

@Component({
  selector: 'payments',
  templateUrl: './deal-payments.component.html',
  styleUrls: ['./deal-payments.component.css']
})
export class DealPaymentsComponent implements OnInit, OnChanges {

  @Input() shootId: number;
  isLoading: boolean = false;
  payments: Page<Payment>;


  paymentMethods = [{
    label: 'Net Banking',
    value: 'netBanking'
  }, {
    label: 'Debit Card',
    value: 'debitCard'
  }, {
    label: 'Credit Card',
    value: 'creditCard'
  },{
    label: 'Cash',
    value: 'cash'
  }];


 types = [
    { label: "Initial Deposit", value: "initialDeposit" },
    { label: "Initial 25% Deposit", value: "initial25Deposit" },
    { label: "Initial 50% Deposit", value: "initial50Deposit" },
    { label: "Paid in Full in Advance", value: "paidInFullInAdvance" },
    { label: "Paid in Full (Before Deliverables Sent)", value: "paidInFullBeforeDeliverablesSent" },
    { label: "Paid in Full (After Deliverables Sent)", value: "paidInFullAfterDeliverablesSent" }
  ]

  paymentStyle = [{
    label: 'Exterior',
    value: 'exterior'
  }, {
    label: 'In House',
    value: 'inHouse'
  },
  {
    label: 'On The Spot',
    value: 'onTheSpot'
  }];

  dialogRef: NbDialogRef<any>;

  form: FormGroup = new FormGroup({
    title: new FormControl('', [Validators.required]),
    date: new FormControl('', [Validators.required]),
    amount: new FormControl('', [Validators.required]),
    currency: new FormControl('$'),
    paymentStyle: new FormControl('', [Validators.required]),
    paymentMethod: new FormControl('', [Validators.required]),
    paymentStage: new FormControl('', [Validators.required])
  })


  constructor(
    private _CommenService: AppService,
    private _PaymentService: PaymentService,
  ) {

    this.payments = new Page({
      api: _PaymentService.shootPayments,
      serverPaging: false,
      properties: new Payment(),
      filters: [{
        field: 'shootId',
        value: null
      }]
    });

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['shootId'] && this.shootId) {
      this.payments.filters.properties['shootId'].value = this.shootId;
      this.fetch();
    }
  }

  ngOnInit() {
    
  }

  openDialog(temp: TemplateRef<any>) {
    this.dialogRef = this._CommenService.dialogService.open(temp);
  }

  fetch() {
    this.payments.fetch().then(d => {

    }).catch(err => {
      //console.log(err);
    });
  }

  filter(type, value) {
    let f = this[type].find(i => i.value === value);
    if (f) {
      return f.label;
    }
  }

  create() {
    this.isLoading = true;
    let body: Payment = { ...this.form.value };
    if (this.shootId) body.shootId = this.shootId;
    if(body.amount) body.amount = parseFloat(body.amount as any);
    this._PaymentService.shootPayments.create(body).then(d => {
      this.isLoading = false;
      this.dialogRef.close();
      this.form.reset();
      this.fetch();
    }).catch(err => {
      this.isLoading = false;
      this._CommenService.toastr.error(err);
    });
  }

}
