<div class="form-field" *ngIf="type == 'editable'">
    <div contenteditable="false" class="hover flex py-1" (click)="selected = null;onBtnClick()"
        [matMenuTriggerFor]="adminOptions">
        <user-profile [name]="selected?.displayName" [image]="selected?.imgUrl" diameter="33" color="muted" size="14"
            weight="M">
        </user-profile>
    </div>

    <div class="append flex" font-13 *ngIf="selected">
        <mat-icon [inline]="true">clear</mat-icon>
    </div>
</div>


<div *ngIf="type === 'icon'">
    <button class="assign-btn" type="button" (click)="onBtnClick()" [matMenuTriggerFor]="adminOptions">
        <img *ngIf="!selected?.id" [class.p-1]="true" src="assets/svg/user_placeholder.svg" alt="">
        <logo diameter="23" [tooltip]="selected?.displayName" [size]="12" [src]="selected?.imgUrl" *ngIf="selected?.id"
            [title]="selected?.displayName"></logo>
    </button>
</div>
<div *ngIf="type === 'whiteIcon'">
    <button class="whiteIcon" (click)="onBtnClick()" type="button" [matMenuTriggerFor]="adminOptions">
        <img *ngIf="!selected?.id" src="assets/svg/user-white.svg" alt="">
        <logo diameter="23" [tooltip]="selected?.displayName" [size]="12" [src]="selected?.imgUrl" *ngIf="selected?.id"
            [title]="selected?.displayName"></logo>
    </button>
</div>


<mat-menu #adminOptions>
    <mat-list>
        <mat-list-item *ngIf="assignToMe" (click)="onSelect(appService?.user)" mat-ripple placeholderLoading
            [loading]="teamMember?.isLoading">
            <user-profile [name]="'Assign to me'" [image]="appService?.user?.imgUrl" diameter="24" size="13"
                weight="M">
            </user-profile>
        </mat-list-item>
        <mat-list-item (click)="onSelect(item)" *ngFor="let item of teamMember?.items" mat-ripple placeholderLoading
            [loading]="teamMember?.isLoading">
            <user-profile [name]="item?.displayName" [image]="item?.imgUrl" diameter="24" size="13" weight="M">
            </user-profile>
        </mat-list-item>
    </mat-list>
    <data-not-found *ngIf="!teamMember?.items?.length && !teamMember?.isLoading" [text]="'Team is not found.'">
    </data-not-found>
</mat-menu>