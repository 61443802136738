<div class="bar-container">
  <div class="content">
    {{selectedTasks?.length}} tasks selected
  </div>
  <div class="button-container">
    <button class="assign-btn" tooltip="Mark Complete" (click)="upDateTask('done')">
      <img src="assets/svg/check-mark-white.svg" alt="">
    </button>
    <button class="assign-btn" (click)="dueDate.open();">
      <img src="assets/svg/calendar-white.svg" alt="" tooltip="Set Due Date">
    </button>
    <input type="text"  [matDatepicker]="dueDate" (dateChange)="getAssignDate($event)" class="d-none"/>
    <mat-datepicker touchUi #dueDate></mat-datepicker>
    <!-- <button class="assign-btn" (click)="upDateTask('assign')">
      <img src="assets/svg/user-white.svg" alt="" tooltip="Assign">
    </button> -->
    <app-task-incharge *ngIf="selectedTasks?.length" [(ngModel)]="assignedTo" [tooltip]="assignedTo?null:'Assign'" [uiType]="'whiteIcon'"
     (onSelect)="upDateTask('assign',$event)"></app-task-incharge>
    <button class="assign-btn" (click)="upDateTask('delete')">
      <img src="assets/svg/delete-white.svg" alt="">
    </button>
    <button class="assign-btn ml-2" (click)="clear.emit(null)" tooltip="Close"> 
      <img src="assets/svg/close-white.svg" alt="" >
    </button>
  </div>
</div>