<div>
  <input [formControl]="control" [matAutocomplete]="auto" [placeholder]="placeholder || ''" InputUi
    (input)="search($event.target.value)">
  <div class="append">
    <i class="fa fa-spinner fa-spin" *ngIf="teamMember?.isLoading"></i>
    <img *ngIf="!teamMember?.isLoading" src="../../../../../assets/images/icon_search gray.png">
  </div>

  <mat-autocomplete #auto="matAutocomplete" [displayWith]="display" (optionSelected)="onSelect($event.option.value)">
    <mat-option *ngFor="let a of teamMember.items" [value]="a">
      <img height="100%" class="rounded-circle" [src]="a?.imgUrl || '../../../../../assets/images/PROFILE.png'">
      {{a?.displayName}}
    </mat-option>
  </mat-autocomplete>
</div>