import { TeamDepartment } from './team-department.model';
export class User {
    id?: number;
    username?: string;
    token?: string;
    email?: string;
    password?: string;
    confirmPassword?: string;
    newPassword?: string;
    deviceType?: string = 'web';
    adminType?:string;
    role?:Role
    roleType?: string;
    countryCode?: string;
    firstName?: string;
    imgUrl?: string;
    isProfileCompleted?: boolean;
    lastName?: string
    location?: string;
    loginType?: string;
    phone?: any;
    status?: string;
    tierId?: number;
    createdAt?: any;
    orderCount?: number;
    vehicle?: any;
    name?: string;
    displayName?: string;
    error?: string;
    deviceId?: string = 'string';
    userType?: string = 'partner';
    btnDisable?: boolean;
    category?: string;
    businessId?: any;
    teamDepartment?:TeamDepartment = {};
    accountId?: number;
    contactId?:number;

}

export class Role{
    id?:any;
    name?:string;
}