import { Directive, ElementRef, Input, OnInit, OnChanges } from '@angular/core';

@Directive({
  selector: '[ButtonUi]'
})
export class ButtonUiDirective implements OnInit ,OnChanges{

  button: HTMLButtonElement;

  @Input() status: 'primary' | 'secondary' | 'muted' = 'primary';

  constructor(
    private el: ElementRef<HTMLButtonElement>
  ) {
    this.button = el.nativeElement;
    this.primary()
  }

  ngOnInit() {
    this[this.status]();
  }

  ngOnChanges(){
    this[this.status]();
  }

  primary() {
    let b = this.button;
    b.style.backgroundColor = '#f0775e';
    b.style.color = 'white';
    b.style.outline = 'none';
    b.style.border = 'none';
    b.style.fontSize = '12px';
    b.style.padding = '10px 36px';
    b.style.fontWeight = '600';
    b.style.borderRadius = '2px';
    b.style.cursor = "pointer";
    b.style.lineHeight = 'normal';
  }

  secondary() {
    let b = this.button;
    b.style.cursor = 'pointer';
    b.style.color = '#f0775e';
    b.style.fontSize = '12px';
    b.style.border = '1px solid #f0775e';
    b.style.fontWeight = '500';
    b.style.backgroundColor = 'white';
    b.style.outline = 'none';
    b.style.padding = '5px 54px';
    b.style.borderRadius = '2px';
  }

  muted(){
    let b = this.button;
    b.style.background= '#bdbfbf61';
    b.style.color= '#012c49';
    b.style.fontSize = '12px';
    b.style.outline = 'none';
    b.style.border = 'none';
    b.style.padding = '11px 17px';
    b.style.fontWeight = '600';
    b.style.borderRadius = '2px';
    b.style.cursor = "pointer";
    b.style.lineHeight = 'normal';
  }

}
