import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IApi } from '../http-handler/common/contracts/api';
import { GenericApi } from '../http-handler/common/generic-api';
import { ShootSub } from '../models/shoot-subcategory.model';

@Injectable({
    providedIn: 'root'
})
export class ShootSubService {

    shootSubcat: IApi<ShootSub>;
    constructor(private http: HttpClient) {
        this.shootSubcat = new GenericApi<ShootSub>('shootSubCategories', http)
    }
}
