import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Model } from 'src/app/http-handler/common/contracts/model';
import { Chat } from 'src/app/models/chat.model';
import { FireBaseMessage } from 'src/app/models/firebase-message.model';
import { AppService } from 'src/app/services/app.service';
import { ChatService } from 'src/app/services/chat.service';
import { SupportFirebaseService } from 'src/app/services/support-service/support-firebase.service';
import { FileUploadService } from 'src/app/services/firebase-file-upload/firebase-file-upload.service';
import { PresenceService } from 'src/app/services/support-service/presense.service';
import { FirebaseFileUpload } from 'src/app/models/firebase-upload.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-chat-messages',
  templateUrl: './chat-messages.component.html',
  styleUrls: ['./chat-messages.component.scss'],
  host: {
    '(document:click)': 'closeEmoji($event)',
  },
})
export class ChatMessagesComponent implements OnChanges, AfterViewChecked {
  private collectionUrl = environment.firebaseCollectionUrl;
  @ViewChild('emojiRef') emojiRef: ElementRef<HTMLDivElement>;
  messages: Observable<FireBaseMessage[]>;
  @Input('chat') chat: Chat;
  @Output() afterSent: EventEmitter<Chat> = new EventEmitter();
  isEmoji: boolean;
  chatModel: Model<Chat>;
  selectfileType: string;
  selectedFiles: FileList;
  currentFileUpload: FirebaseFileUpload;
  percentage: number;

  @ViewChild('scrollMe') private myScrollContainer: ElementRef;

  ngAfterViewChecked() {
    if (this.isScroll) {
      this.scrollToBottom();
    }
  }
  isScroll: boolean = true;
  onScroll() {
    this.isScroll = false;
  }
  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop =
        this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) {}
  }

  constructor(
    private chatService: ChatService,
    public appservice: AppService,
    public activeRoute: ActivatedRoute,
    private supportChatService: SupportFirebaseService,
    private uploadService: FileUploadService,
    private presenceService: PresenceService
  ) {
    this.chatModel = new Model({
      api: chatService.chats,
      properties: new Chat(),
    });
  }

  closeEmoji(event) {
    if (!this.emojiRef?.nativeElement?.contains(event.target)) {
      this.isEmoji = false;
    }
  }

  onlineStatus: any;

  ngOnChanges() {
    this.subscribeMessages();
    if (this.chat?.id) {
      this.fetch();
      if (this.isScroll) {
        this.scrollToBottom();
      }
    }

    if (this.appservice?.user?.id == this.chatModel?.properties?.user1Id) {
      this.onlineStatus = '';
      this.presenceService
        ?.getPresence(this.chatModel?.properties?.user2Id)
        .subscribe((d: any) => {
          this.onlineStatus = d?.status;
        });
    } else {
      this.onlineStatus = '';
      this.presenceService
        ?.getPresence(this.chatModel?.properties?.user1Id)
        .subscribe((d: any) => {
          this.onlineStatus = d?.status;
        });
    }
  }

  subscribeMessages() {
    this.messages = this.supportChatService.getMessages(this.chat?.id);
    this.messages.subscribe((d) => {
      if (this.chat?.id) {
        this.fetch();
        this.isScroll = true;
      }
      this.afterSent.emit();
      this.presenceService.setPresence('online');
      this.onlineStatus = '';
      if (this.appservice?.user?.id == this.chatModel?.properties?.user1Id) {
        this.presenceService
          ?.getPresence(this.chatModel?.properties?.user2Id)
          .subscribe((d: any) => {
            this.onlineStatus = d?.status;
          });
      } else {
        this.presenceService
          ?.getPresence(this.chatModel?.properties?.user1Id)
          .subscribe((d: any) => {
            this.onlineStatus = d?.status;
          });
      }
    });
  }

  fetch() {
    this.chatModel
      .fetch(this.chat?.id)
      .then((d) => {
        this.setCountZero();
      })
      .catch((err) => {
        this.appservice.toastr.error(err);
      });
  }

  setCountZero() {
    let body: Chat = {};
    if (this.appservice?.user?.id == this.chatModel?.properties?.user1Id) {
      body.user1MessageCount = 0;
    }
    if (this.appservice?.user?.id == this.chatModel?.properties?.user2Id) {
      body.user2MessageCount = 0;
    }
    this.chatService.chats
      .update(this.chat?.id, body)
      .then((d) => {
        this.afterSent.emit();
      })
      .catch((err) => {
        this.appservice.toastr.error(err);
      });
  }

  addEmoji($event) {
    let data = this.msg;
    if (data) {
      this.msg = data + $event.emoji.native;
    } else {
      this.msg = $event.emoji.native;
    }
  }

  msg: string;
  btnDisable?: boolean;
  whenTyping() {
    if (this.msg) {
      const v: string = `${this.msg}`.replace(/\s+/g, '');
      if (v.length <= 0) {
        this.btnDisable = true;
      } else {
        this.btnDisable = false;
      }
    }
  }

  sendMessage() {
    let chat: Chat = {};
    chat.id = this.chat?.id;
    chat.sentById = this.appservice?.user?.id;
    chat.jobApplicantId = this.chat?.jobApplicantId;

    if (this.selectedFiles?.length) {
      this.uploadFile();
      if (this.isScroll) {
        this.scrollToBottom();
      }
    }
    if (this.msg) {
      this.supportChatService?.sendMessage(chat, this.msg);
      let m = this.msg;
      this.msg = '';
      this.chatService.chats
        .update(
          this.chat.id,
          { text: m, userType: this.appservice?.user?.adminType },
          null,
          'last/message/' + this.chat.id
        )
        .then((d) => {
          this.afterSent.emit(d);
          this.scrollToBottom();
        })
        .catch((err) => {
          this.appservice.toastr.error(err);
        });
    }
  }

  msgBoxClick() {
    if (this.isEmoji) {
      this.isEmoji = false;
    }
  }

  block(status: string) {
    if (status == 'blocked' && !confirm('Are you sure you want to block?'))
      return;
    this.chatService.chats
      .update(this.chat.id, {
        status: status,
        userType: this.appservice?.user?.adminType,
      })
      .then((d) => {
        this.afterSent.emit(d);
        this.appservice.toastr.success(
          status == 'blocked'
            ? 'Blocked successfully.'
            : 'Unblocked successfully.'
        );
        this.fetch();
      })
      .catch((err) => {
        this.appservice.toastr.error(err);
      });
  }

  delete() {
    if (!confirm('Are you sure you want to delete chat?')) return;
    this.supportChatService
      .deleteChat(this.chat?.id)
      .then((d) => {
        this.appservice.toastr.success('Chat deleted successfully');
        this.chatService.chats
          .update(
            this.chat.id,
            { text: ' ', userType: this.appservice?.user?.adminType },
            null,
            'last/message/' + this.chat.id
          )
          .then((d) => {
            this.afterSent.emit(d);
          })
          .catch((err) => {
            this.appservice.toastr.error(err);
          });
      })
      .catch((err) => {
        this.appservice.toastr.error(err);
      });
  }

  selectFile(event): void {
    this.selectedFiles = event.target.files;
    if (this.selectedFiles[0].size > 20 * 1024 * 1024) {
      this.appservice.toastr.error(
        'Files more than 20 Mb can not be shared in chat, please consider sending a link instead.'
      );
      this.selectedFiles = null;
      return;
    }
    this.selectfileType = event.target.files[0].type;
    this.convertBase64();
  }

  upload: boolean;
  uploadFile(): void {
    const file = this.selectedFiles.item(0);
    // this.selectedFiles = undefined;
    this.currentFileUpload = new FirebaseFileUpload(file);
    this.percentage = 0;
    this.upload = true;
    this.uploadService
      .pushFileToStorage(this.currentFileUpload, this.chat, this.selectfileType)
      .subscribe(
        (percentage) => {
          this.percentage = Math.round(percentage);
          if (this.percentage == 100) {
            setTimeout(() => {
              this.upload = false;
              this.selectedFiles = undefined;
              this.chatService.chats
                .update(
                  this.chat.id,
                  {
                    text: 'Attachment file',
                    userType: this.appservice?.user?.adminType,
                  },
                  null,
                  'last/message/' + this.chat.id
                )
                .then((d) => {
                  this.afterSent.emit(d);
                })
                .catch((err) => {
                  this.appservice.toastr.error(err);
                });
            }, 400);
          }
        },
        (error) => {
          this.appservice.toastr.error(error);
          this.upload = false;
        }
      );
  }

  base64: any;
  convertBase64() {
    let file = this.selectedFiles.item(0);
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.base64 = reader.result;
    };
  }
}
