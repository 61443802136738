import { Injectable } from '@angular/core';
import { IApi } from '../http-handler/common/contracts/api';
import { Invite } from '../models/Invite.model';
import { HttpClient } from '@angular/common/http';
import { GenericApi } from '../http-handler/common/generic-api';

@Injectable({
  providedIn: 'root'
})
export class InviteService {

  invites:IApi<Invite>;

constructor(
  http:HttpClient
) {

  this.invites = new GenericApi<Invite>('invites',http);

 }

}
