import {
  Component,
  OnInit,
  forwardRef,
  EventEmitter,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-priority-bar',
  templateUrl: './priority-bar.component.html',
  styleUrls: ['./priority-bar.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => PriorityBarComponent),
    },
  ],
})
export class PriorityBarComponent implements OnInit {
  priorityLevels = [0, 1, 2, 3, 4, 5];

  @Output('onChange') change: EventEmitter<number> = new EventEmitter();

  onChange: any = () => {};
  onTouch: any = () => {};
  val: string | number = 1; // this is the updated value that the class accesses
  set value(val) {
    // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
    this.val = val;
    this.onChange(val);
    this.onTouch(val);
  }

  get value() {
    return this.val;
  }

  constructor() {}

  ngOnInit() {}

  writeValue(value: any) {
    this.value = value;
  }
  // upon UI element value changes, this method gets triggered
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  // upon touching the element, this method gets triggered
  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }
}
