import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IApi } from '../http-handler/common/contracts/api';
import { GenericApi } from '../http-handler/common/generic-api';
import { AdminEquipment, Equipment, EquipmentManufacturer, EquipmentModel } from '../models/equipment.model';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class EquipmentService {

  equipments: IApi<Equipment>;
  equipmentManufacturers: IApi<EquipmentManufacturer>;
  equipmentModels: IApi<EquipmentModel>;
  adminEquipments: IApi<AdminEquipment>;

  constructor(
    http: HttpClient,
    private _appService: AppService
  ) {
    this.equipments = new GenericApi<Equipment>('equipments', http);
    this.equipmentManufacturers = new GenericApi<EquipmentManufacturer>('equipmentManufacturers', http);
    this.equipmentModels = new GenericApi<EquipmentModel>('equipmentModels', http);
    this.adminEquipments = new GenericApi<AdminEquipment>('adminEquipments', http);

  }

  addEquipment(e) {
    return this.adminEquipments.create(e).then(d => {
      this._appService.toastr.success('Equipment successfully added.');
      return d;
    }).catch(err => {
      this._appService.toastr.error(err);
      return Promise.reject(err);
    })
  }

  update(id, e) {
    return this.adminEquipments.update(id, e).then(d => {
      this._appService.toastr.success('Equipment successfully updated.');
      return d;
    }).catch(err => {
      this._appService.toastr.error(err);
      return Promise.reject(err);
    })
  }

  remove(id) {
    return this.adminEquipments.remove(id).then(d => {
      this._appService.toastr.success('Equipment successfully deleted.');
      return d;
    }).catch(err => {
      this._appService.toastr.error(err);
      return Promise.reject(err);
    })
  }

}
