<header class="flex justify-content-center items-center border-gray border-bottom">
  <div (click)="dashBoardRoute()">
    <img *ngIf="isSmallNav" width="32px" src="assets/images/Top Logo.svg" alt="">
    <img *ngIf="!isSmallNav" width="100px" src="assets/svg/full-logo.svg" alt="logo">
  </div>
  <button class="icon-btn shadow-sm overflow-hidden mb-3" (click)="toggleMainSideNav()">
    <img src="assets/images/menu.png" alt="">
  </button>
</header>
<section>
  <div class="side-menu py-3">
    <ul class="nav">
      <span *ngFor="let item of sideNav">
        <li class="cursor text-center" [ngClass]="{'pl-4': !isSmallNav}" *ngIf="!item.disabled"
          [routerLink]="item?.link" routerLinkActive="active">
          <div>
            <span>
              <img class="navicon" src="{{item.navIcon}}" />
              <img class="navicon-active" src="{{item.navIconActive}}" />
            </span>
            <span class="f-w-500">{{item?.title}}
            </span>
          </div>
          <!-- <span class="dot" *ngIf="!isSmallNav"></span> -->
        </li>

        <li class=" cursor text-center" [ngClass]="{'pl-4': !isSmallNav}" *ngIf="item.disabled">
          <div>
            <span>
              <img class="navicon" src="{{item.navIcon}}" />
              <img class="navicon-active" src="{{item.navIconActive}}" />
            </span>
            <span class="f-w-500">{{item?.title}}</span>
          </div>
        </li>
      </span>
    </ul>
  </div>
</section>