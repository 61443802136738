<div class="flex wrap">

    <font-13 >Items per page :&nbsp;</font-13>
    <div class="form-field mr-3" (click)="select.open()" >
        <mat-select #select (selectionChange)="setPage()" [(ngModel)]="pageSize">
            <mat-option *ngFor="let item of pageSizeOptions" [value]="item">{{item}}</mat-option>
          </mat-select>
          <div class="append" >
          <img src="assets/svg/chevron-down.svg"  alt="">
          </div>
    </div>

    <div class="mr-3" font-13 >
        {{((pageSize)*(pageNo-1))+1}} - {{(pageSize*pageNo) > totalRecords ? totalRecords :(pageSize*pageNo)}} of
        {{totalRecords}}
    </div>

    <button class="icon-btn" (click)="previousPages()" [disabled]="disable('previousPages')">
        <img src="assets/svg/chevrons-left.svg" alt="">
    </button>

    <button class="icon-btn" [ngClass]="pageNo === p ? 'active' : 'c-primary'"
        *ngFor="let p of pages | slice:showPages[0]:showPages[1]" (click)="directPage(p)">
        {{p}}
    </button>

    <button class="icon-btn" (click)="nextPages()" [disabled]="disable('nextPages')">
        <img src="assets/svg/chevrons-right.svg" alt="">
    </button>
</div>