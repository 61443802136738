import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Page } from 'src/app/http-handler/common/contracts/page';
import { Equipment, EquipmentManufacturer, EquipmentModel, AdminEquipment } from 'src/app/models/equipment.model';
import { Admin } from 'src/app/models/lead.model';
import { AppService } from 'src/app/services/app.service';
import { EquipmentService } from 'src/app/services/equipment.service';
import { UserService } from 'src/app/services/user.service';
import { ValidatationService } from 'src/app/shared/validator.service';

@Component({
  selector: 'app-add-edit-equipment',
  templateUrl: './add-edit-equipment.component.html',
  styleUrls: ['./add-edit-equipment.component.scss']
})
export class AddEditEquipmentComponent implements OnInit {

  form = new FormGroup({
    equipmentId: new FormControl('', [Validators.required]),
    equipmentManufacturerId: new FormControl('', [Validators.required]),
    equipmentModelId: new FormControl(''),
    equipmentManufacturerName: new FormControl('', [Validators.required]),
    equipmentModelName: new FormControl(''),
    equipmentName: new FormControl('', [Validators.required]),
    note: new FormControl('', [Validators.required, ValidatationService.spaceValidator]),
    adminId: new FormControl('', [Validators.required]),
  });;
  isLoading: boolean = false;


  equipments: Page<Equipment> = new Page({
    api: this._equipmentService.equipments,
    serverPaging: false,
  })

  equipmentManufacturers: Page<EquipmentManufacturer> = new Page({
    api: this._equipmentService.equipmentManufacturers,
    serverPaging: false,
    filters: [{
      field: 'equipmentId',
      value: null,
    }]
  })

  equipmentModels: Page<EquipmentModel> = new Page({
    api: this._equipmentService.equipmentModels,
    serverPaging: false,
    filters: [{
      field: 'equipmentId',
      value: null,
    },
    {
      field: 'equipmentManufacturerId',
      value: null,
    }]
  })

  constructor(
    private _equipmentService: EquipmentService,
    private _appService: AppService,
    private _userService: UserService,
    private _router: Router,
    @Inject(MAT_DIALOG_DATA) public data: { equipment?: AdminEquipment, admin: Admin },
    public dialogRef: MatDialogRef<AddEditEquipmentComponent>
  ) {

    this.resetForm();
    this.fetchEquipments();
    if (data.equipment) {
      this.fetchEquipmentManufacturers(data.equipment.equipmentId, false);
      this.fetchEquipmentModels(data.equipment.equipmentManufacturerId, false);
      _appService.formGroupSetValue(this.form, this.data.equipment);
    }

  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  fetchEquipments() {
    this.equipments.fetch().then(d => {

    }).catch(err => {
      this._appService.toastr.error(err);
    })
  }

  fetchEquipmentManufacturers(equipmentId: number, reset?: boolean) {
    this.equipmentManufacturers.filters.properties['equipmentId'].value = equipmentId;
    this.equipmentModels.filters.properties['equipmentId'].value = equipmentId;

    this.equipmentManufacturers.fetch().then(d => {
      if (reset) {
        this.form.get('equipmentManufacturerId').reset();
        this.form.get('equipmentModelId').reset();
        this.form.get('equipmentManufacturerName').reset();
        this.form.get('equipmentModelName').reset();
      }

    }).catch(err => {
      this._appService.toastr.error(err);
    })
  }

  fetchEquipmentModels(equipmentManufacturerId?: number, reset?: boolean) {
    this.equipmentModels.filters.properties['equipmentManufacturerId'].value = equipmentManufacturerId || '';
    this.equipmentModels.fetch().then(d => {
      if (reset) {
        this.form.get('equipmentModelId').reset();
        this.form.get('equipmentModelName').reset();
      }
      d.items.push({ id: '' as any, name: 'Other' });
    }).catch(err => {
      this._appService.toastr.error(err);
    })
  }


  async addEquipment() {
    this.isLoading = true;
    this._equipmentService.addEquipment(this.form.value).then(d => {
      this.dialogRef.close(d);
    }).catch(err => {
      this.isLoading = false;
    });
  }

  async updateEquipment() {
    this.isLoading = true;
    this._equipmentService.update(this.data.equipment.id, this.form.value).then(d => {
      this.dialogRef.close(d);
    }).catch(err => {
      this.isLoading = false;
    });
  }

  resetForm() {
    this.form.reset();
    this.form.get('adminId').setValue(this.data.admin.id);
    this.form.get('equipmentId').valueChanges.subscribe(d => {
      if (d) {
        const e = this.equipments.items.find(i => i.id === d);
        if (e) {
          this.form.get('equipmentName').setValue(e.name);
        }
      } else {
        this.form.get('equipmentName').reset();
      }
    });
    this.form.get('equipmentManufacturerId').valueChanges.subscribe(d => {
      if (d) {
        const e = this.equipmentManufacturers.items.find(i => i.id === d);
        if (e) {
          this.form.get('equipmentManufacturerName').setValue(e.name);
        }
      } else {
        this.form.get('equipmentManufacturerName').reset();
      }
    })

    this.form.get('equipmentModelId').valueChanges.subscribe(d => {
      if (d) {
        const e = this.equipmentModels.items.find(i => i.id === d);
        if (e) {
          this.form.get('equipmentModelName').setValue(e.name);
        }
      } else {
        this.form.get('equipmentModelName').reset('Other');
      }
    })
  }

}
