import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Task } from 'src/app/models/task.model';
import { AppService } from 'src/app/services/app.service';
import { TaskService } from 'src/app/services/task.service';
import * as moment from 'moment';
@Component({
  selector: 'task-selection-actions',
  templateUrl: './task-selection-actions.component.html',
  styleUrls: ['./task-selection-actions.component.scss']
})
export class TaskSelectionActionsComponent implements OnInit {

  @Input() selectedTasks: Task[] = [];
  @Output() onAction = new EventEmitter();
  @Output() clear = new EventEmitter();
  constructor(
    private _mutliTask: TaskService,
    private _appService: AppService,
    private toasterService: ToastrService
  ) { }

  ngOnInit() {
  }
  @HostBinding('class.show') get showBar() {
    return (!!this.selectedTasks?.length);
  }

  assignedDate;
  getAssignDate(e) {    
    this.assignedDate = moment(e.value).startOf('d').add(12).toISOString();
    this.upDateTask('dueDate')
  }
  
  assignedTo; 
  isLoading;
  upDateTask(e,assigned?) {
    if(!assigned && e == 'assign') return;
    if (this.isLoading) return;
    this.isLoading = true;
    let ids = this.selectedTasks.map(i => i.id)
    let body = {
      taskIds: ids,
      type: e,
      assignedTo: this.assignedTo,
      dueDate: this.assignedDate
    }

    let msg;
    switch (e) {
      case 'done':
        delete body.assignedTo;
        delete body.dueDate;
        msg = 'Tasks Mark Completed';
        break;
      case 'dueDate':
        delete body.assignedTo;
        msg = 'Task Due Date successfully changed to ' +moment(this.assignedDate).format('LL');
        break;
      case 'assign':
        delete body.dueDate;
        msg = 'Task successfully assigned to '+  assigned.displayName
        break;
      case 'delete':
        delete body.assignedTo;
        delete body.dueDate;
        msg = 'Tasks successfully deleted';
        break;
    }
    this._mutliTask.updateMultiTaks.create(body as any).then(d => {
      this.toasterService.success(msg)
      this.onAction.emit(null);
      this.isLoading = false;
      this.assignedTo = null;
    }).catch(err => {
      this.toasterService.error(err);
      this.isLoading = false;
    })

  }

}
