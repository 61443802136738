import { ContentChildren, Directive, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output, QueryList, Renderer2 } from '@angular/core';
import { SelectedItemDirective } from './selected-item.directive';

@Directive({
    selector: '[selection-list]',
    exportAs:'selectionList'
})
export class SelectedItemList {

    @ContentChildren(SelectedItemDirective) list:QueryList<SelectedItemDirective>;

    constructor() { }

    ngOnInit() { }

    get seletedItems(){
      return (this.list || [])?.filter(i => i.isSelected).map(i => i.itemData);
    }
     clearItem(){
        return this.list.forEach(i => i.isSelected = false) ; 
      }
  
    @HostListener('click', ['$event']) click(event) {
        // console.log('ctrl')
        if (!event.ctrlKey && this.seletedItems.length) {
            event.preventDefault();
            event.stopPropagation();
            this.list.forEach(i => i.isSelected = false)  
        }        
    }




}
