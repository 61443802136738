import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'SubLoc'
})
export class SubLocPipe implements PipeTransform {
  transform(loc: any, args?: any): any {
    if (args == 'locc') return this.SubLocation(loc);
  }
  SubLocation(loc: string) {
    if (loc === 'podcastRoom') {
      return "Podcast Room";
    }
    if (loc === 'greenRoom') {
        return "Green Room";
      }
      if (loc === 'whiteCove') {
        return "White Cove";
      }
      if (loc === 'blackCove') {
        return "Black Cove";
      }
      if (loc === 'greyCyclorama') {
        return "Grey Cyclorama";
      }
      if (loc === 'whiteCyclorama') {
        return "White Cyclorama";
      }   
     
  }
}