import { Component, EventEmitter, Input, OnInit, Output, forwardRef, SimpleChanges } from '@angular/core';
import { Page } from 'src/app/http-handler/common/contracts/page';
import { Admin } from 'src/app/models/lead.model';
import { UserService } from 'src/app/services/user.service';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-task-incharge',
  templateUrl: './task-incharge.component.html',
  styleUrls: ['./task-incharge.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TaskInchargeComponent),
      multi: true
    }
  ]
})
export class TaskInchargeComponent implements OnInit {

  teamMember: Page<Admin>;
  @Output('onSelect') select: EventEmitter<Admin> = new EventEmitter();

  @Input('uiType') type: "editable" | "input" | "icon" | "whiteIcon" = "editable";

  @Input() selected: Admin = {};

  @Input() filters: {};
  @Input() assignToMe: boolean;

  loading: boolean = false;

  onChange: any = () => { }
  onTouch: any = () => { }
  val: number;

  constructor(
    private _adminService: UserService,
    public appService: AppService
  ) {
    this.teamMember = new Page({
      api: _adminService.users,
      properties: new Admin(),
      pageSize: 20,
      filters: [{
        field: 'displayName',
        value: null
      }, {
        field: 'status',
        value: 'active'
      }, {
        field: 'accountId',
        value: null
      }, {
        field: 'filter',
        value: 'team'
      }]
    });

    this.onBtnClick();
    // this.fetch();
  }

  get value() {
    return this.val;
  }

  set value(val) {
    if (!val) {
      if (this.selected && this.val !== this.selected.id) this.selected = {};
    }
    this.val = val;
    this.onChange(val);
    this.onTouch(val);
  }

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn
  }

  ngOnInit() {

  }

  onBtnClick() {
    if (!this.teamMember.items?.length) {
      this.placeHolderLoading();
      this.teamMember.fetchPromise();
    }
  }

  ngOnChanges(c: SimpleChanges) {
    if (c['selected'] && c['selected'].firstChange && this.selected && this.selected.id) {
      this.value = this.selected.id;
    }
    // if(this.value && (!this.selected || !this.selected.id)){
    //   this.selectById(this.value);
    // }
  }

  search(n: string) {
    this.teamMember.filters.properties['displayName'].value = n;
    this.fetch();
  }

  async selectById(id: number) {
    const t: Admin = await this._adminService.users.get(id);
    if (t) {
      this.onSelect(t);
    }
  }

  onSelect(a?: Admin) {
    this.selected = { ...a };
    this.value = a ? a.id : null;
    this.select.emit(a || null);
  }

  placeHolderLoading() {
    this.teamMember.items = [new Admin(), new Admin(), new Admin(), new Admin(), new Admin()]
  }


  fetch() {
    if (!this.teamMember.items?.length) {
      this.placeHolderLoading();
    }
    this.teamMember.fetch().then(d => {

    }).catch(err => {
      this.teamMember.items = [];
    });
  }

  display(a: Admin) {
    if (a) {
      return a.displayName || "";
      // return a.firstName + ' ' + a.lastName;
    }
  }

}
