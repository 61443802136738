import { Component, OnInit, Input, TemplateRef, OnChanges, EventEmitter, Output } from '@angular/core';
import { Page } from 'src/app/http-handler/common/contracts/page';
import { Task } from 'src/app/models/task.model';
import { TaskService } from 'src/app/services/task.service';
import { AppService } from 'src/app/services/app.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatMenu } from '@angular/material/menu';
import { Admin, Lead } from 'src/app/models/lead.model';
import { NbDialogRef } from '@nebular/theme';
import { Deal } from 'src/app/models/deal.model';
import { Shoot } from 'src/app/models/shoots.model';
import { Department } from 'src/app/models/department.model';
import { Account } from 'src/app/models/accounts.model';
import { Contact } from 'src/app/models/contacts.model';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.css']
})
export class TasksComponent implements OnInit, OnChanges {

  tasks: Page<Task>;
  @Input() shadow = true;
  @Input() taskClass: string;
  @Input() updateList: boolean = true;
  @Input() taskType: string;

  @Input() dealId: number;
  @Input() leadId: number;
  @Input() shootId: number;
  @Input() accountId: number;
  @Input() contactId: number;
  @Input() teamDepartmentId: number;

  @Input() deal: Deal = new Deal();
  @Input() lead: Lead = new Lead();
  @Input() account: Account = new Account();
  @Input() contact: Contact = new Contact();
  @Input() shoot: Shoot = new Shoot();
  @Input() department: Department = new Department();

  dialogRef: NbDialogRef<any>;

  isCreating: boolean = false;

  constructor(
    private _TaskService: TaskService,
    private _CommenService: AppService
  ) {

    this.tasks = new Page({
      api: _TaskService.tasks,
      serverPaging: false,
      properties: new Task(),
      filters: [{
        field: 'dealId',
        value: null
      },
      {
        field: 'status',
        value: null
      }, {
        field: 'leadId',
        value: null
      }, {
        field: 'shootId',
        value: null
      }, {
        field: 'accountId',
        value: null
      }, {
        field: 'contactId',
        value: null
      }, {
        field: 'teamDepartmentId',
        value: null
      }]
    });

  }

  ngOnInit() {
    // this.tasks.items.splice(this.tasks.items.length, 0, new Task());
    if (this.leadId) {
      this.tasks.filters.properties['leadId'].value = this.leadId;
    }
    if (this.dealId) {
      this.tasks.filters.properties['dealId'].value = this.dealId;
    }
    if (this.shootId) {
      this.tasks.filters.properties['shootId'].value = this.shootId;
    }
    if (this.teamDepartmentId) {
      this.tasks.filters.properties['teamDepartmentId'].value = this.teamDepartmentId;
    }
    if(this.accountId){
      this.tasks.filters.properties['accountId'].value = this.accountId;
    }
    if(this.contactId){
      this.tasks.filters.properties['contactId'].value = this.contactId;
    }
    if (this.contactId || this.accountId ||this.leadId || this.dealId || this.shootId || this.teamDepartmentId) this.fetch();
  }

  ngOnChanges() {
    if (this.leadId) {
      this.tasks.filters.reset();
      this.deal = null;
      this.dealId = null;
      this.shoot = null;
      this.shootId = null;
      this.accountId = null;
      this.account = null;
      this.contactId = null;
      this.contact = null;
      this.teamDepartmentId = null;
      this.department = null;
      this.tasks.filters.properties['leadId'].value = this.leadId;
    }
    if (this.dealId) {
      this.lead = null;
      this.leadId = null;
      this.shoot = null;
      this.shootId = null;
      this.teamDepartmentId = null;
      this.department = null;
      this.accountId = null;
      this.account = null;
      this.contactId = null;
      this.contact = null;
      this.tasks.filters.reset();
      this.tasks.filters.properties['dealId'].value = this.dealId;
    }
    if (this.shootId) {
      this.lead = null;
      this.leadId = null;
      this.deal = null;
      this.dealId = null;
      this.teamDepartmentId = null;
      this.department = null;
      this.accountId = null;
      this.account = null;
      this.contactId = null;
      this.contact = null;
      this.tasks.filters.reset();
      this.tasks.filters.properties['shootId'].value = this.shootId;
    }
    if (this.accountId) {
      this.shoot = null;
      this.shootId = null;
      this.contactId = null;
      this.contact = null;
      this.lead = null;
      this.leadId = null;
      this.deal = null;
      this.dealId = null;
      this.teamDepartmentId = null;
      this.department = null;
      this.tasks.filters.reset();
      this.tasks.filters.properties['accountId'].value = this.accountId;
    }
    if (this.contactId) {
      this.shoot = null;
      this.shootId = null;
      this.accountId = null;
      this.account = null;
      this.lead = null;
      this.leadId = null;
      this.deal = null;
      this.dealId = null;
      this.teamDepartmentId = null;
      this.department = null;
      this.tasks.filters.reset();
      this.tasks.filters.properties['contactId'].value = this.contactId;
    }
    if (this.teamDepartmentId) {
      this.accountId = null;
      this.account = null;
      this.contactId = null;
      this.contact = null;
      this.lead = null;
      this.leadId = null;
      this.deal = null;
      this.dealId = null;
      this.shoot = null;
      this.shootId = null;
      this.tasks.filters.properties['teamDepartmentId'].value = this.teamDepartmentId;
    }
    if (!this.contactId && !this.accountId &&!this.leadId && !this.dealId && !this.shootId && !this.teamDepartmentId) {
      this.tasks.items = [];
      this.addNewTask();
    }
  }


  fetch() {
    if (!this.updateList) return;
    this.tasks.fetch().then(d => {
      if (!d.items.length) {
        this.addNewTask();
      }
    }).catch(err => {
      this._CommenService.toastr.error(err);
    })
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.tasks.items, event.previousIndex, event.currentIndex);
    let p = 1;
    let positions = this.tasks.items.filter(i => i.id).map(i => { return { taskId: i.id, position: p++ } });
    this.updatePositions({ positions });
  }

  addNewTask() {
    if (this.tasks.items.length && !this.tasks.items[this.tasks.items.length - 1].id) return;
    this.tasks.items.splice(this.tasks.items.length, 0, new Task());
  }


  change(task: Task, index: number,successMessage?:string){
    if (task.id) {
    return  this.update(task, index,successMessage);
    } else {
    return  this.create(task, index,successMessage);
    }
  }

  
  error(value) {
    this._CommenService.toastr.error(value)
  }
  success(value) {
    this._CommenService.toastr.success(value)
  }

  statusChg(task: Task) {
    if (task.isCompleted) {
      task.status = 'finished';
    }
    else {
      task.status = 'started';
    }
    this._TaskService.tasks.update(task.id, { status: task.status , isCompleted:task.isCompleted } as any).then(() => {
      task.btnDisable = false;
      this.success('Updated Successfully');
      this.fetch();
    }).catch((err) => {
      this.fetch();
      this.error(err);
      task.btnDisable = false;
    })
  }
  assignToMe(t: Task, index: number) {
    t.adminId = JSON.parse(window.localStorage.getItem('user')).id;
    t.assignedTo = JSON.parse(window.localStorage.getItem('user')).id;
    this.change(t, index,"Task successfully assigned to you");
  }

  detail(temp: TemplateRef<any>) {
    this.dialogRef = this._CommenService.dialogService.open(temp);
  }

  assign(a: Admin, t: Task, index: number) {
    t.adminId = JSON.parse(window.localStorage.getItem('user')).id;
    t.assigned = { ...a };
    t.assignedTo = a.id;
    this.change(t, index,`Task successfully assigned to ${a.displayName}`);
  }

  create(task: Task, index?: number,successMessage?:string) {
    // //console.log(this.dealId);
    if (!this.accountId && !this.contactId && !this.dealId && !this.leadId && !this.shootId && !this.teamDepartmentId && this.taskType) return this._CommenService.toastr.error(`require field ${this.taskType == 'operation' ? 'department' : this.taskType}`)
    if (task.btnDisable) return;
    task.btnDisable = true;
    task.dealId = this.dealId;
    task.leadId = this.leadId;
    task.shootId = this.shootId;
    task.accountId = this.accountId;
    task.contactId = this.contactId;
    task.teamDepartmentId = this.teamDepartmentId;
    task.type = this.accountId ? 'account' :  this.contact ? 'contact' :  this.leadId ? 'lead' : this.dealId ? 'deal' : this.shootId ? 'shoot' : 'operation';

  return this._TaskService.tasks.create(task).then(async d => {
    if(successMessage) this._CommenService.toastr.success(successMessage);
      this.tasks.items[index] = await this._TaskService.tasks.get(d.id);
      task.btnDisable = false;
    }).catch(err => {
      task.btnDisable = false;
      this._CommenService.toastr.error(err);
    })
  }

  update(task: Task, index?: number,successMessage?:string) {
    task.dealId = this.dealId;
    task.leadId = this.leadId;
    task.shootId = this.shootId;
    task.teamDepartmentId = this.teamDepartmentId;
    task.type = this.accountId ? 'account' :  this.contact ? 'contact' : this.leadId ? 'lead' : this.dealId ? 'deal' : this.shootId ? 'shoot' : 'operation';
   return this._TaskService.tasks.update(task.id, task).then(async d => {
    if(successMessage) this._CommenService.toastr.success(successMessage);
      this.tasks.items[index] = await this._TaskService.tasks.get(d.id);
    }).catch(err => {
      this.fetch();
      this._CommenService.toastr.error(err);
    })
  }

  updatePositions(body) {
    this._TaskService.tasks.create(body, 'positions').then(d => {
      this.fetch();
    }).catch(err => {
      this.fetch();
      this._CommenService.toastr.error(err);
    })
  }

}
