import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { FontsModule } from '../../directives/fonts/fonts';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.css']
})
export class PaginatorComponent implements OnInit, OnChanges {
  pages: number[] = [1];
  showPages: number[] = [0, 6];
  totalPages: number = 0;
  intervel: any;
  @Input() pageSizeOptions: number[] = [5, 10, 25, 50, 75, 100];
  @Input() pageNo: number = 1;
  @Input() totalRecords: number = 0;
  @Input() pageSize: number = 50;
  @Input() isLoading: boolean;

  @Output() page: EventEmitter<any> = new EventEmitter();
  @Output() pageSizeChange: EventEmitter<number> = new EventEmitter();
  @Output() pageNoChange: EventEmitter<number> = new EventEmitter();

  constructor() { }

  ngOnChanges(change: SimpleChanges) {
    this.setPagesCount();
  }

  ngOnInit() {
    if (!this.pageSize) this.pageSize = 50;
    this.totalPages = parseInt(`${this.totalRecords / this.pageSize}`);
  }

  next() {
    if (this.disable('next')) return;
    this.pageNo++;
    this.setPage();
  }

  nextPages() {
    if (this.disable('nextPages')) return;
    this.showPages[0] = this.showPages[0] + 6;
    this.showPages[1] = this.showPages[1] + 6;
  }

  previous() {
    if (this.disable('previous')) return;
    this.pageNo--;
    this.setPage();
  }

  directPage(p) {
    this.pageNo = p;
    this.setPage();
  }

  previousPages() {
    if (this.disable('previousPages')) return;
    this.showPages[0] = this.showPages[0] - 6;
    this.showPages[1] = this.showPages[1] - 6;
  }

  setPagesCount() {
    this.totalPages = parseInt(`${this.totalRecords / this.pageSize}`);
    if (this.totalPages < (this.totalRecords / this.pageSize)) {
      this.totalPages++;
    }
    if (this.totalRecords > this.pageSize) {
      this.totalPages = this.totalRecords / this.pageSize;
    }
    this.setViewPages();
  }

  setViewPages() {
    this.totalPages = (JSON.stringify(this.totalPages).split('.').length >= 2) ? parseInt(this.totalPages as any) + 1 : this.totalPages;
    this.pages = [];
    for (let i = 1; i <= this.totalPages; i++) {
      this.pages.push(i);
    }
    if (!this.pages.length) this.pages.push(1);
  }

  setPage() {
    this.pageNoChange.emit(this.pageNo);
    this.pageSizeChange.emit(this.pageSize);
    this.page.emit();
  }

  disable(type) {
    switch (type) {
      case "next":
        return (this.isLoading || !this.pages.length || this.pageNo === this.totalPages)
      case "nextPages":
        return (this.isLoading || !this.pages.length || this.showPages[1] >= this.totalPages)
      case "previous":
        return (this.isLoading || !this.pages.length || this.pageNo == 1)
      case "previousPages":
        return (this.isLoading || !this.pages.length || this.showPages[0] == 0)
    }
  }
}

@NgModule({
  declarations: [PaginatorComponent],
  exports: [PaginatorComponent],
  imports: [FontsModule, MatSelectModule, FormsModule,CommonModule]
})
export class PagingModule { }
