import { Injectable } from '@angular/core';
import { IApi } from '../http-handler/common/contracts/api';
import { HttpClient } from '@angular/common/http';
import { GenericApi } from '../http-handler/common/generic-api';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  private _badgeCountSubject = new BehaviorSubject<number>(0);
  badgeCount$ = this._badgeCountSubject.asObservable();

  notification: IApi<any>;
  constructor(http: HttpClient) {
    this.notification = new GenericApi<any>('notifications', http);
  }

  async deleteAll() {
    await this.notification.create({}, 'deleteAll');
  }

  async updateAll() {
    await this.notification.create({ status: 'inactive' }, 'updateAll');
  }

  incrementBadgeCount() {
    const currentCount = this._badgeCountSubject.value;
    this._badgeCountSubject.next(currentCount + 1);
  }

  resetBadgeCount() {
    this._badgeCountSubject.next(0);
  }

  setbadgeCount(count: any) {
    this._badgeCountSubject.next(count);
  }
}
