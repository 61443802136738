import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { State } from 'src/app/models/state.model';
import { Page } from 'src/app/http-handler/common/contracts/page';
import { StateService } from 'src/app/services/state.service';
import { AppService } from 'src/app/services/app.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-select-state',
  templateUrl: './select-state.component.html',
  styleUrls: ['./select-state.component.css']
})
export class SelectStateComponent implements OnInit {

  states: Page<State>;
  @Input() control: FormControl;
  @Input() id: any;
  @Output() idChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private stateService: StateService,
    private appService: AppService
  ) {

    this.states = new Page({
      api: stateService.states,
      properties: new State(),
      serverPaging: false
    });

    this.fetch();

  }

  ngOnInit() {
  }

  fetch() {
    this.states.fetch().then(d => {

    }).catch(err => {
    })
  }

}
