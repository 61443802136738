import { Injectable } from '@angular/core';
import { CourseInterest } from '../models/courseInterest.model';
import { IApi } from '../http-handler/common/contracts/api';
import { HttpClient } from '@angular/common/http';
import { GenericApi } from '../http-handler/common/generic-api';

@Injectable({
  providedIn: 'root'
})
export class CourseInterestService {


  courseInterests: IApi<CourseInterest>;
  adminInterests: IApi<CourseInterest>;

  constructor(
    http: HttpClient
  ) {

    this.courseInterests = new GenericApi<CourseInterest>('courseInterests', http);
    this.adminInterests = new GenericApi<CourseInterest>('adminInterests', http);


  }

  // mapChildrenIds(item: CourseInterest) {
  //   let ids = [item.id];
  //   if (item?.children?.length) {
  //     item?.children.forEach(i => {
  //       ids.push(i.id);
  //       ids = [...ids, ...this.mapChildrenIds(i)];
  //     })
  //   }
  //   return ids;
  // }

  mapParents(ary: CourseInterest[]) {
    ary.filter(i => !i.sourceId).forEach((item, index) => this.renderChildrens(item, ary, item.name));
  }

  renderChildrens(item: CourseInterest, ary: CourseInterest[], mainParentName?: string) {
    if (mainParentName && item.sourceId) {
      item.mainParentName = mainParentName;
    }
    if (!item?.children?.length) item.children = ary.filter(i => i.sourceId === item.id);
    item?.children.forEach((i,) => {
      this.renderChildrens(i, ary, mainParentName);
    })
  }

}
