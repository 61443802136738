<div class="row m-0" id="message-container" [@msgAnimation]="messages.length">
    <div>
        <span *ngIf="messages.length > 1" class="float-right clear" (click)="removeAll()">Clear</span>
    </div>
    <div id="msg" class="m-0 rounded-0 mb-3 message-outer m-auto py-1" [ngClass]="messages.length == 0 ? '':'visible'"
        [ngStyle]="{'background':msg.bg}" *ngFor="let msg of messages">
        <div class="px-2" [ngStyle]="{'color':msg.color}">
            <img class="mr-1" *ngIf="msg.sucess" height="17px" width="17px"
                src="../../../assets/images/icons8-checkmark-60.png" alt="">
            <img class="mr-1" *ngIf="!msg.sucess" height="17px" width="17px"
                src="../../../assets/images/icons8-box-important-60.png" alt="">
            <!-- <span *ngIf="msg.sucess" class="h4 message font-17">
                Success
            </span>
            <span *ngIf="!msg.sucess" class="h4 message font-17">
                Failed
            </span> -->
            <span class="icon font-13 font-weight-light text-capitalize">
                {{msg.msg}}
            </span>
            <button mat-icon-button class="outline-0 float-right ml-1 rounded-0 l-h-n" style="height:17px;width:17px;margin-top: 3px;" (click)="removeAll()">
                    <img height="13px" width="13px"
                    src="../../../assets/images/icons8-delete-24.png" alt="">
            </button>
        </div>
        <!-- <div class="w-100 px-2">
            
        </div> -->
    </div>
</div>