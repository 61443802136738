<div class="d-inline-block">
  <div class="pt-3 pr-0 pl-3" *ngFor="let item of uploader?.queue" >
    <div class="file-item progress" [class.text-white]="item.isUploaded" >
      <div class="progress-bar" [style.width]="item.progress+'%'" >
      </div>
      <span>{{(item._file?.name.length > 25 ? (item._file?.name | slice:0:25)+'..' : item._file?.name) || 'N/a'}}</span>
      <button mat-icon-button (click)="cancel(item)" >
          <mat-icon>clear</mat-icon>
      </button>
    </div>
</div>

</div>