import { Deal } from './deal.model';
import { Shoot } from './shoots.model';

export class Payment {
    id?: number;
    note?: string;
    date?: string;
    amount?: number;
    currency?: string;
    paymentStyle?: string;
    paymentMethod?: string;
    type?: string;
    dealId?: number;
    shootId?: number;
    deal?: Deal = new Deal();
    shoot?: Shoot = new Shoot();
    btnDisable?: boolean;
    paymentStage?: string;
}

export class DealPayment {
    id?: number;
    title?: string;
    date?: string;
    amount?: number;
    currency?: string;
    paymentStyle?: string;
    paymentMethod?: string;
    paymentStage?: string;
    dealId?: number;
    deal?: Deal = new Deal();
    shootId?: number;
}