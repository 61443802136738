import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';

@Component({
    selector: 'router-drawer-container',
    template: `
        <mat-drawer-container (backdropClick)="backdropClick()" >
  <mat-drawer disableClose #drawer="matDrawer" mode="over" position="end">    
    <router-outlet></router-outlet>
  </mat-drawer>
  <mat-drawer-content>
    <ng-content></ng-content>
  </mat-drawer-content>
</mat-drawer-container>
    `,
    styles:[`
        mat-drawer-container{
            background:transparent !important;
        }
    `]
})

export class RouterDrawerContainerComponent implements OnInit, AfterViewInit {

    @ViewChild('drawer') drawer: MatDrawer | any;

    constructor(
        private _router: Router,
        private _activatedRoute: ActivatedRoute
    ) { }

    ngOnInit() { }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this._activatedRoute.snapshot.firstChild?.data['isInDrawer'] && this.drawer) {
                this.drawer.open();
            }
        });
        this._router.events.pipe(filter(event => event instanceof NavigationEnd), map(d => {
            const child = this._activatedRoute.firstChild;
            if (child?.snapshot.data) {
                return child.snapshot?.data;
            } else {
                return {}
            }
        })).subscribe(d => {
            if (d['isInDrawer'] && this.drawer) {
                this.drawer.open();
            } else {
                this.drawer.close();
            }
        });
    }

    backdropClick() {
        const routes = this._activatedRoute.snapshot.routeConfig.children.filter(i => i.data['isInDrawer']).map(i => {
            let path = i.path;
            Object.keys(this._activatedRoute.snapshot.firstChild.params).forEach(k => {
                path = path.replace(`:${k}`,this._activatedRoute.snapshot.firstChild.params[k])
            })
            return path;
        });
        var currentUrl = this._router.url;
        const activeRoute = routes.find(i => currentUrl.includes(i));
        if(activeRoute){
            this._router.navigateByUrl(currentUrl.replace(`/${activeRoute}`,''))
        }else{
            this.drawer.close();
        }
        // this._router.navigateByUrl(`/pages/${this._activatedRoute.snapshot.routeConfig?.path}`);
    }
}