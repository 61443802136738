<nb-layout>
    <nb-layout-column class="p-0">
        <mat-drawer-container *ngIf="appService?.user?.roleType === 'admin' || appService?.role === 'admin'">

            <mat-drawer class="main-drawer" #drawer opened mode="over" disableClose mode="side">
                <app-main-side-nav #sideNav></app-main-side-nav>
            </mat-drawer>

            <mat-drawer-content [style.margin-left]="sideNav?.isSmallNav ? '70px' : '190px'">
                <nb-layout-header fixed style="background-color: #012c49;">
                    <!-- <app-header ></app-header> -->
                    <div class="ml-auto btn-links">
                        <!-- admin-tabs -->
                        <button *ngFor="let l of tabs" [hidden]="l.hidden || appService?.role !== 'admin' || '' "
                            class="link-btn" (click)="navigate(l?.title);sideNav?.close()" [routerLink]="[l.link]"
                            [routerLinkActive]="'active'">
                            <img class="icon white" [src]="'assets/header-svgs/White/'+l.icon">
                            <img class="icon blue" [src]="'assets/header-svgs/Blue/'+l.icon"> <br>
                            <a>{{l?.title}}</a>
                        </button>

                        <button *ngIf="appService.role === 'admin'" class="link-btn" (click)="app()"
                            style="background-color: #fe4564;">
                            <img class="imagg" src="assets/header-svgs/Forma 1.svg">
                        </button>

                        <div class="ml-1 mr-2 cursor" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="notifications"
                            (click)="onBellClick()">
                            <mat-icon [matBadge]="notificationService.badgeCount$ | async" matBadgeColor="warn"
                                [ngStyle]="{'color':'white'}">notifications</mat-icon>
                            <span class="cdk-visually-hidden">
                                Notification Badge
                            </span>
                        </div>
                        <mat-menu #notifications="matMenu">
                            <div class="d-flex justify-content-between align-items-center py-3 px-2">
                                <span class="notification-header">Notifications</span>
                                <img src="assets/svg/Group 243.svg" *ngIf="not?.length" class="
                                    mr-2" (click)="deleteAllNotitications($event)" />
                            </div>
                            <div class="notification-container">
                                <ng-container *ngIf="!loading">
                                    <div class="notification-item" *ngFor="let item of not">
                                        <div class="d-flex flex-column cursor" (click)="goToDetail(item)">
                                            <font-12 color="black" weight="M">{{item?.title}}</font-12>
                                            <span>{{item?.description}}</span>
                                            <div class="mt-1">
                                              <font-11> {{item?.createdAt | date: 'M/d/yy, h:mm a'}} </font-11>  
                                            </div>
                                        </div>
                                        <div>
                                            <img src="assets/images/delete-grey.svg"
                                                (click)="deleteOneNotitication($event,item?.id)" class="delete-icon" />
                                               
                                        </div>
                                    </div>
                                    <div *ngIf="!not?.length"
                                        class="d-flex align-items-center justify-content-center h-100 w-100">
                                        <font-16 color="black" weight="M">No notification found.</font-16>
                                    </div>
                                </ng-container>
                                <div class="w-100 h-100 d-flex justify-content-center align-items-center"
                                    *ngIf="loading">
                                    <mat-progress-spinner [mode]="'indeterminate'" [strokeWidth]="1.5" [diameter]="25">
                                    </mat-progress-spinner>
                                </div>
                            </div>

                        </mat-menu>

                        <div class="profile">
                            <logo [matMenuTriggerFor]="menu" [src]="appService?.user?.imgUrl" [diameter]="40.5"
                                [title]="appService?.user?.displayName"></logo>
                            <mat-icon [matMenuTriggerFor]="menu" [inline]="true">arrow_drop_down</mat-icon>
                            <mat-menu #menu>
                                <mat-list class="p-0">
                                    <mat-list-item font-14 matRipple
                                        [routerLink]="'/pages/my-account'">Profile</mat-list-item>
                                    <mat-list-item font-14 matRipple
                                        [routerLink]="'/pages/template'">Templates</mat-list-item>
                                    <mat-list-item font-14 matRipple [routerLink]="'/admin-console'">Admin
                                        Console</mat-list-item>
                                    <!-- <mat-list-item font-14 matRipple [routerLink]="'/pages/goals'" >Goals</mat-list-item> -->
                                    <mat-list-item font-14 matRipple class="c-white" (click)="logOut()">Logout
                                        <mat-icon class="ml-auto">login</mat-icon>
                                    </mat-list-item>
                                </mat-list>
                            </mat-menu>
                            <div class="dropdown-menu fade-down bottom-left mt-2">

                            </div>
                        </div>
                    </div>
                </nb-layout-header>

                <div class="screen" *ngIf="appService.user?.roleType === 'admin'">
                    <router-outlet></router-outlet>
                </div>
                <div class="d-flex mt-5 pt-5" *ngIf="appService.user?.roleType !== 'admin'">

                    <nb-card class="m-auto text-center p-5">
                        <nb-card-body>

                            <h3 class="font-weight-light">
                                Welcome to Earthvirtual. <br>
                                Coming Soon!
                            </h3>

                        </nb-card-body>
                    </nb-card>
                </div>
            </mat-drawer-content>

            <mat-drawer #drawer1 opened mode="right" disableClose>
                <app-main-side-nav></app-main-side-nav>

            </mat-drawer>

        </mat-drawer-container>



        <!-- project manager router -->
        <mat-drawer-container
            *ngIf="appService?.user?.roleType === 'projectManager' || appService?.user?.role?.name === 'projectManager'">
            <mat-drawer-content>
                <nb-layout-header fixed style="background-color: #012c49;">
                    <!-- <app-header ></app-header> -->
                    <div>
                        <img routerLink="/dashboard/business" class="mainlogo" src="../../assets/images/Top Logo.png"
                            alt="">
                        <font-14 class="ml-3" weight="R" color="white">EARTH VIRTUAL </font-14>
                    </div>

                    <div class="ml-auto btn-links">
                        <!-- Project Manager tabs -->
                        <button *ngFor="let l of projectManagerTabs" [hidden]="l.hidden || '' " class="link-btn"
                            (click)="navigate(l?.title);sideNav?.close()" [routerLink]="[l.link]"
                            [routerLinkActive]="'active'">
                            <img class="icon white" [src]="'assets/header-svgs/White/'+l.icon">
                            <img class="icon blue" [src]="'assets/header-svgs/Blue/'+l.icon"> <br>
                            <a>{{l?.title}}</a>
                        </button>
                        <button class="link-btn" (click)="app()" style="background-color: #fe4564;">
                            <img class="imagg" src="assets/header-svgs/Forma 1.svg">
                        </button>

                        <div class="profile">
                            <logo [matMenuTriggerFor]="menu" [src]="appService?.user?.imgUrl" [diameter]="40.5"
                                [title]="appService?.user?.displayName"></logo>
                            <mat-icon [matMenuTriggerFor]="menu" [inline]="true">arrow_drop_down</mat-icon>
                            <mat-menu #menu>
                                <mat-list class="p-0">
                                    <mat-list-item font-14 matRipple
                                        [routerLink]="'/pages/my-account'">Profile</mat-list-item>
                                    <!-- <mat-list-item font-14 matRipple [routerLink]="'/pages/goals'" >Goals</mat-list-item> -->
                                    <mat-list-item font-14 matRipple class="c-white" (click)="logOut()">Logout
                                        <mat-icon class="ml-auto">login</mat-icon>
                                    </mat-list-item>
                                </mat-list>
                            </mat-menu>
                            <div class="dropdown-menu fade-down bottom-left mt-2">
                            </div>
                        </div>
                    </div>
                </nb-layout-header>

                <div class="screen">
                    <router-outlet></router-outlet>
                </div>
                <div class="d-flex mt-5 pt-5"
                    *ngIf="appService?.user?.roleType !== 'projectManager' || appService?.user?.role?.name !== 'projectManager'">
                    <nb-card class="m-auto text-center p-5">
                        <nb-card-body>
                            <h3 class="font-weight-light">
                                Welcome to Earthvirtual. <br>
                                Coming Soon!
                            </h3>
                        </nb-card-body>
                    </nb-card>
                </div>
            </mat-drawer-content>
        </mat-drawer-container>


        <!-- sale manager route -->

        <mat-drawer-container
            *ngIf="appService?.user?.roleType === 'saleManager' || appService?.user?.role?.name === 'saleManager'">
            <mat-drawer-content>
                <nb-layout-header fixed style="background-color: #012c49;">
                    <div>
                        <img routerLink="/dashboard/business" class="mainlogo" src="../../assets/images/Top Logo.png"
                            alt="">
                        <font-14 class="ml-3" weight="R" color="white">EARTH VIRTUAL </font-14>
                    </div>
                    <!-- <app-header ></app-header> -->
                    <div class="ml-auto btn-links">
                        <!-- Project Manager tabs -->
                        <button *ngFor="let l of saleManagerTabs" [hidden]="l.hidden || '' " class="link-btn"
                            (click)="navigate(l?.title);sideNav?.close()" [routerLink]="[l.link]"
                            [routerLinkActive]="'active'">
                            <img class="icon white" [src]="'assets/header-svgs/White/'+l.icon">
                            <img class="icon blue" [src]="'assets/header-svgs/Blue/'+l.icon"> <br>
                            <a>{{l?.title}}</a>
                        </button>
                        <button class="link-btn" (click)="app()" style="background-color: #fe4564;">
                            <img class="imagg" src="assets/header-svgs/Forma 1.svg">
                        </button>

                        <div class="profile">
                            <logo [matMenuTriggerFor]="menu" [src]="appService?.user?.imgUrl" [diameter]="40.5"
                                [title]="appService?.user?.displayName"></logo>
                            <mat-icon [matMenuTriggerFor]="menu" [inline]="true">arrow_drop_down</mat-icon>
                            <mat-menu #menu>
                                <mat-list class="p-0">
                                    <mat-list-item font-14 matRipple
                                        [routerLink]="'/pages/my-account'">Profile</mat-list-item>
                                    <!-- <mat-list-item font-14 matRipple [routerLink]="'/pages/goals'" >Goals</mat-list-item> -->
                                    <mat-list-item font-14 matRipple class="c-white" (click)="logOut()">Logout
                                        <mat-icon class="ml-auto">login</mat-icon>
                                    </mat-list-item>
                                </mat-list>
                            </mat-menu>
                            <div class="dropdown-menu fade-down bottom-left mt-2">
                            </div>
                        </div>
                    </div>
                </nb-layout-header>

                <div class="screen">
                    <router-outlet></router-outlet>
                </div>
                <div class="d-flex mt-5 pt-5"
                    *ngIf="appService?.user?.roleType !== 'saleManager' || appService?.user?.role?.name !== 'saleManager' ">

                    <nb-card class="m-auto text-center p-5">
                        <nb-card-body>

                            <h3 class="font-weight-light">
                                Welcome to Earthvirtual. <br>
                                Coming Soon!
                            </h3>

                        </nb-card-body>
                    </nb-card>
                </div>
            </mat-drawer-content>
        </mat-drawer-container>



    </nb-layout-column>
</nb-layout>