import { Component, OnChanges, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Page } from 'src/app/http-handler/common/contracts/page';
import { Chat } from 'src/app/models/chat.model';
import { AppService } from 'src/app/services/app.service';
import { ChatService } from '../../../../services/chat.service';
import { SupportFirebaseService } from '../../../../services/support-service/support-firebase.service';
import { StartNewChatComponent } from './start-new-chat/start-new-chat.component';
@Component({
  selector: 'app-chat-bot',
  templateUrl: './chat-bot.component.html',
  styleUrls: ['./chat-bot.component.scss']
})
export class ChatBotComponent implements OnInit {
  chatList: Page<Chat>;
  userId: number;
  selectedChat: Chat;
  constructor(
    private chatService: ChatService,
    public appservice: AppService,
    public activeRoute: ActivatedRoute,
    private supportChatService: SupportFirebaseService,
    public router: Router,
    private matDialog: MatDialog
  ) {

    this.chatList = new Page({
      api: chatService.chats,
      filters: [{ field: 'search', value: '' }]
    });
    this.userId = activeRoute.snapshot.params.userId;
    if (this.userId) {
      this.createChat()
    }
  }

  url?: string;
  ngOnInit() {
    for (let i = 0; i < 5; i++) {
      this.filterChatList.push(new Chat())
    }
    this.getChatList(true);
    if (this.userId) {
      let index = new String(this.router.url).indexOf(`${this.userId}`);
      this.url = this.router.url.slice(0, index);
    } else {
      this.url = this.router.url;
    }
  }

  onChange(d) {
    if (d) {
      this.selectedChat = d;
      return
    }
    this.userId = this.activeRoute.snapshot.params.userId;
    if (this.activeRoute.snapshot.params.userId) {
      this.createChat()
    }
    if (this.userId) {
      let index = new String(this.router.url).indexOf(`${this.userId}`);
      this.url = this.router.url.slice(0, index);
    } else {
      this.url = this.router.url;
    }
    this.chatService.countSubscription.subscribe(d => {
      this.getChatList();
    })
  }

  isLoading: boolean;
  getChatList(lod?) {
    if (lod) {
      this.isLoading = true
    }
    this.chatList.fetch().then(d => {
      this.filterChatList = d?.items;
      this.isLoading = false;
      if (!this.userId && d?.items?.length) {
        if (this.appservice?.user?.id == d.items[0]?.user1Id) {
          this.router.navigate([`${this.router.url}/${d.items[0]?.user2Id}`])
        } else {
          this.router.navigate([`${this.router.url}/${d.items[0]?.user1Id}`])
        }
      }
    }).catch(err => {
      this.appservice.toastr.error(err);
      this.isLoading = false;
    })
  }

  createChat() {
    let body: any = {};
    if (this.activeRoute.snapshot.queryParams.applicationId) {
      body.jobApplicationId = this.activeRoute.snapshot.queryParams.applicationId;
    }
    if (!this.activeRoute.snapshot.params.userId) return;
    body.user2Id = this.activeRoute.snapshot.params.userId;
    this.chatService.chats.create(body).then(d => {

      this.selectedChat = d;
      this.getChatList();
    }).catch(err => {
      this.appservice.toastr.error(err);
    })
  }


  openNewChat() {
    this.matDialog.open(StartNewChatComponent, {
      width: '400px'
    }).afterClosed().subscribe(d => {
      if (d) {
        this.getChatList();
        this.selectedChat = d;
        this.router.navigate([`${this.url}/${d.user2Id}`])
      }
    })
  }

  filterChatList: Chat[] = [];
  search(e) {
    let val = e.target.value;
    let selectedArr;
    this.filterChatList = this.chatList.items.filter(d => d?.user2?.displayName.toLocaleLowerCase().includes(val) || d?.user1?.displayName.toLocaleLowerCase().includes(val));
  }

}
