import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ShootService } from 'src/app/services/shoot.service';
import { AppService } from 'src/app/services/app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Page } from 'src/app/http-handler/common/contracts/page';
import { Shoot } from 'src/app/models/shoots.model';
import { Lead } from 'src/app/models/lead.model';
import { Deal } from 'src/app/models/deal.model';

@Component({
  selector: 'app-shoots',
  templateUrl: './shoot-list.component.html',
  styleUrls: ['./shoot-list.component.css']
})
export class ShootListComponent implements OnInit , OnChanges {

  shoots: Page<Shoot>;

  @Input() dealId: number;
  @Input() leadId: number;
  @Input() lead: Lead;
  @Input() deal: Deal;
  colorPosition: number = 0;

  addQuereParams: any = { lead: null, deal: null };

  constructor(
    private _shootService: ShootService,
    private _CommenService: AppService,
    private _Router: Router,
    private _ActivatedRoute: ActivatedRoute
  ) {

    this.shoots = new Page({
      api: _shootService.shoots,
      properties: new Shoot(),
      filters: [{
        field: 'dealId',
        value: null
      }, {
        field: 'leadId',
        value: null
      }]
    });

  }

  ngOnInit() {
    
  }

  ngOnChanges(){
    if (this.lead) this.addQuereParams.lead = JSON.stringify(this.lead);
    if (this.deal) this.addQuereParams.deal = JSON.stringify(this.deal);
    // this.addQuereParams = {
    //   lead: JSON.stringify(this.lead),
    //   deal: JSON.stringify(this.deal),
    // }

    if (this.leadId) {
      this.shoots.filters.properties['leadId'].value = this.leadId;
    }
    if (this.dealId) {
      this.shoots.filters.properties['dealId'].value = this.dealId;
    }
    this.fetch();
  }



  async fetch() {
    await this.shoots.fetch().then(d => {
      d.items.forEach(i => this.setColor(i));
    }).catch(err => {
      this._CommenService.toastr.error(err);
    })
  }


  setColor(s: Shoot) {
    this.colorPosition++;
    if (this.colorPosition == 8) {
      this.colorPosition = 1;
    }
    s.color = this.color(this.colorPosition);
  }


  color(p) {
    switch (p) {
      case 1:
        return '#10c3c0';
      case 2:
        return '#ff9f52';
      case 3:
        return '#279cfc';
      case 4:
        return '#ed6370';
      case 5:
        return 'rgb(78, 152, 13)';
      case 6:
        return 'rgb(248, 138, 31)';
      case 7:
        return 'rgb(15, 99, 253)';
    }
  }

}
