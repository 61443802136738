import { Injectable } from '@angular/core';
import { IApi } from '../http-handler/common/contracts/api';
import { HttpClient } from '@angular/common/http';
import { GenericApi } from '../http-handler/common/generic-api';
import { User } from '../models/user.model';
import { AppService } from './app.service';

@Injectable({ 
    providedIn: 'root'
})

export class UserService {
    users: IApi<User>;

    constructor(http: HttpClient,private _appService: AppService) {
        this.users = new GenericApi<User>('admins', http);
    }

    signin(data) {
        return this.users.create(data, 'signin')
    }

    createExternalResource(data) {
        return this.users.create(data, '/external/resource').then(d => {
            return d;
        }).catch(err => {
            return Promise.reject(err);
        });
    }

    currentUser() {
        return new Promise(resolve => {
            let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
            if (user) {
                return this.users.get(user.id).then(res => {
                    return resolve(res)
                }).catch(err => resolve(user))
            }
            return resolve(null)
        })
    }



}