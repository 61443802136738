import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FileUploader, FileItem } from 'ng2-file-upload';
import { environment } from 'src/environments/environment';
import { AppService } from 'src/app/services/app.service';
import { FormControl, FormArray, FormGroup } from '@angular/forms';
import { from } from 'rxjs';

@Component({
  selector: 'app-auto-file-uploader',
  templateUrl: './auto-file-uploader.component.html',
  styleUrls: ['./auto-file-uploader.component.css']
})
export class AutoFileUploaderComponent implements OnInit {

  uploader: FileUploader = new FileUploader({
    url: `${environment.apiUrls.api}/api/images/upload`,
    itemAlias: 'media',
    autoUpload: true,
    queueLimit:1
  });

  @Input() formatItem:(url:string) => {[key:string]:any};
  @Input() file;
  @Input() control:FormControl;
  @Input() formArray:FormArray;
  @Output() onItemUploaded:EventEmitter<string> = new EventEmitter();
  @Output() onCancel:EventEmitter<any> = new EventEmitter();
  @Output() onComplete:EventEmitter<any> = new EventEmitter();

  @Input() isUploading:boolean;
  @Output() isUploadingChange:EventEmitter<boolean> = new EventEmitter();

  index:number;
  

  constructor(
    private _appService: AppService,
  ) {


    this.uploader.onSuccessItem = this.onSuccessItem;
    this.uploader.onErrorItem = this.onErrorItem;
    this.uploader.onCompleteAll = this.onCompleteAll;

  }

  addToQueue(file:File):FileItem{
    this.uploader.addToQueue([file]);
    return this.uploader.queue[this.uploader.queue.length -1];
  }

  onSuccessItem = (item: FileItem, responce: string, status: number, header) => {
    const res: Responce = JSON.parse(responce);
    if (res.isSuccess) {
      if(this.formArray){
        this.addIntoArry(res.data.url);
      }
      if(this.control){
        this.control.setValue(res.data.url);
      }
      this.onItemUploaded.emit(res.data.url);
      this.isUploadingChange.emit(false);
    } else {
      this._appService.toastr.success(res.error || res.message || 'File upload failed.');
      this.isUploadingChange.emit(false);
    }
  }

  addIntoArry(url:string){
    let data:{[key:string]:any};
    let form:FormGroup = new FormGroup({});
    if(this.formatItem){
     data = this.formatItem(url);
    }else{
      data = {url};
    }

    Object.keys(data).forEach(k => {
      form.setControl(k,new FormControl(data[k]))
    });

    this.index = this.formArray.length;
    this.formArray.push(form);
  }

  onErrorItem = (item: FileItem, responce: string, status: number, header) => {
    if(!responce){
      this._appService.toastr.error('Image upload Failed');
    }
    const res: Responce = JSON.parse(responce);
    if (!res.isSuccess) {
      this._appService.toastr.error(res.error || res.message || 'File upload failed.');
    } else {
      this._appService.toastr.error('Image upload Failed');
    }

    this.isUploadingChange.emit(false);
    
  }

  cancel(file:FileItem){
    file.cancel();
    file.remove();
    if(this.formArray && this.index) this.formArray.removeAt(this.index);
    if(this.control) this.control.setValue('');
    this.isUploadingChange.emit(false);
    this.onCancel.emit(true);
  }

  onCompleteAll = () => {
    // this.uploader.clearQueue();
    this.onComplete.emit(true);
  }

  ngOnInit() {
    if(this.file instanceof File){
      this.addToQueue(this.file);
    }
  }

}

class Responce {
  isSuccess: boolean;
  data?: { url?: string };
  error?: string;
  message?: string;
}
