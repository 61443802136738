import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IApi } from '../http-handler/common/contracts/api';
import { GenericApi } from '../http-handler/common/generic-api';
import { LeadServices } from '../models/lead-service.model';
import { Services } from '../models/service.model';


@Injectable({
  providedIn: 'root',
})
export class LeadServicesService {
  leadServices: IApi<LeadServices>;
  services: IApi<Services>
  constructor(private http: HttpClient) {
    this.leadServices = new GenericApi<LeadServices>('leadServices', http);
    this.services = new GenericApi<LeadServices>('services', http);
  }

  leadSources = 
    { 
        'Printed Ad': 'Printed Ad' ,
     'Social Media Ads IG': 'Social Media Ads IG',
     'Social Media Ads FB': 'Social Media Ads FB',
     'CREJ': 'CREJ',
     'RMA Website': 'RMA Website',
     'RMP Website': 'RMP Website',
     'PPD Website': 'PPD Website',
     'RMS Website': 'RMS Website',
     'CREP Website': 'CREP Website',
     'DBH Website': 'DBH Website',
     'Introduction Or Referral': 'Introduction Or Referral',
     'Cold Call': 'Cold Call',
     'Cold Email': 'Cold Email',
     'E-Mail Campaign': 'E-Mail Campaign',
     'Client Call or Text': 'Client Call or Text',
     'Client E-Mail': 'Client E-Mail',
     'In Person Request': 'In Person Request',
     'Referral': 'Referral',
     'Drone Laws Page': 'Drone Laws Page',
     'videoProductionPage': 'Video Production Page',
     'eventPage': 'Event Page',
     'constructionPage': 'Construction Page',
     'portraitPage': 'Portrait Page',
     'matterport&Virtural': 'Matterport & Virtural',
     'printing&Production': 'Printing & Production',
     'commercialPropertyVideo': 'Commercial Property Video',
     'drone&AerialService': 'Drone & Aerial Service',
     'productPage': 'Product Page',
     'rrePage': 'Residential Real Estate Page',
     'creTtpePage': 'Commercial Real Estate Page',
     'industryPage': 'Industry Page',
     'studioRMPPage': 'RMP Page',
     'home': 'Home Page',
     'RMP': 'RMP',
      'SRMP': 'SRMP',
      'PPD': 'PPD',
      'DP IO': 'DP IO',
      'DI AI': 'DI AI',
      'DV AI': 'DV AI',
      'DP AI': 'DP AI',
      'PHD': 'PHD',
      'DVD': 'DVD',
      'APD': 'APD',
      'DLV': 'DLV',
      'DPP': 'DPP',
      'LD': 'LD',
      'PSRD': 'PSRD',
      'DBH': 'DBH',
      'RMA': 'RMA',
      'MD': 'MD',
      'CREV': 'CREV',
     }
  
}
