import { SmallAccount, Industry, SubIndustry, Account } from './accounts.model';
import { Audience } from './audience.model';
import { BillingAddress } from './billing-address.model';
import { MailingAddress } from './mailing-address.model';

export class Contact {
  id?: number;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  fullName?: string;
  username?: string;
  noteForDataSource?: string;
  jobTitle?: string;
  suiteNumber?: string;
  landmark?: string;
  officePhone?: number;
  mobilePhone?: string;
  email?: string;
  dataSource?: string;
  vision?: string;
  extension?: number;
  website?: string;
  homedown?: string;
  past?: string;
  birthday?: string;
  sex?: string;
  introduce?: string;
  highSchool?: string;
  universityGraduate?: string;
  universityUnderGraduate?: string;
  universityJoin?: string;
  facebook?: string;
  instagram?: string;
  linkedIn?: string;
  status?: string;
  university?: string;
  reportId?: number;
  billingAddress?: BillingAddress = new BillingAddress();
  maillingAddress?: MailingAddress = new MailingAddress();
  contactSkill?: ContactSkill[] = [];
  contactSkillIds?: number[] = [];
  account?: Account = new Account();
  btnDisable?: boolean;
  accountId?: number;
  report?: Report = new Report();
  editEducation?: boolean = false;
  editSocial?: boolean = false;
  adminId?: number;
  businessName?: string;
  audienceType?: string;
  audienceIds?: number[];
  contactAudiences?:ContactAudiences[] = [];
  audiences?:Audience[] = [];
}

export interface ContactAudiences {
  audienceId?: number;
  contactId?: number;
  id?: number;
  audience?:Audience;
}

export class Report {
  firstName?: string;
  id?: number;
  fullName?: string;
  constructor(obj?: Report) {
    this.firstName = obj && obj.firstName || '';
  }
}
export class ContactSkill {
  id?: number;
  name?: string;
  contactId?: number;
  selected?: boolean;
}

export class Contacts {
  id?: number;
  salutation?: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  phone?: number;
  mobile?: number;
  email?: string;
  title?: string;
  toDo?: string;
  companyTagline?: string;
  salesPitch?: string;
  status?: string;
  createdAt?: string;
  updatedAt?: string;
  accountId?: string;
  adminId?: string;
  industryId?: string;
  subIndustryId?: string;
  contactId?: number;
}
