import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { InviteService } from 'src/app/services/invite.service';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.css']
})
export class InviteComponent implements OnInit {

  isLoading:boolean = false;

  form:FormGroup = new FormGroup({
    email:new FormControl('',[Validators.required,Validators.pattern('([a-zA-Z0-9+_.-]){3,}\@([a-zA-Z]){3,}\.([a-zA-Z0-9+_.-]){2,}')]),
    type:new FormControl('',[Validators.required]),
    inviteLink:new FormControl('',[Validators.required])
  });

  constructor(
    private _router:Router,
    private _inviteService:InviteService,
    private _appService:AppService
  ) {

    let type = _router.url.split('/')[2];
    this.form.get('type').setValue(type == 'accounts' ? 'account' : 'realEstate');

    this.form.get('email').valueChanges.subscribe(d => {
      if(this.form.get('email').value){
        this.form.get('inviteLink').setValue(`${environment.apiUrls.api}/invitation/create-client?email=${d}&type=${this.form.value['type']}`);
      }
    })

   }

  ngOnInit() {
  }

  invite(){
    let e:string = this.form.get('email').value;
    if(e.trim() === this._appService.user.email){
      this.form.get('email').setErrors({invalid:true});
      return this._appService.toastr.error("You can't able to invite your self. Please try again with another email address.");
    }
    this.isLoading = true;
    this._inviteService.invites.create({...this.form.value}).then(d => {
      this._router.navigate(['/pages/real-estate']);
      this._appService.toastr.success('Invitation successfully sent');
    }).catch(err => {
      this.isLoading = false;
      this._appService.toastr.error(err);
    });
  }

}
