import { Contact } from './contacts.model';
import { Lead, Admin, lAddress } from './lead.model';
import { Account } from './accounts.model';

export class Deal {
    id?: number;
    name?: string;
    leadId?: number;
    dealNumber?: number;
    aggreedAmount?: number;
    startDate?: string;
    closeDate?: Date;
    paymentType?: string;
    status?: string;
    type?: string;
    contactIds?: number[] = [];
    contactId?: number;
    shootAddressCoordinates?: [];
    contact?: Contact = new Contact;
    contacts?: Contact[] = [];
    lead?: Lead = new Lead;
    btnDisable?: boolean;
    account?: Account = new Account();
    admin?: Admin = new Admin();
    adminId?: number;
    accountId?: number;
    stages?: string;
    dealContacts?: DealContact[] = [];
    notes?: string;
    dealType?: string;
    remainingAmount?: string;
    priority?: number;
    dealData?: string;
    dealSource?: string;
    dealReferrals?: DealReferral = {};
    referralContactId?:number;
    referralAccountId?:number;
    dealAddresses?: lAddress[] = [];
    businessName?:string
}

export class DealReferral {
    id?: number;
    accountId?: number;
    dealId?: number;
    contactId?: number;
    referralAccount?: Account = new Account();
    referralContact?: Contact = new Contact();
}

export class DealContact {
    id?: number;
    dealId?: string;
    contactId?: string;
    contact?: Contact = new Contact();
}
