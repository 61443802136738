<header class="flex content-between">
  <font-25 *ngIf="!data?.equipment?.id">Add Equipment</font-25>
  <font-25 *ngIf="data?.equipment?.id">Update Equipment</font-25>
  <button class="icon-btn md" (click)="dialogRef.close(false)">
    <img src="assets/svg/close (2).svg" alt="">
  </button>
</header>
<mat-progress-bar [mode]="'indeterminate'" *ngIf="isLoading"></mat-progress-bar>
<section>
  <form [formGroup]="form">
    <div class="form-field">
      <mat-select placeholder="Equipment & Gear" formControlName="equipmentId"
        (selectionChange)="fetchEquipmentManufacturers($event.value, true)">
        <mat-option [value]="item.id" *ngFor="let item of equipments?.items">{{item?.name | titlecase}}
        </mat-option>
      </mat-select>
      <div class="append flex">
        <img *ngIf="!equipments?.isLoading" src="assets/svg/filter-down.svg">
        <i *ngIf="equipments?.isLoading" class="fa fa-spinner fa-spin"></i>
      </div>
    </div>

    <div class="form-field">
      <mat-select placeholder="Manufacturer" formControlName="equipmentManufacturerId"
        (selectionChange)="fetchEquipmentModels($event.value, true)">
        <mat-option [value]="item.id" *ngFor="let item of equipmentManufacturers?.items">{{item?.name |
          titlecase}}</mat-option>
      </mat-select>
      <div class="append flex">
        <img *ngIf="!equipmentManufacturers?.isLoading" src="assets/svg/filter-down.svg">
        <i *ngIf="equipmentManufacturers?.isLoading" class="fa fa-spinner fa-spin"></i>
      </div>
    </div>

    <div class="form-field">
      <mat-select placeholder="Model" formControlName="equipmentModelId">
        <mat-option [value]="item.id" *ngFor="let item of equipmentModels?.items">{{item?.name | titlecase}}
        </mat-option>
      </mat-select>
      <div class="append flex">
        <img *ngIf="!equipmentModels?.isLoading" src="assets/svg/filter-down.svg">
        <i *ngIf="equipmentModels?.isLoading" class="fa fa-spinner fa-spin"></i>
      </div>
    </div>

    <div class="form-field">
      <textarea class="textarea" rows="5" formControlName="note" type="text"
        placeholder="Add your note here..."></textarea>
    </div>

    <div class="text-right">
      <button type="submit" [disabled]="isLoading || form.invalid" *ngIf="!data?.equipment?.id"
        [disabled]="form.invalid" (click)="addEquipment()" class="theme-btn orange"> Add
      </button>
      <button type="submit" [disabled]="isLoading || form.invalid" *ngIf="data?.equipment?.id" [disabled]="form.invalid"
        (click)="updateEquipment()" class="theme-btn orange"> Update
      </button>
    </div>
  </form>
</section>