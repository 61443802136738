import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class ClientPanelGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // this.user.roleType === 'clientAccount'
    if (
      this.user.roleType === 'clientAccount' ||
      this.user.role?.name === 'clientAccount'
    ) {
      return true;
    }
    this.router.navigate(['/external-resource']);
    return false;
  }

  get user(): User {
    return JSON.parse(localStorage.getItem('user')) || new User();
  }
}
