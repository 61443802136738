import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'DataBck'
})
export class BckDataPipe implements PipeTransform {
  transform(paymentType: any, args?: any): any {
    if (args == 'deall') return this.PaymentType(paymentType);
  }
  PaymentType(paymentType: string) {
    switch(paymentType){
      case 'dollar':
        return 'Dollar';
        case 'iTradeCredit':
        return 'I Trade Credit';
        case 'tradeCredit':
        return 'Trade Credit';
    }
  }
}