import { Department } from './department.model';

export class Team {
    id?: number;
    salutation?: string;
    firstName?: string;
    lastName?: string;
    displayName?: string;
    email?: string;
    imgUrl?: string;
    teamDepartmentId?: number;
    teamDepartment?: Department = new Department();
    roleType?: string;
    mobile?: number;
    status?: string;
    btnDisable?: boolean;
    services?:any;
    address?:any
}
