import { Component, OnInit } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';
import { AppService } from 'src/app/services/app.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-side-navigator',
  templateUrl: './side-navigator.component.html',
  styleUrls: ['./side-navigator.component.css']
})
export class SideNavigatorComponent implements OnInit {

  items: NbMenuItem[] = [
    {
      title: 'Dashboard',
      link: '/pages/dashboard',
      icon:'assets/images/Side_bar_icon/Dashboard_white.png'
    },
    {
      title: 'Users',
      link: '/pages/users',
      icon:'assets/images/Side_bar_icon/User_white.png'
    },
    // {
    //   title: 'Jobs',
    //   link: '/pages/jobs',
    //   icon:'assets/images/Side_bar_icon/Jobs_white.png'
    // },
    {
      title: 'User Tiers list',
      link: '/pages/tiers',
      icon:'assets/images/Side_bar_icon/Jobs_white.png'
    },
    {
      title: 'Service Menu',
      link: '/pages/service-menu',
      icon:'assets/images/Side_bar_icon/Service-Menu.png'
    },
    // {
    //   title: 'Change Password',
    //   link: '/pages/change-password',
    //   icon:'assets/images/Side_bar_icon/Service-Menu.png'
    // }
   ];

  constructor(
    public commanService:AppService,
    private router:Router
  ) {

   }

  ngOnInit() {
    let item = this.items.filter(i => this.router.url.includes(i.link));
   this.navigate(item[0].title);
  }

  navigate(heading){
    this.commanService.pageHeading = heading;
  }

  acitveNavigator(heading){
    this.commanService.pageHeading = heading;
    return "active";
  }

}
