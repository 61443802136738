import { CommonModule } from '@angular/common';
import { Directive, ElementRef, Input, NgModule, OnChanges } from '@angular/core';

@Directive({
  selector: '[placeholderLoading]'
})
export class PlaceholderLoadingDirective implements OnChanges {

  @Input() loading: boolean = false;

  marquee: HTMLMarqueeElement = document.createElement('marquee');
  marqueeContant: HTMLDivElement = document.createElement('div');

  constructor(
    private _el: ElementRef<HTMLElement>
  ) {
    _el.nativeElement.style.position = "relative";
    this.addMarquee();
  }

  ngOnChanges() {
    if (this.loading) {
      this._el.nativeElement.classList.add('loading-placeholder');
      // this.marquee.scrollAmount = 70;
      this.marquee.style.visibility = "visible";
      this.marquee.style.display = 'block';
    } else {
      if (this._el.nativeElement.classList.value.includes('loading-placeholder')) this._el.nativeElement.classList.remove('loading-placeholder');
      this.marquee.style.visibility = "hidden";
      this.marquee.style.display = "none";
    }
  }

  addMarquee() {
    this.marqueeContant.style.backgroundImage = "linear-gradient(90deg, #e9ecf2 10%, white 57%, #e9ecf2 100%)";
    // this.marqueeContant.style.transform = "rotate(-45deg)";
    this.marqueeContant.style.width = "25%";
    this.marqueeContant.style.minWidth = "70px";
    this.marquee.scrollAmount = 70;
    this.marqueeContant.style.height = "100vh";
    this.marquee.direction = "right";
    this.marquee.style.overflow = 'hidden';
    this.marquee.style.background = "#e9ecf2";
    this.marquee.style.position = "absolute";
    this.marquee.style.borderRadius = "4px";
    this.marquee.style.top = "0px";
    this.marquee.style.left = "0px";
    this.marquee.style.height = "100%";
    this.marquee.style.width = "100%";
    this.marquee.style.zIndex = "9";
    this.marquee.appendChild(this.marqueeContant);
    this._el.nativeElement.appendChild(this.marquee);
  }

}

@NgModule({
  exports: [PlaceholderLoadingDirective, CommonModule],
  declarations: [PlaceholderLoadingDirective]
})
export class PlaceholderLoadingModule { }