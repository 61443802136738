<div class="dialog">
  <header class="pb-3 flex">
    <font-20>Users</font-20>
    <button class="icon-btn md ml-auto" (click)="_matDialogRef.close()">
      <img src="assets/svg/close (2).svg" alt="">
    </button>
  </header>
  <div class="col px-0">
    <div class="form-field search-field">
      <input placeholder="Search.." font-13 type="text" [(ngModel)]="admins.filters.properties['displayName'].value"
        (input)="fetch()" InputUi>
      <div class="append">
        <img src="assets/svg/search (1).svg" alt="">
      </div>
    </div>
  </div>
  <!-- list -->

  <div class="list-container mt-2">
    <!-- row -->
    <div class="chater-info flex pointer mr-2" *ngFor="let item of admins?.items" placeholderLoading
      [loading]="admins?.isLoading" (click)="createChat(item?.id)">
      <img (click)="$event.preventDefault();$event.stopPropagation()"
        [src]="item?.imgUrl || 'assets/images/Square icon.png'" alt="">
      <!-- name -->
      <div class="w-100" font-14 weight="M" color="muted">
        <div>
          {{item?.displayName || 'N/a'}}
        </div>
      </div>
    </div>

    <div class="text-center pt-3" *ngIf="!admins?.items?.length">
      No data found
    </div>

  </div>

</div>