<input type="file" id="file" class="d-none" (click)="file.value = null" [multiple]="!single"
  (change)="chooseFile($event)" #file />
<label (click)="file.click()">
  <span *ngIf="!template"> Select Image </span>
  <ng-container *ngTemplateOutlet="template"></ng-container>
</label>
<div class="uploaderFiles" *ngIf="uploader?.queue?.length">
  <nb-card class="dialog">
    <nb-card-header>
      <h4>
        Files
      </h4>
    </nb-card-header>
    <nb-card-body>
      <div class="container p-0">
        <div class="row">
          <div class="col-md-12">

            <table class="table text-left">
              <thead>
                <tr>
                  <th width="35%">Name</th>
                  <th>Size</th>
                  <th>Type</th>
                  <th>Uploading</th>
                  <th>Progress</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of uploader.queue">
                  <td><strong>{{ item?.file?.name }}</strong></td>
                  <td *ngIf="uploader.options.isHTML5" nowrap>{{ item?.file?.size/1024/1024 | number:'.2' }} MB</td>
                  <td>{{item?.file.type.includes('image') ? "Image" : "Video"}}</td>
                  <td>
                    {{item.progress || '0'}} %
                  </td>
                  <td *ngIf="uploader.options.isHTML5">
                    <div class="progress" style="margin-bottom: 0;">
                      <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': item.progress + '%' }"></div>
                    </div>
                  </td>
                  <td nowrap style="width: 113px;">
                    <button mat-icon-button type="button" class="text-danger btn-xs" (click)="item.remove()">
                      <mat-icon>clear</mat-icon>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <div class="text-right">
        <button type="button" class="btn btn-success btn-s" (click)="uploader.uploadAll()"
          [disabled]="!uploader.getNotUploadedItems().length || uploader?.isUploading">
          <span class="glyphicon glyphicon-upload"></span> Upload
        </button>
        <button type="button" class="btn btn-danger btn-s" (click)="uploader.clearQueue()"
          [disabled]="!uploader.queue.length">
          <span class="glyphicon glyphicon-trash"></span> Remove all
        </button>
      </div>

    </nb-card-footer>
  </nb-card>
</div>